import { FormBase } from "./FormBase"


export type Form$ = {
  submit: (event:any) => void  // <--  provided by the specific component
  change: (event:any) => void
}


// -- form - to replace
export const change = $ => (evt) =>  $.mu(mu => {
  evt.preventDefault()
  const {name, value} = evt.target
  mu.pm.Append({values:{[name]:value}})  // <-- might be 
}) 



export const addOptionsP = (_, $) => (field:string, newOptions:{id:string, rurl}[]) => $.run(function*($$) {
    const {options} = yield $$.Get
    yield $$.Set({options:{...options, [field]:newOptions}})
})


// -- validation effect
// () => Promise (Result a)  <-- where a is the type 
export const validateMu = (mu, form:FormBase<any>) => {
    
  const errors = doValidate(form)    // <-- this is reuseable if we inject the validator
  
  if (errors) {
    mu({errors, hasError:true, message:"didn't validate", ok:false}) 
  } else {
    mu({errors:{}, hasError:false, message:"ok", validatedValues:form.values, ok:true})
  }
    
}



/**
 * Validate based on a FormSchema
 * 
 *  custom ~ custom form validators, ie
 * 
 *  custom {
 *      //  required is automatic 
 *      verifypassword: (v, values) =>  ( (v == values.passord)  "does not match" : null)
 *      
 *  }
 *  
 */
const doValidate = (form:FormBase<any>)  => {
  const {values, formSchema:schema, validators} = form
  var errors
  for (var def of schema) {
    var {id, label} = def
    var err:any = null
    var value = values[id]
    if (validators && validators[id]) {
      err = null
      const validator = validators[id]
      err = validator(value, values);  
      if (err) break
    } else {
      if ((!value || value === "") && !def.optional) {
        err = `required: ${label}`
      }
      if (err) {
        errors = errors || {}
        errors[id] = err
      }
    }
  }
  return errors
}
