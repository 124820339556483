import { useMemo } from "react"
import { Poster } from "../../../media/vid/MediaState"
import { useVimeoPs } from "./useVimeoPs"


const options = {
    autoplay:false
}

export const Vimeo = ({id, poster, ref}:{id:string,  poster:Poster, ref?:any}) => {

    const {pm, $} = useVimeoPs(id, options)
    const {playerURL  } = pm
    const embedVideoStyle = { width:poster.width, height:poster.height}
    
    return useMemo(() =>  playerURL ? (
      <div ref={ref} className='vimeo-embed' style={ embedVideoStyle }>
      
        <iframe frameBorder='0' title="vimeo-player"
        ref={$.registeriframe} 
        src={ playerURL} />

      </div>) : <div> no url</div>
    , [playerURL])  // eslint-disable-line react-hooks/exhaustive-deps
}


/*
   <div>
        <Link onClick={_ => $.playAt(2)}>play at 2</Link>
        <Link onClick={_ => $.playAt(4)}>play at 4</Link>
    </div>

*/  