

// -- react
import React from 'react';
import { createRoot } from 'react-dom/client'
import './appindex.css';

// -- redux

// -- material ui
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { createAppUIPs } from "./ui/ps/useAppUIPs";


// -- temporary mechanism to nav actions easier to work with
// https://reactrouter.com/docs/en/v6/routers/history-router 



// -- app
//import App from './App';
//import { store, history } from './app-model-config.ts.old';
import { theme } from "./ui/theme/theme"


//import { App2 } from './App2';
//import App from './App3';
import { App } from './App';
import { appEnv } from './AppMediaEnv';
import { MediaContextProvider } from './MediaContextProvider';
import { createBrowserHistory } from 'react-router-dom/node_modules/history'

export const history = createBrowserHistory({ window });



// <unstable_HistoryRouter history={history}>
//  </unstable_HistoryRouter>

const ps$ = {
  ui:createAppUIPs
}

const root = createRoot(  document.getElementById("root") )

root.render(
  <React.StrictMode>
    
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}> 
         <BrowserRouter>
            <MediaContextProvider env={appEnv} ps$={ps$}>
               <App/>
            </MediaContextProvider>
          </BrowserRouter>
       </ThemeProvider>
    </StyledEngineProvider>

  </React.StrictMode>,

);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();



