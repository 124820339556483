import X2JS from "x2js";
import { BubbleDocOld } from "../model/BubbleNotes";
/*
const DOC = "w:document";
const BODY = "w:body";
const P = "w:p";
const R = "w:r";
const T = "w:t";
const $T = "$t";
const pPr = "w:pPr";
const wIND = "w:ind";
const LEFT = "w:left";
*/


const DOC = "document";
const BODY = "body";
const P = "p";
const R = "r";
const T = "t";
const $T = "__text" //"$t";
const pPr = "pPr";
const rPr = "rPr";

const wIND = "ind";
const LEFT = "_w:left";
const GROUP = "Object Group";

const SHD = "shd"; // "w:shd"
const FILL = "_w:fill" 


const NOTE_TYPES = {
  Excerpt: true,
  Comment: true,
  Textbox: true,
  "Highlight:": true,
}; // Comment, probably group etc

// -- 1st pass - extract basic data

export const toChunks = data => {
  return data[DOC][BODY][P];
}

export const parseChunk = p => {

      
    //console.log("=== paragraph", { p });
    //const s = JSON.stringify( p, null, 2)
    //console.log(s)
    var indent = p[pPr][wIND][LEFT]; // <-- this appears to determine the group indentation
    indent = Number(indent)
    //console.log(` indent ${indent}`);
    var data = extractParagrap(p);

    if (data) {
      return {
        indent,
        data,
      };
    }
    return null
  }


export const chunksToNodes = chunks => {
 const out =  chunks.filter(notNull) // ps.map
  .map(({ indent, data }) => {
    if (indent > 1500) {
      return { $$: "Indent", data };
    } else {
      return { $$: "Data", data };
    }
  });
  return out
}


function extractGroups(nodes) {
  var i = 0;
  var nodesWithGrouping = [];
  while (i < nodes.length) {
    var item = nodes[i];
    if (item.data === GROUP) {
      var group = [];
      i++;
      var nextNode = nodes[i];
      while (
        i < nodes.length &&
        nextNode.data !== GROUP &&
        nextNode.$$ === "Indent"
      ) {
        group.push(nextNode.data);
        i++;
        nextNode = nodes[i];
      }
      if (group.length > 0) {
        item = { $$: "Group", nodes: group };
      }
    } else {
      item = item.data;
      i++;
    }
    nodesWithGrouping.push(item);
  }
  return nodesWithGrouping;
}

export const nodesToNotes = (data, digest?):BubbleDocOld =>  {
  let [titleNode, filler, ...nodes0] = data;

  // 1. first line is "Notes in 'Documnet name here'"
  let { title, ok } = extractTitle(asText(titleNode.data));
  
  if (!ok) {
    extractTitle(asText(titleNode.data));
    throw new Error("couldn't parse document title from " + data[0].data)
  }

  // -- liquid text expliti
  const NOTES_IN_DOC = "Notes in Document";
  const NOTES_IN_WORKSPACE = "Notes in Workspace:";
  
  if (filler.data === NOTES_IN_DOC) {
    var found = false;
    nodes0 = nodes0.filter((node) => {
      if (!found) {
        if (node.data === "Notes in Workspace:") {
          found = true;
          return true; // <-- filter out everying until we find workspace
        }
        return false;
      }
      return true;
    });
    [filler, ...nodes0] = nodes0;
  }

  // 2. next line assumed to be constant string
  if (filler.data !== NOTES_IN_WORKSPACE) {
    throw new Error("was expecting second line to say 'notes in Workspace'")
  }

  // -- need to extract "group objects"

  var groupedItems = extractGroups(nodes0);
  var nodes = fmapGrouped(groupedItems, (node) => nodeToNote(node, digest));

  return {
    $$: "Doc",
    title,
    nodes
  };
}

//  data Note type text ref = Note .type .text .ref
//                           | Group notes:(Note type text ref)[]
//

function fmapGrouped(groupedItems, f) {
  return groupedItems.map((item) => {
    // this is really just an fmap
    if (item.$$ === "Group") {
      return {
        $$: "Group",
        nodes: item.nodes.map((item) => f(item)),
      };
    } else {
      return f(item);
    }
  });
}

function toNotes(notes) {
  var out = notes.map((note) => {
    return note[$T];
  });
  return out.filter(notNull);
}

function toNote(note, i) {
  //console.log(` note ${i}`, { note });
  var t = note[T];

  var out = null;

  if (t instanceof Array) {
    out = toNotes(t);
  } else {
    var text = t[$T];
    if (i === 0) {
      // <-- hardcoding that we extract the colour of the "Extract", "Comment" etc
      if (!NOTE_TYPES[text]) {
        throw new Error("unknown / unsupported node type: " + text);
      }
      const pr = note[rPr];
      const shd =  pr[SHD];
      const col = shd[FILL];  // <--  background colour, which encodes node type

  
      return { type: text, col }; // ie. {type:"Excerp", col:"etc"}
    }
    //console.log(`   uncoloured text: ${text}`);
    return text;
  }

  return out;
}

function extractParagrap(p) {
  var out = null;
  var r = p[R];
  if (r) {
    if (r instanceof Array) {
      out = r
        .map((note, i) => {
          //console.log(" -- note " + i);
          return toNote(note, i);
        })
        .filter(notNull);
    } else {
      var t = r[T];
      var value = t[$T];
      var out = value;
    }
  }
  return out;
}

const notNull = (v) => v !== null && v !== undefined;

// -- 2rd phase - organize extracted data into structured josn

const nodeToNote = (node, digest) => {
  // notes of length 4 are Extracts
  // nodes of length 3 are Textbox (which dont have reference info)
  if (node.length !== 4 && node.length !== 3) {
    //console.log(node.join("\n"));
    //console.log("expected note node to have ");
    throw new Error("unanticipated node form", node)
  }

  let [{ type, col }, , content, refData] = node;

  var text = "";
  if (content instanceof Array) {
    var line;

    for (line of content) {
      line = asText(line); // <-- assert that all lines
    }
    text = content.join("\n");
  } else {
    text = asText(content);
  }

  let refTxt = refData ? asText(refData[0]) : null;
  var { doc, pg, ok } = extractRef(refTxt);

  // var col = COLOURS[col];
  // console.log(`   coloured text: ${text}`);

  var ref;

  if (!ok) {
    ref = null;
  } else {
    ref = { doc };
  }

  var out: any = {
    type,
    text,
    ref,
  };
  if (pg) {
    out.pg = pg;
  }

  //var colName = COLOURS[col];
  //if (!colName) {
  // throw new Error("unknown colour " + col)
  // //colName = "ERROR"
  //}

  //if (colName && COLOURS[col] !== "white") {
  //if (col && col !== DEFAULT_COL) {
    out.col = col;
  //}
  //
  //  create a content digest .. by only on type, text and page.
  //  meaning that changing colour or the document name won't alter the digest
  //
  if (digest) {
    var page = pg ? pg : -1;
    var did = digest({ type, text, pg: page });
    out.did = did;
  }

  return out;
};

// -- until
const TITLE = /^Notes\sin\s[‘'](.*)[’']$/; // <--  extracts content from "Notes in 'My Doc'"

const extractTitle = (v) => {
  let match = TITLE.exec(v);
  return match ? { title: match[1], ok: true } : { ok: false };
};
 
const REF = /^\((.*),\s+p.([0-9]+)\)$/; // <-- extracts content from "(My Doc, p.12)""

const extractRef = (v) => {
  if (!v) {
    return { ok: false };
  }
  let match = REF.exec(v);
  if (match && match.length >= 2) {
    return { ok: true, doc: match[1], pg: Number(match[2]) };
  }

  return { ok: false };
};

const asText = (t) => {
  if (typeof t != "string") {
    throw new Error("expecting Text")
  }
  return t;
};

export function lqDocxToNotes(xml) {
  //var json = docXtoJSONSync(path);

  var x2js = new X2JS();
  var json = x2js.xml2js( xml.v );
  //var nodes = toObjectJson(json);
  //var notes = nodesToNotes(nodes, digest, notesAuth);
  return  json
}
//modules.exports.lqDocxToNotes = lqDocxToNotes
