// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyByv161xAuRb39XcRGQ0EAQr9ud-SG453w",
    authDomain: "ranjoonikath.firebaseapp.com",
    databaseURL: "https://ranjoonikath-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ranjoonikath",
    storageBucket: "ranjoonikath.appspot.com",
    messagingSenderId: "775793105819",
    appId: "1:775793105819:web:eb4a969c3a238a27281f3e",
    measurementId: "G-ZY7LVP30VV"
  };
  