import { Page } from "./PageState"

/**
 * Abstract on a simple hash. 
 *  
 *  
 */

export type Cache<a> = {[string:string]:a}


const update = <a>(path:string, a:a, cache:Cache<a>):Cache<a> => {
    return {...cache, [path]:a}
}

const retrieve = <a>(path:string, cache:Cache<a>):any|undefined => {
    return cache[path]
}

const EMPTY = {}

export const Cache = {
    update, retrieve, EMPTY
}


export type PageCache = Cache<Page>

  
