import { useEffect } from "react"
import { usePm$ } from "../../../../anyonic/dsl/usePm"
import { Page, PageState } from "../../../../model/page/resource/PageState"
import { NotesFilter } from "../NotesFilter"
import { BubbleMu } from "../../view/BubbleMu"
import { BubbleReading }  from "../doc/BubbleModel"

import { EntityCache } from "../../../../anyonic/dsl/EntityCache"
import { BubblePs, BubbleViewPM } from "./pm/BubbleViewPM"
import { useUI$, useUser } from "../../../../MediaContextProvider"
import { MuPM } from "../../../../ps/firebase-ps/PresenceMu"
import { BubbleEff } from "./eff/BubbleEff"
import { UIState } from "../../../../ui/model/UIState"
import { $Page } from "../../../../model/ps/pagePs/usePage"

export type NotesPage =  PageState<BubbleReading, NotesFilter> 

export type TagPsCache = {
  running:boolean
  tagServerURI:string
  ref:any
  cancelFn: () => void 
}


export type $Bubbles = {
	mu:any 

  pm:MuPM<BubbleViewPM>
  _ps:MuPM<BubblePs>
  
  refs: () => {
     tagServer:TagPsCache
  }

  ref: {
    tagServer: (tagServer?:TagPsCache) => void
  }

} & BubbleEff<any>


const BUBBLE_CACHE = Symbol("bubble")

export type BubbleViewCfg = {
  editTweaks:boolean
}

const cfg0:BubbleViewCfg = {
  editTweaks:false
}

export type DbStatus = {
  tagsLoaded:boolean,
  dataLoaded:boolean,
  tweaksLoaded:boolean
}


export const DbStatus0:DbStatus = {
  tagsLoaded:false,
  dataLoaded:false,
  tweaksLoaded:false
}

const selectTagFilter = (uis:UIState) => (uis.tagFilter)

export const useBubbleNotesPs = ($pg:{$:$Page, page:Page}, cfg?:BubbleViewCfg):{$:$Bubbles, pm:BubbleViewPM} => {
  
  const user = useUser()
  const ui = useUI$(selectTagFilter)

	const {$, pm} = usePm$(() => ({
    
    pm:{ 
      ok:false,
      tagState:{} ,
      cfg: cfg || cfg0,
      status:"... starting",

      fpm:{},
      // -- TODO - clean up typing, initial state
      _notes: null as any,
 

      tagFilter:false,
      fnotes: null as any,  

      dbstatus: DbStatus0,
      

      chaptersPM:[],

      tweaks:{}, 
      allTags:[],
      filter:null,
      filterPM:null as any,
      name:"TODO - determine name"
    },
    
    PM: {
      _notes:EntityCache(BUBBLE_CACHE), // <-- cache the pmodel
      _refs:{
        tagServer:null
      },
      _ps:{}

    },


    mu: BubbleMu,
    
    $: $ => ({
      // -- TODO: abstract cache
   
      ref: {
        tagServer: tagServer => $.mu._refs({tagServer})
      },
      refs: () => $.mu._refs(),
      
      pm:$.mu.pm,
      _ps:$.mu._ps,
      ...BubbleEff($, ui.$)
    })

  })) as {$:$Bubbles, pm:BubbleViewPM}

  useEffect( () => {
    ui.$.showTagFilter(true)
    //ui.$.tagFilter(false)
    $.start($pg,user.user)

    
    return () => {
      //ui.$.showTagFilter(false)
      //$.stop()
      console.log("stopping...")
    }
  }, [$, $pg.page.query, $pg.page.rurl, ui.$, user.user, $pg]) // , $, user.user, ui.$])

  useEffect(() => {
    console.log(`tag filters changed => ${ui.pm}`)

    $.setTagfilter(ui.pm as any as boolean)
  }, [ui.pm, $] )



  return {$, pm} as any


} // -- 




