import { AppBar } from "@mui/material";
import { Box  } from '@mui/material';

// -- icons
import { useUI$ } from '../../../MediaContextProvider';
import { Avatars } from '../../../view/util/Avatars';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import { SyncLoader } from "react-spinners";
import { RED } from "../../theme/theme-lea";


export const AppToolBarLea = ()  => {

	//const ctx:MediaPsPM = useContext(MediaContext)
	//const media:MediaState  = useMedia()  useSelector(mediaSelector)
		
	const {pm} = useUI$()
	//const open = pm.menuOpen



	//const buttonClassname = clsx({
	//    [cs.buttonSuccess]: (pm.saveInProgress === "ok")
	// });

	return (
		<AppBar sx={{ top: 'auto', bottom: 0, position:"fixed" }} >
				

			<Box sx={{ml:10, display: 'inline-flex' , px:4, py:4}}  > 
				<div>
					<Box sx={{ typography: 'appTitle' }}>Social Justice in Counselling</Box>
					<Box sx={{ typography: 'appSubtitle'}}>Lea Braithwaite</Box>
				</div>
				{"  "}

				<Box sx={{ flexGrow:1, ml:20, mr:20} } >

					<FastRewindIcon sx={{pt:15}}/>
					
					<span> prev / next </span>
					<FastForwardIcon/>

				</Box>

			
				{pm.isLoading && 
					<SyncLoader size={5}  speedMultiplier={0.6} color={RED} margin={1}/>
				}

				<Box sx={{alignSelf: "end", px:7}}>
				
					<Avatars/>
				</Box>	
			</Box>	
		



		
	</AppBar>)

}

