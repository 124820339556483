import { BubbleReading, Pages, PgIds, Pgs } from "../BubbleModel";
import { Chapter, NotePage } from "../BubblePM";
import { PgFns, toPagesPM } from "../note-transforms";
import { applyTweaks, ChapterTweak, Tweaks } from "./BubbleTweak";
import { Part, partition } from "./pgChPartition";


export type ChapterContent = {
  ch?:ChapterTweak   // ie {label: "Ch1"}
  content:PgIds      // ie ["a", "b", "c"]  all the ids in this chapter
} 

/**
 * return the chapter annotation for a note or group 
 */
const toCh = (did:string|string[], tweaks:Tweaks):ChapterTweak|null => {
  const vs:string[] = (did instanceof Array)  ? did : [did];
  return vs.reduce((ch:ChapterTweak|undefined, v) => (ch || (tweaks[v] && tweaks[v].ch)), undefined)
} 

// accumulator for reducer algorithm
type PgAcc = {  ch:ChapterContent, all:ChapterContent[] }

export const toChapters = (pgs:PgIds, tweaks:Tweaks):ChapterContent[] => {

  const acc0:PgAcc = {all:[], ch:{ch:null, content:[]}};

  const commit = (acc:PgAcc):ChapterContent[] =>  
      ((acc.ch.content.length > 0) ? [...acc.all, acc.ch] :  acc.all)

  const addNode = (ch:ChapterTweak|null, v:(string|string[]), acc:PgAcc):PgAcc => 
      (ch) ? {all:commit(acc), ch:{ch, content:[v]} }
          : {...acc, ch:{ch:acc.ch.ch, content:[...acc.ch.content, v]}}

  const acc1:PgAcc = pgs.reduce((acc:PgAcc, v:string|string[]) => addNode(toCh(v, tweaks), v, acc), acc0)

  return commit(acc1)  // comit last node(s)

}


const merge = (v1:ChapterContent, v2:ChapterContent):ChapterContent => ({
  ch:v1.ch, content:[...v1.content, ...(v2.content)]
})

const splitL = <a>(vs:a[]):[a[],a] => ([
    vs.length > 1 ? vs.splice(0, vs.length - 1) : [],
    vs[vs.length -1]
  ])


export const toPgIds = (pages:Pages):{pgs:PgIds[], pgNos:number[]} => {
  const pgNos = Object.keys(pages)
                .map(n => Number(n))
                .sort( (a:number, b:number) => (a - b)) 

  const pgs =  pgNos.map(n => pages[n]);
  return {pgNos, pgs}
}

export const mergeChapters = (pages:Pages, tweaks:Tweaks):ChapterContent[] => {

  const {pgs} = toPgIds(pages)
  const rawChapters:(ChapterContent[])[] = pgs.map(pg => toChapters(pg, tweaks) )

  type ChAcc = {
    all:ChapterContent[]
    last?:ChapterContent
  }
  const acc0:ChAcc = {all:[]}

  const out = rawChapters.reduce( (acc:ChAcc, chs:ChapterContent[]) => {
    const {all, last} = acc // <-- accumulator of previous
    const [v, ...vs] = chs   

    // -- 1. pg1: [c1, c2, c3]   ~ [all, last] in ChAcc
    //       pg2: [c3, ...vs]     <-- merge last 
    if (!v) {
      console.log('x')
    }
    if (last && ((last.ch === v.ch) || (v.ch == null))) {
      const last1 = merge(last, v)
      if (vs.length  === 0) {
        // -- 2. pg1: [c1, c2, c3]
        //       pg2: [c3]     <--  nothing else to merge
        return {all, last:last1}
      } else if (vs.length === 1) {
        // -- 3. pg1: [c1, c2, c3]
        //       pg2: [c3, v4]       nothing else to merge
        return {all:[...all, last1], last:vs[0]}
      } else if (vs.length > 1){
        // -- 3. pg1: [c1, c2, c3]
        //       pg2: [c3, ...vs2, cX]       nothing else to merge
        const [vs2, cX] = splitL(vs)
        return {all:[...all, last1, ...vs2], last:cX}
      }
    } //else {
      // -- 3. pg1: [c1, c2, c3]
      //       pg2: [...vs3?, cY]  <-- nothing to merge
      const [vs3, cY] = splitL(chs)
      return { all: [...all, ...(last ? [last] : []), ...vs3], last:cY}            
    //}
  }, acc0)

  return out.last ? [...out.all, out.last] : out.all
}




// -- The basic paritioning into chapters and pages
export type ChPgPartitions = Part<Part<Pgs,number>,ChapterTweak>

export const partitionChapters = (pages:Pages, tweaks:Tweaks):ChPgPartitions[]  => {
  const {pgs, pgNos} = toPgIds(pages) 

  // -- create chapter partitions
  const chapters:ChapterContent[] = mergeChapters(pages, tweaks)
  // -- add chapter paritions
  const chs:Pgs[][] = chapters.map(ch => ch.content);      
  const partitioned:(Part<Pgs, number>)[][] = partition(pgs, pgNos, chs )

  // -- add chapter anotations to the partitioning
  return partitioned.map((content:(Part<Pgs,number>[]),i) => ({ label: chapters[i].ch, content})) 

}




/**
 * 
 * Creates chapters from a reading's {page, notes} data, while partitioning 
 * into chapters (and pages within those chapters) taken from the tweak annotation mechanism
 * 
 */
export const createChapters = <c,p,n>(reading0:BubbleReading, tweaks:Tweaks, fns:PgFns<c,p,n>):Chapter<c,p,null,n,null>[] => {

  // -- TODO retain untweaked reading etc
  const reading = applyTweaks(reading0, tweaks)

  const partitions:ChPgPartitions[] = partitionChapters(reading.pages, tweaks)
  
  const chs:Chapter<c,p,null,n,null>[] = partitions.map( (chapter:Part<Part<Pgs, number>, ChapterTweak>, i) => {
    const {label:ch, content:rawPages} = chapter
    
    var chPages:Pages = {}

    // -- put the partition into the form for each page
    rawPages.forEach( (pg:Part<Pgs, number>) => (chPages[pg.label] = pg.content))

    const pages:NotePage<p,null,n,null>[] = toPagesPM({notes:reading.notes, pages:chPages}, fns)

    const pm:c = fns.chPm(ch, pages, i )

    return  Chapter(ch, pages, pm)

  })

  return chs
}