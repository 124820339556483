import { useEffect } from "react"
import { P } from "../../anyonic/dsl/dsl-api"
import { usePm$ } from "../../anyonic/dsl/usePm"
import { useUI$, useUser } from "../../MediaContextProvider"
import { FBUser } from "../../model/page/user/FBUser"
import { DefaultReducer } from "../../ps/firebase-ps/PresenceMu"
import { ProjectEff } from "./ref/eff/ProjectEff"
import { ProjectMu } from "./ref/eff/ProjectMu"
import {  RefDocPM } from "./ref/model/RefDoc"


/**
 * This describes the effect language of a project editing process. 
 * 
 * Which involves:
 * 
 *  - loading the document from a backend.
 *  - editing the text, and parsing it into  a RefDoc dsl , ie
 *    This is My project document
 *    # this is a group title
 *     This is just some annotated
 *    [Ref02] This is a referenced paper
 *    [Miranda07] And so on.
 *  
 *  - creates backend references to references 
 *  ie [Ref02] Name of Paper
 *     - it just does this automatically at the moment.
 *       but the next step would be to add ui to invoke creation
 * 
 *    
 *  - persists to data base
 *  
 *  - provides a source document to view a project, annotations, and links
 *    to bubble notes. 
 *  
 * 
 */
export type $ProjectEditor = {
  ps:any
  run:any
  await: <a>(f:(() => Promise<a>)) => Promise<a>
  pm:P<ProjectPM>&DefaultReducer<ProjectPM,ProjectPM>
  mu:ProjectMu<any> & any
} & ProjectEff


export type ProjectPM = {

  // -- target
  user?:FBUser,
  projectId:string
  
  // -- ui
  exists:boolean|string
  unavailable?:boolean  // <-- server reports unavailable

  loading:boolean
  loaded:boolean
  
  // -- editor ee
  unsaved:boolean
  saving?:boolean
  saveRequired?:boolean

  editorChanged?:boolean

  // -- references validated
  validates?:boolean
  resolved?:boolean 

  // -- persisted
  doc?:RefDocPM

}

const AdminPM0:ProjectPM = {
  projectId:"Heaney",  // <-- FIX_THIS hard coding a single project

  loaded:false,
  loading:false,
  unsaved:false,
  exists:"unknown"

}


export const useProjectRefDocEditorPs = (projectId):{$:$ProjectEditor, pm:ProjectPM} => {
  const user = useUser()
  const $ui = useUI$()
  
  const {$,pm} = usePm$(() => ({
    pm:AdminPM0,
    _ps: {
      stopFn: null // <-- TODO - manage process properly 
    },
    mu:ProjectMu,
    $: ProjectEff($ui.$)
    
  }))

  useEffect(() => {
    $.start(projectId, user.user)
  }, [projectId,  user.user, $])

  return {$,pm} as any
}