
import { Login, Logout } from "./view/login/Login" // <-- require fb

import { PFolder } from "./media/vid/folder/PFolder"
import { PVideo } from "./media/vid/PVideo"
import { Home } from './view/home/Home';
import { PProject } from './media/vid/PProject';
import { Presence } from './view/util/Presence';
import { Register } from './view/login/Register';
import { DProject } from './media/notes/DProject';
import { DProjects } from './media/notes/DProjects';
//import { DNotes } from './media/notes/view/DNotes';
import { usePage } from "./MediaContextProvider";
//import { Draw } from "./bubbleChamber/Draw";
import { MsgChannels } from "./media/bubble-chat/view/MsgChannels";
import { Anyon } from "./media/anyon/view/Anyon";
import { BubbleView} from "./media/notes/model/view/BubbleView"
import { ProjectEditor } from "./media/bubble-base/ProjectEditor";
import { ProjectView } from "./media/notes/refs/ProjectView";
import { BubbleEdit } from "./media/notes/model/view/BubbleEdit";
import { BubbleTweakView } from "./media/notes/model/view/BubbleTweakView";
import { ChannelsEdit } from "./media/channels/ChannelsEdit";
import { MyNotes } from "./media/notes/myNotes/MyNotes";



const UnknownPage = () => (<div>
    unknown page: ${usePage().rurl}
  </div>)


export const RENDERERS = {

  PProject: <PProject/>,
  PFolder: <PFolder/>,
  PVideo: <PVideo/>, 
  Login: <Login/>,
  Register: <Register/>,
  Logout:<Logout/>,
  Home: <Home/>,
  Presence: <Presence/>,

  DProject: <DProject/>,
  DProjects: <DProjects/>,
  //DNotes: <DNotes/>,

  //Draw:  <Draw/>,
  // -- documents
  Anyon: <Anyon/>,

  // -- channels
  MsgChannels: <MsgChannels/>,
  BubbleNotes: <BubbleView/>,
  BubbleNotesEdit: <BubbleEdit/>,
  BubbleNotesTweak: <BubbleTweakView/>,

  ProjectEdit: <ProjectEditor/>,
  ProjectView: <ProjectView/>,
  ChannelsEdit: <ChannelsEdit/>,

  MyNotes: <MyNotes/>,

  UNKNOWN: <UnknownPage/>,
  '404': <UnknownPage/>

}






    