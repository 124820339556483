import * as React from "react" 
import { extendForm, FormBase } from "../../ps/form/FormBase";
import { validPasswordConfirm, validUsername } from "../../ps/form/validators";
import { LoginValues, useLoginPs ,  loginForm0 } from "../../ps/user/useLoginPs";
import { useRegisterPs } from "../../ps/user/useRegisterPs";
import { BaseForm } from "./BaseForm";

export type RegisterValues = LoginValues & {
  name:string
}

export type RegisterFormPM = FormBase<RegisterValues>



export const registerForm0:RegisterFormPM = extendForm(loginForm0,  {
  
  formSchema: [
    { id: 'userName', label: 'user name', value: '' },
    { id: 'confirm', label: 'confirm password', value: '' , isPassword:true}
  ], 

  values: {
    userName:''
  },

  validators: {
    name: validUsername,
    confirm: validPasswordConfirm
  },
  submitMsg:""
} as any ) as any


export const Register =  () =>  {
  const {pm, $} = useRegisterPs()

  return BaseForm(pm, $)
}
  

export const Loading = () => {
  return <div>Loading... </div>
}

  
export const Logout = () => {
  const {$} = useLoginPs()
  
  return <div>
    <a href="/logout" onClick={$.logout}>logout</a>
  </div>
}