import {  useCallback } from "react";
import { dsl } from "./dsl";
import { toPmConfig , toPm0, DslConfig} from "./usePm";
// -- TODO - separate from react dependency
import {useSyncExternalStore} from  "react"  // 'use-sync-external-store';





/**
  * Syntactic sugar around a process context exposing a react presentation model
  *  
  *  Goal: is to compose reducers mechanism
  * 
  *    state:{
  *       pm: {initial state + leaf reducers}
  *    } 
  * 
  * 
  *  1. config () => {
  *        // -- specify initial data 
  *        
  *        PM: { 
  *           
  *           pm1: [initialValue1, {
  *             // -- could add leaf reducers here? 
  *             //   --  Mu(p0, reducers, selectors, update) 
  *           }]  
  *           pm2: initialValue2
  *           _internal: {...} 
  *        }
  *         
  *        pm   <-- convenience when you have a single model pmodel
  *         
  *        mu: {    <-- mu-reducers (see ...)
  *           // capital letters are mu reducers
  *           SetSomething = (a, v) => (mu, s) => ...
  *           pm: {  <-- TODO mechanism to add leaf reducer methods goes here
  *              Leaf: (pm, appi) =>  ...    
  *           }
  *        }, 
  * 
  *       
  *        // -- TODO - work out algebraic mechanism better
  *        $$: {
  *             MyCmd: function($$ ..) {... }  
  *        }
  * 
  *        // -- TODO - better api mechanism
  *        $: { 
  *             starts: $ => pm => $.ps(...)
  *             otherApi: $ => (...args) => $.run(etc)
  *        }, 
  * 
  *         
  *         restartOn  :: a  => any[]    transforms the pmodel into array of values 
  *                                      upon which a change will restart the process
  *                                      Defaults to []. 
  *         
  *  }
  *  
  *
  */   
 // 
  export const createStorePs$ = <a>(store:SStore<a>, config:DslConfig<a, any>)  => {
    
    const pm:a = toPm0(config)

    const { $, $$, pm:pm0, mu, dbg} = config
    const setPm = store.setState
    // -- update function communicates the pmodel to react, and the view
    const update0 = dbg && dbg.update ? dbg.update(setPm) : setPm
    const ns = (pm0 !== undefined) ? "pm" : undefined

    const update = v0 => {
      const v = ns ? v0[ns] : v0  //   "de-namespace" to  {pm: ~ default pmodel} 
      // -- this is typically local react state
      update0(v)  // <-- handy place to set breakpoint
    }


    const stateConfig = toPmConfig(config)   
    store.setState(pm)

    return dsl( stateConfig,
        { 
          ...(mu || {}), // <-- mu-reducers MyReducer = (..args) => (mu, s) => ...
          $:($ || {}),   // <-- $.apiActions(...)  :: $ => (...arcs) => ...
          $$:($$ || {})  // <-- yield $$.cmd(...)  :: function*($$) { ... }
        },
        update)
  }     

 




// -- extremely minimal, shallow, store 
export type SStore<A> = {
  getState: () => A,
  setState: (v:A) => void
  subscribe: (fn:((v:A) => void)) => void
}


export const createStore = <A,>(initialState?:A):SStore<A> => {
  let state = initialState;
  const getState = () => state!;
  const listeners = new Set();
  
  const setState = (v) => {
    if (v !== null && v !== state) {   // <-- very shallow store
      state = v
      listeners.forEach((l:any) => l());
    }
  }
  
  const subscribe = (listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  }
  return {getState, setState, subscribe}
}

const id = v => v


export const useStore = (store, selector?) => {
  selector = selector || id
  return useSyncExternalStore(
    store.subscribe, 
    useCallback(() => selector(store.getState()), [store, selector])
  )
}

