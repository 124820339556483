import { DocStatus } from "../pm/DocState"
import { $Anyon } from "../useAnyonPs"

export const DocStateUI = ({ds, $}:{ds:DocStatus, $:$Anyon}) => {

  return (<div>



  </div>)




}