import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux";
import { IProcessAspect } from "./aspect/IProcessAspect";
import { $CoProxy, dsl, dsl2 } from "./dsl";
import { CompositeReducerUpdate } from "./dsl-spec"
import { Dispatcher, DslDef, isDef, keys, keys2o, Mu } from "./dsl-api";
import { $, $Mu, $MuReducer, ExlcudeLower } from "./mu-types";


// -- some utilities to integrate the $ process dsl with react

export type DslDebug = {
  update?: (update:Function) => (v:any) => void
}

export type DslConfig<a, refs> = {
  pm?:a,
  _ps?:any,
  refs?:refs
  PM?:any, 
  mu?:any,
  $?:any, // $eff & {mu:$Mu<a>} => $eff
  $$?:any, 
  setStore?:any, 
  restartOn?:((pm:a) => Array<any>), 
  dbg?:DslDebug
}



/**
  * Syntactic sugar around a process context exposing a react presentation model
  *  
  *  Goal: is to compose reducers mechanism
  * 
  *    state:{
  *       pm: {initial state + leaf reducers}
  *    } 
  * 
  * 
  *  1. config () => {
  *        // -- specify initial data 
  *        
  *        PM: { 
  *           
  *           pm1: [initialValue1, {
  *             // -- could add leaf reducers here? 
  *             //   --  Mu(p0, reducers, selectors, update) 
  *           }]  
  *           pm2: initialValue2
  *           _internal: {...} 
  *        }
  *         
  *        pm   <-- convenience when you have a single model pmodel
  *         
  *        mu: {    <-- mu-reducers (see ...)
  *           // capital letters are mu reducers
  *           SetSomething = (a, v) => (mu, s) => ...
  *           pm: {  <-- TODO mechanism to add leaf reducer methods goes here
  *              Leaf: (pm, appi) =>  ...    
  *           }
  *        }, 
  * 
  *       
  *        // -- TODO - work out algebraic mechanism better
  *        $$: {
  *             MyCmd: function($$ ..) {... }  
  *        }
  * 
  *        // -- TODO - better api mechanism
  *        $: { 
  *             starts: $ => pm => $.ps(...)
  *             otherApi: $ => (...args) => $.run(etc)
  *        }, 
  * 
  *         
  *         restartOn  :: a  => any[]    transforms the pmodel into array of values 
  *                                      upon which a change will restart the process
  *                                      Defaults to []. 
  *         
  *  }
  *  
  *
  */   
 // 
  export const usePm$ = <a, refs>(
        configFn:() => DslConfig<a, refs>, dbg?:{aspect:IProcessAspect}
     ):{$:any, pm:a} => {

    var [pm, setPm]:[any, any] = useState(() => toPm0(configFn()))  // <-- form state
    // -- possible mechanism for further injections 
    // const ctxs = PsContexts.map(class => useContext(class)) 
 
    const $:any = useMemo(() => {

      const config:DslConfig<any, any> = configFn();

      const { $, $$, pm:pm0, mu, dbg, setStore} = config
      
      // -- update function communicates the pmodel to react, and the view
      const update0 = dbg && dbg.update ? dbg.update(setPm) : setPm
      const ns = (pm0 !== undefined) ? "pm" : undefined

      const update = v0 => {
        const v = ns ? v0[ns] : v0  //   "de-namespace" to  {pm: ~ default pmodel} 
        // -- this is typically local react state
        update0(v)  // <-- handy place to set breakpoint
        if (setStore) {
          setStore(v)    // <-- FIX_THIS/ Store mechanism duplicates useState
        }
      }
      if (setStore) {
        setStore(pm) 
      }
      
      const stateConfig = toPmConfig(config)   
     
      return dsl(
        stateConfig,
        { 
          ...(mu || {}), // <-- mu-reducers MyReducer = (..args) => (mu, s) => ...
          $:($ || {}),   // <-- $.apiActions(...)  :: $ => (...arcs) => ...
          $$:($$ || {})  // <-- yield $$.cmd(...)  :: function*($$) { ... }
        }, 
        update)
    
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // -- start ps via $.start (if present) 
  
  // TODO - remove this. Make it hard to debub, and other headaches. Better to construct the reactive graph explicitly
  //useStart($, startArgs.length > 0  ? startArgs : EMPTY)   //restart ) // <-- TODO  work our args 

  return {$, pm}  // <-- pm type is reliably typed a, it's just messy in typescript

}

const EMPTY = []

export const useStart = ($:any, params?:any[]) => useEffect(() => {
    if ($.start) {
      const p = $.start.apply(null, params)
      return () => {
        p && p.cancel()    // FIX_THIS ... apply doesn't seem to return the correct object 
      }
    }
  },  params || EMPTY) // eslint-disable-line react-hooks/exhaustive-deps
    




/**
 * Another variant of abstraction, /w local component state + redux integration
 *  
 */
export const usePs = (spec:PsDef) => {
    
    const [pm, update] = useState(spec.state0.pm);
    const dispatch = useDispatch() // <-- assuming we're using redux

    const $:any = useMemo(() => {
        const {state0, defs, cmds, reduxActions} = spec

        var $:any = dsl({
                pm:     Mu(state0.pm) ,  // pm, 
                psm:    Mu(state0.psm),  // psm 
                __redux: Dispatcher(reduxActions || {}, dispatch), // redux
                ...(defs || {})
            }, { 
                $: cmds 
            }, 
            update)
            
        return $
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps
    
    return {pm, $}

}



/**
 * 
 * 
 * 
 */
export const toPmConfig = (config:DslConfig<any, any>):any => {
    
  const {PM, pm, _ps, refs} = config
  
  var out:any = {}
  
  if (PM) {
    keys(PM, k => {
      const def = PM[k]
      out[k.replace(END0,"" )] = isDef(def) ?  def :  (Mu(def)) 
    })
  } 
  if (pm) {  // <-- mechanism to specify a single model /w namespace "pm"
    out.pm = isDef(pm) ? pm :  Mu(pm || {}) 
  }
  if (_ps) {  // <-- replace this??
    out._ps =  isDef(_ps) ? _ps :  Mu(_ps || {}) 
  }

  if (refs) {
    out._refs = Mu(refs)
  }



  return out
}

const END0 = /(_0)$/


const isPm = k => (k.indexOf('_') !== 0)
export const toPm0 = (cf:DslConfig<any, any>):any =>{ 
  if (cf.pm) {
    return _toValue0(cf.pm)
  }

  return keys2o(cf.PM, (k,o, out) => (isPm(k)) ? out[k] = _toValue0(o) : null)
}

const _toValue0 = o => isDef(o) ? o.state : o




export type PsDef = {
    state0:any,    // <-- TODO paramterize type 


    // defs (other reducer states reducers etc)
    defs?:{[ns:string]:DslDef<any>}

    // public commands
    cmds?:{[cmd:string]:(($:any) => Function)}   // public cmds, ie start = $ => 
    reduxActions?:{[action:string]:any}

} 




// -- TODO - extract. no react dependency
export const $muEff = <
    Pm   extends {[K in keyof Pm]:any} = any,
    $Eff extends {[K in keyof $Eff]: any} = any,
    Cmds extends 
      (
        {[K in keyof Partial<Pm>]:$MuReducer<Pm[K], Cmds[K]>  } 
        & { [K in keyof ExlcudeLower<Cmds, Cmds>]:(...args:any) => $Mu<Pm, Cmds> }
      ) = any,
    Refs extends {[K in keyof Partial<Refs>]:any } = any,
    CoEff$ extends {[ns:string]:{[eff:string]:Function}} = any
  >({pm, mu, refs, dbg, $, update, $with}:{
    pm:Pm,
    mu:Cmds,
    refs:Refs,
    update?:CompositeReducerUpdate,
    dbg?:{aspect:IProcessAspect},
    $: ($:$<Pm, Cmds, Refs, $Eff>, $with:$CoProxy<CoEff$>) => $Eff,
    //  unfortunately, typescript doesn't seem to be powerful enough to 
    //  infer the return type of the function and inject it into 
    //  the type of the arguments   
    //  $eff: ($:$<pm, cmds, refs, $Eff>) => $Eff
    // 
    $with?:($:$<Pm, Cmds, Refs, $Eff>) => CoEff$


  }):$<Pm,Cmds,Refs, $Eff> => {
    const cfg = toPmConfig({PM:pm, mu, refs, $:$})
    const $_ = dsl2(cfg, mu, $, $with, pm, dbg, update)
    return $_ as any as $<Pm, Cmds,Refs,$Eff>
  }

