import React, {  useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { createStore, useStore , SStore} from "./anyonic/dsl/createStorePs$";
import { MediaEnv } from "./model/page/resource/MediaEnv";
import { Page } from "./model/page/resource/PageState";
import { UserState } from "./model/page/user/UserState";
import { $Page, createPagePs$ } from "./model/ps/pagePs/usePage";
import { $User, createUserPs$ } from "./ps/auth/useCurrentUserPs";
import { PresencePM } from "./ps/firebase-ps/PresencePM";
import { $Presence, createPresencePs$ } from "./ps/firebase-ps/usePresencePs";

//import { UIState } from "./ui/model/UIState";

import { useNavigate } from "react-router-dom";
import { shouldLoad } from "./model/ps/pagePs/PageEff";
import { FBUser } from "./model/page/user/FBUser";


export const MediaContext = React.createContext(null as any);

export type MediaPsPM<$ui> = {
  $page:$Page
  $presence:$Presence
  $ui:$ui
  $user:$User
} // -- mediaPs



const store = { 
  user:createStore(null),
  page:createStore(null),
  media:createStore(null),
  presence:createStore(null),
  ui:createStore(null)
  // media:
}


var $:MediaPsPM<any>

export type PsFactory<$, pm> = (store:SStore<pm>) => $

const create$= <$ui, uiPm> (
  env:MediaEnv<any>, 
  createUi: PsFactory<$ui, uiPm>
):MediaPsPM<$ui> => ({
  $user: createUserPs$(store.user),
  $page: createPagePs$(env, store.page),  // <-- note that this invokes rendering at "/""
  $presence: createPresencePs$(store.presence),
  $ui:  createUi(store.ui) // createAppUIPs(store.ui),
  //media: useMedia$
})



// -- temporary global mechanism to collect app proceses


/**
 * 
 * General context provider  
 *  
 *  
 */
export const MediaContextProvider = <$ui, uiPM, media>({ 
  env, 
  ps$,
  children
}: {
    env:MediaEnv<media>,
    ps$: {
      ui:PsFactory<$ui, uiPM>
    },
    children?:any 
  }) => {
    
  // 1. create the store (state effect)
  useMemo( () => {
      return $ = $ || create$(env, ps$.ui )    
    }, [env, ps$.ui])  
        
  // 2. start the user process
  useEffect(() => {
    $.$user.start()
  }, [])

  const user:UserState = useUser()

  // 3.  presence process inherently depends on the use state
  useEffect(() => {
    $.$presence.start(user.user)
  }, [user.user] )


  // 4. navigation effects 

  const location = useLocation()
  const navigate = useNavigate()


  useEffect(() => {
    $.$page.page.setLocation(location, navigate)
    $.$user.setLocation(location, navigate)  
  }, [location, navigate]) 


  // 5.  page loading effects 

  const page = usePage()

  useEffect(() => {
    if (shouldLoad(page)) {
      $.$page.load(page)  // <-- probably now can remove all of this
    }
  }, [page] )

  
  return (
    <MediaContext.Provider value ={$}>
      {children}
    </MediaContext.Provider>
  )

}

export const useUser = ():UserState => useStore(store.user)

export const useCurrentUser = ():FBUser => useStore(store.user, ({user}) => user)
export const usePage = ():Page => useStore(store.page, ({page}) => page)


export const usePage$ = ():{$:$Page, page:Page} => ({
  $:$.$page,
  page:usePage()
})

export const usePresence = () => useStore(store.presence)



export const useUI = (selector?):any/*UIState*/ => useStore(store.ui, selector)
export const useUI$ = (selector?):{$:any/*$UI*/, pm:any/*UIState*/ } => {
  return {
    $:$.$ui,
    pm: useUI(selector)
  }
}



/*
export const useMedia$:(() => {$:$MediaPs, media:MediaState}) = () => {
  return {
    $:$.media,
    media:useStore(store.media)
  }
}

*/
export const usePresence$:(() => {$:$Presence, pm:PresencePM}) = () => {
  return {
    $:$.$presence,
    pm: useStore(store.presence)
  }
}

