import fb from "../../../firebase/firebase"
import { FBUser } from "../../../model/page/user/FBUser"
import { BubbleReading } from "../model/doc/BubbleModel"



export const toSimpleEntity = <a>(data:a) => {
  return {
    updated: fb.TIMESTAMP(),
    data:JSON.stringify(data)
  }
}

export const fromSimpleEntity =  entity => {
  const out = JSON.parse(entity.data)
  return out
} 

export const toEntity =  (fb, project, src, user:FBUser, data? ) => {

    const base = {
  
      owner:user.uid,
      ownerName:user.name,
      
      project,
      src,
    } 
  
    if (data) {
      return {
        ...base,
        imported:fb.TIMESTAMP(),
        data:JSON.stringify(data)
      }
    } else {
      return base
    }
    
  }





  
  
  export const fromEntity = (entity):BubbleReading => {
    const {data} = entity
    return JSON.parse(data) as BubbleReading
  } 
  
  

