import { FormSchema } from "./FormSchema";


export type FormBase<a> = {

    // -- definition
    formSchema:FormSchema[]
    values:a      // <-- the actual values 
    options?:{[field:string]:{id:string, rurl:string}} // <-- options if dynamically loaded
    validators?: any  // {[value]:(value, values) => (string | null)}
    submitMsg?:string

    // -- state
    validatedValues?:a
    ok?:boolean
    formErrors?:{[id:string]:Function[]}  
    hasError?:boolean
    
    // -- this is not about the form, and might better be added via composition
    isSubmitting?:boolean
    err?:string  // <-- larger process

    message?:string

  }


  export type FormOptions = {
    id:string
    rurl:string
  }



const TO_MERGE = ["values", "options", "validators"]
/**
 * Merges the definitions of two forms
 * 
 */
export const extendForm = <a,b>(f1:FormBase<a>, f2:FormBase<b>):FormBase<a & b> => {
  const out:any = {formSchema:[...f1.formSchema, ...f2.formSchema]}; 
  TO_MERGE.forEach(k => ( (f1[k] || f2[k]) && (out[k] = {...(f1[k] || {}), ...(f2[k] || {})})) )
  out.submitMsg = f1.submitMsg || f2.submitMsg
  return out as FormBase<a & b>

}