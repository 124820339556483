import { MediaEnv } from "./MediaEnv"
import { Cache, PageCache } from "./PageCache"
import { Page, PageState } from "./PageState"



/**
 * An rdf-like store of relationns between pages
 *  ie {
 *      parent: '/folder/folder-id'   
 *  } 
 */
export type MediaLinks = {[rel:string]:string}




export const resolveLinks = (page:PageState<any,any>, env:MediaEnv<any>, cache0:PageCache):{cache:PageCache, links:{[rel:string]:string}} => {
    const visited:any = {}
    const links:any = {}
    const cache = resolveLinks_low(page,env, cache0, [], (rurl:string, paths:string[]) => {
      const done:boolean = visited[rurl] !== undefined
      visited[rurl] = true
      links[paths.join(".")] = rurl
      return done 
    })
  
    return {cache, links}
  }
  
  /**
   * Resolve the static hypermedia links upon instantiating a page resource
   * 
   * - may alter the cache, so it needs to be returned
   */
  const resolveLinks_low = ( page0:PageState<any,any>, env:MediaEnv<any>, cache0:PageCache, path:string[] = [], visit:(rurl:string, paths:string[]) => boolean ):PageCache => {
    
    var cache:PageCache = cache0
   
    

    Object.keys(page0.resource.links).forEach(rel => {
      var page:PageState<any,any>
  
      const rurl = page0.resource.links[rel];
      // -- recurse
      ({page, cache} = env.resolvePage(rurl,  cache));
      
      const ps =  path.concat([rel])
      if  (!visit(rurl, ps)) {
        cache = resolveLinks_low(page, env, cache, path.concat([rel]), visit)
      }
    })
  
    return cache
  }
  
  
/**
 * Resolve all PageState instances for links
 */
export const toPageLinks = (rurl:string, cache:PageCache):{[rel:string]:Page} => {
    const page:Page = Cache.retrieve(rurl, cache)!
    const plinks:any = {}
    for (var rel of Object.keys(page.resource.links)) {
        plinks[rel]  = Cache.retrieve(page.resource.links[rel], cache);
    }
    return plinks
  } // resolveLinks
