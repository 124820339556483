import * as zip from "@zip.js/zip.js";
import { chunksToNodes, lqDocxToNotes, nodesToNotes, parseChunk, toChunks } from "./parseLqxml";
import { P } from "../../../anyonic/dsl/dsl-api";


import { useEffect } from "react"
import { usePm$ } from "../../../anyonic/dsl/usePm"
import { usePage, useUser } from "../../../MediaContextProvider"
import { DefaultReducer } from "../../../ps/firebase-ps/PresenceMu"
import { BubbleReading } from "../model/doc/BubbleModel"
import { BubbleNoteServerEff } from "./BubbleNoteServerEff"
import { BubbleDocOld } from "../model/BubbleNotes";
import { docXtoNotes } from "./docXtoBubble";
import { digest } from "../model/doc/note-digest";
import { FBUser } from "../../../model/page/user/FBUser";
import { Tweaks } from "../model/doc/tweak/BubbleTweak";


export type $Upload = {
    ps:any
    pm:((v?:undefined|P<BubbleNotesImportPM>) => BubbleNotesImportPM)& DefaultReducer<BubbleNotesImportPM, void>
    
 

    // -- upload 
    setFile: (file:any) => void
    // -- ui
    toggleView: () => void
    // -- persistence
    server:BubbleNoteServerEff
  
  } & ZipUpload
  
export type ViewStr = "json" | "object" | "preview" | "pm"

  export type BubbleNotesImportPM = {
    ok?:boolean
    target?: {
      projectId:string,
      srcId:string,
      user:FBUser
    }

    tweaks?:Tweaks
    unsavedTweak?:boolean

    // -- save ps
    saveErr?:string
    couldForceSave?:boolean
    saveOK?:boolean
    
    // -- for debug
    ps:any[],    // <-- raw xml (conjerted to json)
    es:any[],    // <-- extracted data (possibly null)
    
    parseError?: string
    e?:any  // <-- 
 
    // -- for the individual component viewer
    i:number     // <-- current index
    raw:string   // <-- raw json of selection
    extracted:string // <-- json of what's extracted
  
    // -- 
    notesStr?:any
    notes?:any 

    // -- the actual data we're producing
    reading?:BubbleReading
  
    // -- ui
    view:ViewStr
    viewStates:["pm", "json", "object", "preview"]
  }
  
  const state0:BubbleNotesImportPM = {
 
    ps:[], es:[], i:-1,raw:"", extracted:"",
    view: "pm",
    viewStates: ["pm", "json", "object", "preview"]
  
  }
  
  export const useBubbleNotesImport = ():{$:$Upload, pm:BubbleNotesImportPM} => {
    const page = usePage()
    const user = useUser()
    const {$, pm} = usePm$(() => ({
      pm:state0,
      $:$ => ({
        pm: $.mu.pm,
        ...ZipUpload($),
        server:BubbleNoteServerEff($)
        
      })
    })) as any
  
    useEffect(() => {
        const {project, src} = page.resource.params;
        if (project && src) {
          $.server.start(project,src, user.user)
        }
    }, [page.resource.params, user.user, $]) 

    return {$,pm}
  }
  
  type ZipUpload = {  
    setFile: (file:any) => void
    next: () => void
    prev: () => void
    pg  : (n:number) => void 
    setView: (view:string) => void
  
  }
  
  
  const ZipUpload = ($:$Upload):ZipUpload => ({
  
    setFile: event => {
      const file = event.target.files[0];  
      if (file) {
        const p = $.ps((Ok, Err)  => {
          (new zip.ZipReader(new zip.BlobReader(file))).getEntries({})
          .then(entries => {
            const data = entries.find(entry => entry.filename === "word/document.xml")
            const writer = new zip.TextWriter()
            data.getData(writer)
              .catch(e => {
                Err("unable to unzip .docx")
              }).then( (txt:string) => {
                //const parser = new DOMParser();
               // const xmlDoc = parser.parseFromString(txt,"text/xml");
                Ok(txt)
              }).catch(e => {
                Err("unable to parse .docx xml")
              })
  
          p.then(xmltxt => {
            
            try {
              const json = lqDocxToNotes(xmltxt)
              const ps = toChunks(json)
  
              const es = ps.map(parseChunk)
              var nodes = chunksToNodes(es)
              
              var notes:BubbleDocOld = nodesToNotes(nodes, digest);
              
              const reading = docXtoNotes(notes)
  
              const notesStr = JSON.stringify(notes, null, 2)
              $.pm({
                ps, es, reading, raw:null, extracted:null, i:0, ok:true, notes, notesStr
               })
            } catch (e) {
              console.log(e.message)
              console.trace(e.stack)
              const msg = e.message
              $.pm({parseError:msg, e})
            }
            
          }).catch(e => {
            Err("??" + e.message)
          })
   
  
      
        
         //$.img.loadImagePreview(file)
        
        })  
       })
      }
    }, // setFile
  
    prev: () => {
      const pm:BubbleNotesImportPM = $.pm()
      if (pm.i > 0) {
        const { raw, extracted } = parseBN(pm.ps[pm.i-1])
        $.pm({i: pm.i - 1, raw, extracted})
      }
    }, 
    next: () => {
      
      const pm:BubbleNotesImportPM = $.pm()
  
      if (pm.i < (pm.ps.length -1)) {
        const { raw, extracted } = parseBN(pm.ps[pm.i+1])
        $.pm({i: pm.i - 1, raw, extracted})
      }
  
  
    }, 
  
    pg: n => {
      const pm:BubbleNotesImportPM = $.pm()
      const { raw, extracted } = parseBN(pm.ps[n])
      $.pm({i: n, raw, extracted})
    },
  
    setView: (view:ViewStr) => $.pm({view})
  
  
  })
  
  
  const parseBN = (p) => {
  
    const raw = JSON.stringify(p, null, 2)
    const v0 = parseChunk(p)
    const extracted = v0 ? JSON.stringify(v0) : "--EMPTY--"
  
    return {raw, extracted}
  }
  
  
  