import { EditorConfig, TextNode } from "lexical";

// https://lexical.dev/docs/concepts/nodes#extending-textnode

export class RefNode extends TextNode {
  
  __className:string
  __color: any

  static getType() {
    return "ref";
  }

  static clone(node) {
    return new RefNode(node.__field, node.__key);
  }

  constructor(ref, key?) {
    super(ref, key)
    this.__className = "antiform-field";
    this.__color =  "#51ACC5"  // "#E65634" 
  }

  

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.style.color = this.__color;
    return element;
  }

  updateDOM( prevNode: RefNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    if (prevNode.__color !== this.__color) {
      dom.style.color = this.__color;
    }
    return isUpdated;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  isTextEntity(): true {
    return true;
  }
}

export function $isRefNode(node) {
  return node instanceof RefNode;
} 

export function $createRefNode(fieldName):RefNode {
  const node = new RefNode(fieldName).setMode("token");
  return node
}
