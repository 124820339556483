import { usePage } from "../../../MediaContextProvider"
import { isReady } from "../../../model/page/resource/PageState"
import ResourceStatus from "../../util/Resources"
import VidCard from "./VidCard"

export const PFolder = () => {
    
    // -- invoke resource loading her
    const page = usePage()
    
    if (!isReady(page!)) {
        return <ResourceStatus/>
    }

    return <div> 
        {renderFolder(page!.data)}
    </div>

}

const renderFolder = data => {


    return (<div>
        <h2>  
            Folder: {data.name}
        </h2>
        <div>
            {data.videos.videos.map(vid => Video(vid))}
        </div>

    </div>)
}

const Video = vid => {
    const picture = vid.pictures.sizes[1]
    const title = vid.name
    return <VidCard key={vid.uri} title={title} picture={picture.link_with_play_button}/>
}



