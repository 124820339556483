
import { Box } from "@mui/material"

import { alpha } from '@mui/material/styles';

export const Slide = ({children, supTitle, title }) => (<Box sx={{
    position:"fixed",
    overflow: "scroll",
    top: 50,
    bottom:80,
    left:50,
    right:50,
    px:40, 
    pt:7,
    background: ({palette}) => alpha(palette.slide.bg, 0.95),    
  }}>
  
  
    <Box sx={{typography:"appSubtitle", color:({palette})=> palette.slide.txt }}>
  
      <Box sx={theme => ({
        fontSize:17,
        display:"flex",
        
        color:theme.palette.slide.RED,
        pt:3, pb:7
      })}>
        {supTitle}
      </Box>
  
      <Box sx={{ borderTop:1, borderColor:({palette}) => palette.slide.RED,  width:"100%"}}/>
  
      <Box sx={({palette}) => ({typography:"appTitle", color:palette.slide.txt, py:4})}>
        {title}
      </Box>
  
      <Box sx={{ borderTop:1, borderColor:({palette}) => palette.slide.RED,  width:"100%"}}/>
      
  
      <Box sx={{typeography:"appSubtitle", pt:10, fontSize:20}}>
  
      {children}
  
      </Box>
  
  
    </Box>
  </Box>)
  
    
  