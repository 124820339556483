import { shallowEqual } from "react-redux";
import {  keys2o} from "../../../../anyonic/dsl/dsl-api";
import { BubbleNote, BubbleReading, Notes } from "./BubbleModel";
import { Chapter, ChPages, eachNode, Group, Leafs, mergeTaggings, Note, NoteAction, NoteNode, NotePage, PgActions, PgTags, Tag, Taggings} from "./BubblePM";
import { ChapterTweak } from "./tweak/BubbleTweak";


const toNote = (note, did, pm) => Note(note,did,pm)

const notNull = v => (v != null)

export const toLeaves = (notes:Notes, f?):Leafs<null> => 
  keys2o(notes, (k,v, o) => (o[k] = toNote(v, k, f ? f(k,v) : null))) 


export const toPagesPM = <c,p,n>(reading:BubbleReading, fns:PgFns<c,p,n>):NotePage<p,null,n,null>[] => {
  const {notes, pages} = reading
  const leafs:Leafs<n> = toLeaves(notes, fns.notePm)

  
  const lookup = (id:string):Note<n> => {
    if (!leafs[id]) {
      throw new Error(`unknown page: + ${id}`)
    }
    return leafs[id]
  }

  const pageNos:number[] = Object.keys(pages).map(n => Number(n)).sort((a, b)  => (a-b))

  const out = pageNos.map(n => {
    var dids = pages[n]  // ie. [id1, id2, [id3, id4], etc]
    if (dids.length > 0) {
      const nodes:NoteNode<null,n,null>[] = dids.map(did => {
        if (did instanceof Array) {      
          return Group( did.map(lookup), null)   // ie. wrap [id1, id2] in a Group 
        } else {
          return lookup(did)
        }
      }) 
      const pm = fns.pgPm(n, nodes)
      return NotePage(nodes, pm)
    }
    return null
  }).filter(notNull)
   
  return out as NotePage<p,null,n,null>[]

} // toPM


export const getNoteById = <p,g,n,s>(
  chapters:Chapter<any,p,g,any, any>[], 
  //pages:NotePage<p,g,n,s>[], 
    did:string
  ):{ok:boolean, note?:Note<n>, group?:Group<g,n,s>, page?:NotePage<p,g,n,s> } => {
   for (var ch of chapters) {
    for (var page of ch.pages) {
      for (var node of page.nodes){ 
        if (node.$$ === "Group") {
          for (var note of node.notes) {
            if (note.$$ === "Note") {
              if (note.did === did) {
                return {ok:true, page, group:node, note}
              } 
            } 
          } 
        } else if (node.$$ === "Note" && node.did === did) {
          return {ok:true, page, note:node}
        }

      }
    }
  }
  return {ok:false}
}

export type ChapterMapFns<c0,c> = {
  cmap?:((chapter:Chapter<c0,any, any,any,any>, chapters, i) => c)
}


export type PageMapFns<p0,p> = {
  pmap?:((page:NotePage<p0,null, null,null>, pages, i) => p)
}




export type MapFns<c0,p0,c,p> = PageMapFns<p0,p> & ChapterMapFns<c0,c>


type x = any
/**
 * Combines functorality in chapter pm:c and page pm:c, 
 * returning the identity object when no changes are made
 *  (suitable for memoization)
 */
export const mapChapters = <c0,c,p0,p>(chapters:Chapter<c0,p0,x,x,x>[], fmaps:MapFns<c0,p0,c,p>):Chapter<c,p,x,x,x>[] => {
  const {cmap} = fmaps
  const out = chapters.map((ch, chs,i)=> {
    const pm:c = cmap  ? cmap(ch, chs,i)  : (ch.pm as any)
    const pages = mapPages(ch.pages, fmaps)
    return (shallowEqual(pages, ch.pages)) && (pm as any === ch.pm)
      ? ch : {...ch, pages, pm}
  })
  return shallowEqual(chapters, out) ? chapters as any : out  
}


export const mapPages = <p0,p>(pages:NotePage<p0,x,x,x>[], fns:PageMapFns<p0,p>):NotePage<p,x,x,x>[] => {
  const {pmap} = fns
  const out = pages.map((pg, pgs, i) => {
    const pm:p = pmap ? pmap(pg, pgs, i) : (pg.pm as any as p)
    return (pm as any=== pg.pm) as any ? pg : {...pg, pm }  as any
  })
  return shallowEqual(pages, out) ? pages : out
}


export type PgFns<c,p,n> = {
  chPm: (ch:ChapterTweak, pages:NotePage<p,null,n,null>[], i:number) => c,
  pgPm: (pgNo:number,nodes:NoteNode<null,n, null>[]) => p,
  //gpPm: (leafs:Leaf<n,s>) => g,
  notePm: (did:string, note:BubbleNote) => n,
  //splatPm: () => s
}

export const pgFnsNoop:PgFns<null, null, null> = {
  chPm: _ => null,
  pgPm: _ => null,
  notePm: _ => null
}



/**
 * 
 * mapping function to create inital pmodel /w taggings
 * 
 */
export const initTags = (taggings:Taggings, allTags:Tag[], actions?:NoteAction[]):PgFns<ChPages,PgTags&PgActions,null> => {

  return {

    chPm: (ch:ChapterTweak, _, i ) => {
        return {
          n:i,
          id:i.toString(),
          label: ch?.label ? `Ch ${ch.label}` : `Ch ${i+1}`
        }
    },

    pgPm: (pgNo:number,nodes:NoteNode<PgTags&PgActions,null, null>[]) => {

      var pgTaggings = {}
      eachNode(nodes, ({did}) => (pgTaggings[did] = taggings[did]))

      const tags = Object.keys(pgTaggings).length > 0 ? mergeTaggings(pgTaggings) : undefined
      const pm:PgTags&PgActions = { allTags, taggings:pgTaggings, tags, pg:`pg${pgNo}` }
      if (actions) {
        pm.actions = actions
      }
      return pm
    
    },

    notePm: _ => null
     

  }
}