import { morph } from "../../../anyonic/dsl/dsl-api"
import assert from "../../../util/assert"
import { Cache } from "../../page/resource/PageCache"
import { Page, toPath } from "../../page/resource/PageState"

export type PageMu<r> = {
    Update: (rurl:string, updateFn:(p:Page) => Page , andSelect?:boolean,) => r
    
    LOAD_START: (rurl:string, andUpdate?:boolean) => r
    LOAD_ERROR: (rurl:string, e:{message:string}) => r
    LOAD_COMPLETE: (rurl:string, data:any) => r
    
  } 
  

  export const pageMu:PageMu<any> = {

    Update: (rurl, updatePage:(page:Page) => Page, andSelect?:boolean) => (mu, s) => {
      // 
      const cache = s._ps.cache
      const page0:Page = Cache.retrieve(rurl, cache) // <-- TODO - cache management is precicely what we wich to avoid
      
      if (page0) {
        const page = updatePage(page0)   
        assert(page, "page failed to update")
        if (andSelect || (toPath(s.page) === rurl)  ) {
          mu.page.Put(page)  // <-- this selects
        }
        mu._ps({cache: Cache.update(rurl, page,cache)})
      }
    },
    
    LOAD_START: (rurl, andSelect?:boolean) => mu =>   
      mu.Update(rurl, setLoading, andSelect),

    LOAD_ERROR: (rurl, e) => mu => mu.Update(rurl, setError(e)),

    LOAD_COMPLETE: (rurl, data) => mu => mu.Update(rurl, setData(data) )

  }



// -- lenses
const setLoading = (page:Page):Page => morph(page, { 
  status: morph(page.status, { isLoading:true, isReady:false })
}) 


const setData = data => (page:Page) => morph(page, {
  data, 
  status:morph(page.status, {isLoading:false, isReady:true, errorMsg:undefined })
}) 






const setError = (e:{message:string}) => (page:Page):Page => morph(page, {
  status:morph(page.status, {
    isLoading:false, 
    isReady:false,   
    errorMsg: [`error loading "${page.resource.rurl}" msg: "${e.message || " (unknown error message)"}"`],
  })
})


    
    