import firebase from '../../firebase/firebase'


// -- Q: do we couple this to 
export const login = $ => (email, password) => $.run(function*($$) {
    
  // -- FIX_THIS -- adds a ton of completely unnecessary boilerplate around 
  //                a simple promise

  const out = yield $$.await(() =>  new Promise((accept, reject) => {
    firebase.auth.signInWithEmailAndPassword(email, password)
      .then(signedInUser => {
        console.log(signedInUser);
        accept({ok:true})      // <-- note that the actual use will be recieved by a seperate listenng process
      })
      .catch(err => {
        accept({ok:false, err})
      })
  }))
  return out
})

export const logout = $ => () => $.mu( (_, s) => {
  console.log('x');
  firebase.auth.signOut()   // <-- recalling that the resulting (asynchronous) change in state
})                           //     is listened for elsewhere

    



