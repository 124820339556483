import { BubbleDocOld, BubbleNote as BubbleNoteOld, BubbleNoteOrGroup } from "../model/BubbleNotes"
import { Notes, Pages, BubbleNote, DEFAULT_TYPE} from "../model/doc/BubbleModel"
import { digest } from "../model/doc/note-digest"
import { colMap } from "./colMap"


const filterUnNumbered = (doc:BubbleDocOld):BubbleDocOld => {
  


  const nodes1 = doc.nodes.filter((node:BubbleNoteOrGroup) => {
   

    if (node.$$ === "Group") {
      const hasPg = node.nodes.reduce((v, note:BubbleNoteOld) => {
        return v || !isNaN(note.pg)
      }, false)
      return hasPg
    } else {
      return !isNaN(node.pg)
    }
  })

  const nodes = nodes1.map((node:BubbleNoteOrGroup) => {

 
   if (node.$$ === "Group") {
      return {
        ...node,
        nodes: node.nodes.filter( (note:BubbleNoteOld) => (!isNaN(note.pg)))
      }
    } else {
      return node
    }
  })



  return {
    ...doc, 
    nodes
  }
}





export const docXtoNotes = (doc:BubbleDocOld):{pages:Pages, notes:Notes} => {
    const pages:Pages = {}
    const notes:Notes = {}
  
    doc = filterUnNumbered(doc)

    var currentPage:number = -1

    doc.nodes.forEach( (node) => {
      var idOrIds:string |string[]
      
      if (node.$$ === "Group") {
        idOrIds = node.nodes.map(leaf => {
          const {did, note} = toNote(leaf)
          notes[did] = note
          return did
        })
      } else {
        // -- create the note

        
        
        
        const {did, note} = toNote(node)
        notes[did] = note
        idOrIds = did
      
      }  
      // -- figure out what page we should be on
      const currentPage1 = toPgNo(currentPage, node  )
      if (isNaN(currentPage1) || currentPage1 <= 0) {
        toPgNo(currentPage, node  )
        throw new Error("No pg#: "+ JSON.stringify(node))
      }
      currentPage = currentPage1
      var pgIds = (pages[currentPage] = pages[currentPage] || []) 
      pgIds.push(idOrIds)
       
    })
    return {pages, notes}
  }
  /*
  const toNextPg = (i, nodes:BubbleNoteOrGroup[]):number => {
  
    if ((i+1) >= nodes.length) {
      return toPg(nodes[i])
    }
    return toPg(i+1)
  
  }
  */
  const toPg = (n:BubbleNoteOrGroup):number => {
    if (n.$$ === "Group") {
      return n.nodes[0].pg
    } 
    return n.pg
  }
  
  
  const toNote = (node):{note:BubbleNote, did:string} => {
    const {did, type, text, pg, col} = node;
    

     var note:BubbleNote = {
      text,
      pg
    }

    if (type && type !== DEFAULT_TYPE) {
      note.type = type
    }

    const mappedCol = colMap(col)
    if (mappedCol && mappedCol.length > 0) {
      note.col = mappedCol
    }
    


    const did1 = digest(note)
  
    if (isNaN(pg) || pg <= 0) {
      //throw new Error("no page number")
    }
    if (did !== did1) {
      console.log('digest integrity issue here')
    }
    return {note, did}
  }
  
  
  /**
   * 
   * 
   */
  const toPgNo = (currentPage:number, node:BubbleNoteOrGroup):number => {
     const pg = toPg(node)
    
     // -- 1. first page 
    if (currentPage < 0) {
      return pg 
    }
  
    // -- 2. same or later page
    if (pg === currentPage) {
      return currentPage
    }
  
    // -- 3. except from previous page ... include on current page
    if (pg < currentPage) {
      return currentPage 
    }
  
    // -- 4. advance current page 
    if (pg > currentPage) {
       return pg
    }
  
  
  }
  