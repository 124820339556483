export const toPlayerUrl = (id, options) => {
    const qs =  Object.keys(options || {}).reduce((acc, k) => {
        acc.push(`${k}=${options[k]}`)
        return acc
    }, [] as string[])

    const query = qs.join("&")
    return `//player.vimeo.com/video/${id}?${query}`;
}

