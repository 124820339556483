import { usePage } from "../../../MediaContextProvider"
import { PageState } from "../../../model/page/resource/PageState"
import { Slide } from "../view/typeography/Slide"
import { MLink, RefGroupView } from "./RefGroupView"
import { $Refs, RefsPM, useProjectRefs } from "./useProjectRefs"


type ProjectParams = {
  project:string
} 


export const ProjectView = () => {
  
  const page:PageState<any,ProjectParams> = usePage()
  const {project} = page.resource.params
  const {$, pm} = useProjectRefs(project);
  const {doc} = pm

  return (<Slide supTitle={renderSup($,pm)} title={pm.title}>
    {!doc
      ?
        (<div>...loading </div>)
      :
        doc.groups.map((g,i) => (<RefGroupView  key={i} group={g} />))
    } 
  </Slide>)
}




const renderSup = ($:$Refs, pm:RefsPM) => {
  return (<>

    RESOURCES
  
    {" "}
    {pm.ok && 
 
      <MLink  sx={{ width:"100%", alignSelf: 'flex-end'}}
      onClick={$.toggleComments}>
        {" "}
        {pm.showComments ? " -annotated" : " +annotated"}
      </MLink>}
  </>)
}