
import { usePage } from "../../MediaContextProvider"
import { Editor } from "../antiform/Editor"
import { RLink } from "../notes/model/view/type/RefLink"
import { Slide } from "../notes/view/typeography/Slide"
import { $ProjectEditor, ProjectPM,  useProjectRefDocEditorPs } from  "./useProjectRefDocEditorPs"


export const ProjectEditor = () => {
  
  const page = usePage()
  const {project} = page.resource.params

  const {$,pm} = useProjectRefDocEditorPs(project)
  
  const ok = pm.loaded
  
  if (ok) {
    console.log('x')
  }

  return <Slide supTitle={renderStatus($, pm)} title="Ignore this for now ..." >

    {renderProjectEditor($,pm)}

    <div style={{visibility: ok ? "visible" : "hidden"}}>
      <Editor onChange={$.editor.changed} onStart={$.editor.start} />
    </div>

  </Slide>
}

const renderStatus = ($:$ProjectEditor, pm:ProjectPM) => {
  
  return (
  <>
    PROJECT {" "}
    {pm.exists 
      ? null 
      : (<RLink onClick={$.xDoc.create}>
          create
        </RLink>)}
    {pm.exists && pm.unsaved 
      ? (
        pm.saving 
        ? "saveing..." 
        : (<RLink onClick={$.xDoc.save}>
            save
          </RLink>)) 
        : null}

    </>)
  
}

const renderProjectEditor = ($:$ProjectEditor, pm:ProjectPM) => {

  if (pm.loading) {
    return (<div> loading... </div>)
  } 

  if (pm.exists === "unknown") {
    if (pm.unavailable) {
      return <div> You appear to be offline ... </div>
    } else {
      return <div> still working out if project exits ...</div>
    }

  }
  if (pm.exists === false) {
    return (<div>
      This project does not exist
      {" "}
      <RLink onClick={$.xDoc.create}>
        create 
      </RLink>
    </div>)
  }


}

