import * as React from 'react'
import {  Button } from "@mui/material";
import {  Container, TextField } from "@mui/material";

//import { makeStyles } from '@mui/styles';

import { FormBase } from '../../ps/form/FormBase';
import { Form$ } from '../../ps/form/form-ps';
import { styled } from '@mui/system';



//const useStyles = makeStyles(form as any);

// see custom styles https://codesandbox.io/s/customizedinputs-material-demo-forked-5wmbd?file=/demo.js:3242-3411

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
    backgroundColor:"white"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
    backgroundColor:"white"


  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      backgroundColor:"white"

    },
    "&:hover fieldset": {
      borderColor: "black"
    },
    "&.Mui-focused fieldset": {
      borderColor: "black"
    }
  }
});

/*
const RedditTextField = styled((props:any) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
      ))(({ theme }:{theme:any}) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "transparent",

   // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.17)"
    },
    "&.Mui-focused": {
      backgroundColor: "rgba(0, 0, 0, 0.67)",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  }
}));

*/


export const BaseForm = (pm:FormBase<any>, $:Form$) => {
    
    //const classes:any  = useStyles()
    //const {  onChange, onBlur, onSubmit, values, errors, isSubmitting } = formPropss
    
    const {formErrors:errs, formSchema, values, isSubmitting/*, hasError*/} = pm

    return(
      <Container component="main" maxWidth="xs">
  
        <div >
          {/*
          className={classes.paper2}
          <Avatar className={classes.avatar}>
            <FaceIcon />
            </Avatar>
          <Typography component="h1" variant="h5">
            
          </Typography>
          className={classes.form}
          */}

          <form  noValidate onSubmit={$.submit} >
            {formSchema.map( ({id,label ,autocomplete , isPassword}) => {
              var err:any = errs ? errs[id] : undefined
            return (
/*
                <CssTextField 
                  key={id} 
                  name={id}
                  id={id}
                  variant="filled"
                  type={isPassword ? 'password' : undefined }
                  autoComplete={autocomplete || "off"}
                  autoFocus
                  size="small"  
                  label={label}
                  value={values[id]}
                  margin="none"
                  required
                  fullWidth
                
                
                  onChange={$.change}
                  error={ err ||  false}
                  helperText = {err || err}
                
            
                  />*/
                <CssTextField 
                 label={label}
                 key={id} 
                 name={id}
                 id={id}
                 variant="filled"
                 type={isPassword ? 'password' : undefined }
                 autoComplete={autocomplete || "off"}
                 autoFocus
                 size="small"  
                 value={values[id]} 
                 onChange={$.change}
                 error={ err ||  false}
                 helperText = {err || err}
                 />
  
            )})}
            
            
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              className={classes.submit}
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              
              disabled={isSubmitting}
            >
              {pm.submitMsg || "Submit"}
            </Button>
            {/*
            <Grid container>
             
              <Grid item xs>
                <Link href="/forgot">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="login">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            */}
          </form>
        </div>
       
      </Container>
    )}
  
  



//    const displayErrors = errors => errors.map((error, i) => <p key={i}>{error.message}</p>);
