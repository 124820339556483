import { Tag } from "@mui/icons-material"
import { keys, keys2o } from "../../../../anyonic/dsl/dsl-api"
import { BubbleNote } from "./BubbleModel"
import { ChapterTweak } from "./tweak/BubbleTweak"

// Presentation model wrapped around core BubbleNotes model
// 
// 

export type Leafs<n> = {[did:string]:Note<n>}

export type Tag = string
export type Tags = {[tag:string]:boolean}
export type Taggings = {[did:string]:Tags}
export type Page_ = NotePage<any, any, any,any>
export type Node = NoteNode<any,any,any>

export type NoteDoc<d,p,g,n,s> =  {
  src:any
  pages:NotePage<p,g,n,s>[]
  pm:d
}

export type ChPages = {
  label:string  // <-- drawn from tweak annotation if present
  n:number // <- index ()
  id:string
}

export type PgTags = {
  pg:string
  allTags:Tag[]
  taggings:Taggings // <-- copy of all taggings on all nodes
  tags:Tags  // <-- merging of all tags for quick check if this page posesses this tag
}

export type NoteAction = {label:string, action:string}

export type PgActions = {
  actions?:NoteAction[]
}


export type NtTags = {
  tags:Tags|undefined
}


export type NotePage<p,g,n,s> = {
  nodes:NoteNode<g,n,s>[]
  // -- extract to pm:PgPm
  //allTags:Tag[]
  //tags:Taggings  // <-- just the taggings relevant to this page
  pm:p     // <-- paramaterized view data 
}

// -- not export 
const GROUP = "Group"
const NOTE = "Note"
const SPLAT = "Splat"

export const isGroup = (v:Node):boolean => (v.$$ === GROUP)
export const isNote = (v:Node):boolean => (v.$$ === NOTE)
export const isSplat = (v:Node):boolean => (v.$$ === SPLAT)

export type NoteNode<g,n,s> = Splat<s> | Note<n> |Group<g,n,s>
export type Leaf<n,s> = Splat<s> | Note<n>

// -- nodes 
// a group is a list of leafs, and otherwise structureless
export type Group<g,n,s> = {
  $$:"Group"
  notes:Leaf<n,s>[]
  pm:g
}

// -- pmodel wrapper around that basic bubble note
export type Note<n> = {
  $$:"Note"
  note:BubbleNote
  pm:n
  did:string  // <-- copy of note.did for convenience
}

// -- splat may be used to indicated filtered notes, or inject other ui
export type Splat<s> = {
  $$:"Splat"
  pm:s             // <-- assume that the pmodel paramater is the same as for group    
}

export type Chapter<c,p,g,n,s> = {
  ch:{
    label?:string
  }
  pages:NotePage<p,g,n,s>[]
  pm:c // <-- chapter pm
}


// -- constructors
export const Chapter =  <c,p,g,n,s>(
    ch:ChapterTweak, pages:NotePage<p,g,n,s>[], pm:c
  ):Chapter<c,p,g,n,s> => ({ch, pages, pm })


export const NotePage = <p,g,n,s>(
    nodes:NoteNode<g,n,s>[], pm:p
  ):NotePage<p,g,n,s> => ({nodes, pm})

export const Group = <g,n,s>(
    notes:Leaf<n,s>[], pm:g
  ):NoteNode<g,n,s> => ({$$:GROUP, notes, pm})

export const Note = <g,n,s>(
    note:BubbleNote, did:string, pm:n, 
  ):NoteNode<g,n,s> => ({$$:NOTE, note, pm, did})

  
const Splat = <g,n,s>(pm:s):NoteNode<g,n,s> => ({$$:SPLAT, pm})

export const eachPgNote = <n>(page:NotePage<any,any,n,any>, f:(note:Note<n>) => void) => { 
  eachNode(page.nodes, f)
}

export const eachNode = <n>(nodes:NoteNode<any,n,any>[], f:(note:Note<n>) => void) => {
  for (var node of nodes) {
    if (node.$$ === "Group") {
      for (var leaf of node.notes) {
        if (leaf.$$ === "Note") {
          f(leaf)
        }
      }
    } else if (node.$$ === "Note") {
      f(node)
    } else  {
      // splat
      // do nothing
    }
    // ignore splats
  }
}


// -- convenience method to extract orderd list of dids + a hash to their leaf notes
type _ = any

export const toHash = <n>(pages:NotePage<_,_,n,_>[]):{byDid:{[did:string]:Note<n>}, dids:string[]} => {
  const byDid = {}
  const dids:string[] = [] // <-- ordered list if note dids
  pages.map(page => eachPgNote(page, note => {
    byDid[note.did] = note
    dids.push(note.did) 
  }))
  return {byDid, dids}
}

type x = any

export const nodesToTaggings = (nodes:NoteNode<x,NtTags, x>[]):Taggings => {
  var out:Taggings = {}

  eachNode(nodes, ({did,pm}) => {
    if (pm.tags) {
      out[did] = pm.tags
    }
  })

  return out
}

export const mergeTaggings = (taggings:Taggings):Tags => keys2o(taggings, (_,tags, o) => keys(tags, k => (o[k] = true)))




