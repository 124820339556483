import { Chapter, eachNode, Note, NotePage, PgActions, PgTags } from "../../doc/BubblePM";
import { BubbleViewPM } from "./BubbleViewPM";



export const toReferenceStr = (pm:BubbleViewPM):string => {
  const ref = `[${pm.srcRefName}]`
  const out:string[] = [
    //`Notes from: ${ref} ${pm.title}]`, 
    //'---- ',
    //' '
  ] 
  const emit = (v:string) => out.push(v)

  pm.fnotes.map( (ch:Chapter<any,PgTags&PgActions,any,any, any>) => {
    ch.pages.map( (pg:NotePage<PgTags&PgActions,any,any, any> ) => {
      eachNode(pg.nodes, (note:Note<any>) => {
        emit(' ')
       // emit(`-- pg: ${pg.pm.pg} (pdf page not book page) --`)
        const text = `${note.note.text}   ${ref}`
        emit(' ')
        emit(text)
      })
    })
  })

  out.push(' ')
  out.push('---')

  return out.join('\n')
}


  

  
