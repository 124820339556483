import { Async, CPromise, cPromise, Err, Ok } from "./dsl-api"


const wait = t => cPromise(acc => { 
    const id = setTimeout(() => {
       acc(undefined)
      }, t)
    return () => {
      clearTimeout(id)
    }
   })
  
  const await0 = fnP => fnP()   // <-- returns a promise  // await?
  
  
  
  const awaitR = fnP => (new CPromise(acc => {
    var p:any = fnP().then(v => acc(Ok(v))).catch(e => acc(Err(e)))
    return () => { if (p.cancel != null) p.cancel() }
  }))
  
  const ps = fn => {     // ie yield $$.ps( (Ok, Err) => {  ... async funtionality return  } 
      var cancel:any
      var p = new CPromise((acc, rej) => (cancel = fn( v => acc(Ok(v)), e => acc(Err(e)) )))    // ie fn( (Ok, Err) => {  ... return () => stop() })
      p.cancel = cancel || p.cancel  
      return p  
    }
  
  
  export const AsyncUtil = Async({wait, await:await0, awaitR, ps})
   
  