import * as React from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import { ONLINE } from '../../ps/firebase-ps/PresencePM';



import { styled } from '@mui/system';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { usePresence } from '../../MediaContextProvider';



const StyledBadge = styled(Badge)(({ theme }:{theme:any}) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  

const cols = ["#8D8741","#659DBD","#DAAD86","#BC986A","#FBEEC1"]

//const cols = ["#844D36", "#474853", "#86B3D1", "#AAAOAO", "#8E8268"]
//const cols = ["#EAE7DC", "#D8C3A5", "#8E8D8A", "#E98074","#E85A4F"]

export const Avatars = () => {
  const pm = usePresence()

  return (<AvatarGroup max={4 } sx={{flexDirection:"row"}} >  

    {pm.userPresences.map( (u, i) =>  {
      const col = cols[i%cols.length ]

      return (<StyledBadge
          key={u.user.uid}
          invisible={u.status !== ONLINE}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
         
          variant="dot">
          <Avatar alt={u.user.name} src="/static/images/avatar/1.jpg"  
                  sx={{bgcolor:col,
                      fontSize:23,
                      fontFamily:"'Dancing Script', cursive",
                      color:"black",
                  }}>
              {u.user.name.charAt(0)}
          </Avatar>
      </StyledBadge>)
      })}
    </AvatarGroup>)
  }

