import {$isMarkNode} from '@lexical/mark';
import {
  EditorState,
    // --
  $getRoot,
  $getSelection,
  $isElementNode,
  $isGridSelection,
  $isRangeSelection,
  $isTextNode,
  NodeSelection,
  GridSelection,
  RangeSelection,
} from 'lexical';
//import { $isAntiformNode } from '../form/AntiformNode';
import { visitTree } from './treeVisit';



const NON_SINGLE_WIDTH_CHARS_REPLACEMENT: Readonly<Record<string, string>> = Object.freeze({
  '\t': '\\t',
  '\n': '\\n',
});

const NON_SINGLE_WIDTH_CHARS_REGEX = new RegExp(
  Object.keys(NON_SINGLE_WIDTH_CHARS_REPLACEMENT).join('|'),
  'g',
);


const SYMBOLS = Object.freeze({
    ancestorHasNextSibling: '|',
    ancestorIsLastChild: ' ',
    hasNextSibling: '├--',
    isLastChild: '└',
    selectedChar: '^',
    selectedLine: '>',
  });



  export function generateContent(editorState: EditorState): string {
    let res = ' root\n';
  
    const selectionString = editorState.read( () => {

      const selection = $getSelection();
  
      visitTree($getRoot(), (node, indent) => {
        const nodeKey = node.getKey();
        const nodeKeyDisplay = `(${nodeKey})`;
        const typeDisplay = node.getType() || '';
        const isSelected = node.isSelected();
        const idsDisplay = $isMarkNode(node)
          ? ` id: [ ${node.getIDs().join(', ')} ] `
          : '';
  
        res += `${isSelected ? SYMBOLS.selectedLine : ' '} ${indent.join(
          ' ',
        )} ${nodeKeyDisplay} ${typeDisplay} ${idsDisplay} ${printNode(node)}\n`;
  
        res += printSelectedCharsLine({
          indent,
          isSelected,
          node,
          nodeKeyDisplay,
          selection,
          typeDisplay,
        });
      }, [], SYMBOLS);
  
      return selection === null
        ? ': null'
        : $isRangeSelection(selection)
        ? printRangeSelection(selection)
        : $isGridSelection(selection)
        ? printGridSelection(selection)
        : printObjectSelection(selection);
    });
  
    return res + '\n selection' + selectionString;
  }
  
  function normalize(text) {
    return Object.entries(NON_SINGLE_WIDTH_CHARS_REPLACEMENT).reduce(
      (acc, [key, value]) => acc.replace(new RegExp(key, 'g'), String(value)),
      text,
    );
  }
  
  function printNode(node) {
    if ($isTextNode(node)) {
      const text = node.getTextContent(true);
      const title = text.length === 0 ? '(empty)' : `"${normalize(text)}"`;
      const properties = printAllProperties(node);
      return [title, properties.length !== 0 ? `{ ${properties} }` : null]
        .filter(Boolean)
        .join(' ')
        .trim();
    }
  
    return '';
  }
  
  const FORMAT_PREDICATES = [
    (node) => node.hasFormat('bold') && 'Bold',
    (node) => node.hasFormat('code') && 'Code',
    (node) => node.hasFormat('italic') && 'Italic',
    (node) => node.hasFormat('strikethrough') && 'Strikethrough',
    (node) => node.hasFormat('subscript') && 'Subscript',
    (node) => node.hasFormat('superscript') && 'Superscript',
    (node) => node.hasFormat('underline') && 'Underline',
  ];
  
  const DETAIL_PREDICATES = [
    (node) => node.isDirectionless() && 'Directionless',
    (node) => node.isUnmergeable() && 'Unmergeable',
  ];
  
  const MODE_PREDICATES = [
    (node) => node.isToken() && 'Token',
    (node) => node.isSegmented() && 'Segmented',
    (node) => node.isInert() && 'Inert',
  ];
  
  function printAllProperties(node) {
    return [
      printFormatProperties(node),
      printDetailProperties(node),
      printModeProperties(node),
    ]
      .filter(Boolean)
      .join(', ');
  }
  
  function printDetailProperties(nodeOrSelection) {
    let str = DETAIL_PREDICATES.map((predicate) => predicate(nodeOrSelection))
      .filter(Boolean)
      .join(', ')
      .toLocaleLowerCase();
  
    if (str !== '') {
      str = 'detail: ' + str;
    }
  
    return str;
  }
  
  function printModeProperties(nodeOrSelection) {
    let str = MODE_PREDICATES.map((predicate) => predicate(nodeOrSelection))
      .filter(Boolean)
      .join(', ')
      .toLocaleLowerCase();
  
    if (str !== '') {
      str = 'mode: ' + str;
    }
  
    return str;
  }
  
  function printFormatProperties(nodeOrSelection) {
    let str = FORMAT_PREDICATES.map((predicate) => predicate(nodeOrSelection))
      .filter(Boolean)
      .join(', ')
      .toLocaleLowerCase();
  
    if (str !== '') {
      str = 'format: ' + str;
    }
  
    return str;
  }
  
  function printSelectedCharsLine({
    indent,
    isSelected,
    node,
    nodeKeyDisplay,
    selection,
    typeDisplay,
  }) {
    // No selection or node is not selected.
    if (
      !$isTextNode(node) ||
      !$isRangeSelection(selection) ||
      !isSelected ||
      $isElementNode(node)
    ) {
      return '';
    }
  
    // No selected characters.
    const anchor = selection.anchor;
    const focus = selection.focus;
  
    if (
      node.getTextContent() === '' ||
      (anchor.getNode() === selection.focus.getNode() &&
        anchor.offset === focus.offset)
    ) {
      return '';
    }
  
    const [start, end] = $getSelectionStartEnd(node, selection);
  
    if (start === end) {
      return '';
    }
  
    const selectionLastIndent =
      indent[indent.length - 1] === SYMBOLS.hasNextSibling
        ? SYMBOLS.ancestorHasNextSibling
        : SYMBOLS.ancestorIsLastChild;
  
    const indentionChars = [
      ...indent.slice(0, indent.length - 1),
      selectionLastIndent,
    ];
    const unselectedChars = Array(start + 1).fill(' ');
    const selectedChars = Array(end - start).fill(SYMBOLS.selectedChar);
    const paddingLength = typeDisplay.length + 3; // 2 for the spaces around + 1 for the double quote.
  
    const nodePrintSpaces = Array(nodeKeyDisplay.length + paddingLength).fill(
      ' ',
    );
  
    return (
      [
        SYMBOLS.selectedLine,
        indentionChars.join(' '),
        [...nodePrintSpaces, ...unselectedChars, ...selectedChars].join(''),
      ].join(' ') + '\n'
    );
  }




  
  function printRangeSelection(selection: RangeSelection): string {
    let res = '';
  
    const formatText = printFormatProperties(selection);
  
    res += `: range ${formatText !== '' ? `{ ${formatText} }` : ''}`;
  
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorOffset = anchor.offset;
    const focusOffset = focus.offset;
  
    res += `\n  ├ anchor { key: ${anchor.key}, offset: ${
      anchorOffset === null ? 'null' : anchorOffset
    }, type: ${anchor.type} }`;
    res += `\n  └ focus { key: ${focus.key}, offset: ${
      focusOffset === null ? 'null' : focusOffset
    }, type: ${focus.type} }`;
  
    return res;
  }
  
  function printObjectSelection(selection: NodeSelection): string {
    return `: node\n  └ [${Array.from(selection._nodes).join(', ')}]`;
  }
  
  function printGridSelection(selection: GridSelection): string {
    return `: grid\n  └ { grid: ${selection.gridKey}, anchorCell: ${selection.anchor.key}, focusCell: ${selection.focus.key} }`;
  }
  
  


  function $getSelectionStartEnd(node, selection): [number, number] {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const textContent = node.getTextContent(true);
    const textLength = textContent.length;
  
    let start = -1;
    let end = -1;
  
    // Only one node is being selected.
    if (anchor.type === 'text' && focus.type === 'text') {
      const anchorNode = anchor.getNode();
      const focusNode = focus.getNode();
  
      if (
        anchorNode === focusNode &&
        node === anchorNode &&
        anchor.offset !== focus.offset
      ) {
        [start, end] =
          anchor.offset < focus.offset
            ? [anchor.offset, focus.offset]
            : [focus.offset, anchor.offset];
      } else if (node === anchorNode) {
        [start, end] = anchorNode.isBefore(focusNode)
          ? [anchor.offset, textLength]
          : [0, anchor.offset];
      } else if (node === focusNode) {
        [start, end] = focusNode.isBefore(anchorNode)
          ? [focus.offset, textLength]
          : [0, focus.offset];
      } else {
        // Node is within selection but not the anchor nor focus.
        [start, end] = [0, textLength];
      }
    }
  
    // Account for non-single width characters.
    const numNonSingleWidthCharBeforeSelection = (
      textContent.slice(0, start).match(NON_SINGLE_WIDTH_CHARS_REGEX) || []
    ).length;
    const numNonSingleWidthCharInSelection = (
      textContent.slice(start, end).match(NON_SINGLE_WIDTH_CHARS_REGEX) || []
    ).length;
  
    return [
      start + numNonSingleWidthCharBeforeSelection,
      end +
        numNonSingleWidthCharBeforeSelection +
        numNonSingleWidthCharInSelection,
    ];
  }

