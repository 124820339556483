import { removeAllComments, removeWSComments, xmap, XMapFns } from "../../bubble-base/ref/model/parseRefDoc"
import { Comment, RefDocPM, SrcRef} from "../../bubble-base/ref/model/RefDoc"
import { RefsPM } from "./useProjectRefs"

export type ProjectRefMu<r> = {
  SetView: () => r
  SetDoc: (doc:RefDocPM|null, loading:boolean) => r
} 
  
export const ProjectRefMu:ProjectRefMu<any> = {

  SetView: () => (mu, {pm}:{pm:RefsPM}) =>  {
    const {showComments, docViews} = pm
    const doc = showComments ? docViews?.annotated : docViews?.unannotated
    mu.pm({doc})

  },

  
  

  SetDoc: (doc:RefDocPM, loading:boolean) => (mu, {pm})=> {
    if (!doc) {
      mu.pm({
        ok:false,
        title:"Loading ... ", 
        doc:null, 
        docView:null,
        loading
        
      })
    } else {
      const {ref, srcIds} = doc
      const {projectId} = pm
      const toLink = (r:SrcRef):string|null => {
        const srcId = srcIds[r.ref]
        return srcId ? `/BubbleNotes/${srcId}/${projectId}/view` : null
      }
      const title = ref.title


    // -- inject presentation data 
    //  a) first remove comments that are whitespace (no need for it in this presentation)

    const doc1 = removeWSComments(ref) 

    //  b) when a comment is above references, it's a preamble, so don't indent it
    //  c) when a comment is below references
    //  d) add the link rurl to references
    const fns:XMapFns<any> = {
      hit: (v:(Comment|SrcRef)) => (v.$$ === "Ref"),
      preHitC: (v:Comment) => ({indent:false}), 
      postHitC: (v:Comment) => ({indent:true}),
      refMap: (r:SrcRef) =>  ({link:toLink(r)})
    }


    const annotated = xmap(doc1, fns) // with indent and link 

    // -- and the unannotated bibliograpy
    const unannotated = removeAllComments(annotated)

      mu.pm({
        ok:true,
        title,  
        docViews:{
          annotated,
          unannotated
        },
        loading,
        doc: pm.showComments ? annotated : unannotated
      })

    }
  }

}

