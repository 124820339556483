import { MediaEnv } from "./MediaEnv";
import { Cache, PageCache } from "./PageCache";
import { resolveLinks } from "./PageLinks";
import { PageModel } from "./PageModel";
import { createResource, PageResource } from "./PageResource";
import { ResourceManifest } from "./ResourceManifest";

const queryString = require('query-string');
export const toPath = (p:Page) => p.rurl


export type PageState<a,pm> = {

    resource:PageResource  // <-- interpretation of rurl
    rurl:string            // <-- duplicates resource, for convenience

                                   //   but type (and environment) specific


    status:ResourceStatus     // <-- loading status, w.r.t manifest 

    data?:a             // <-- the data. maybe this belongs in data
                        //    (assuming a unique representation of the resouce as data)
                        // this isn't a very general pmodel structure   

    query:string
    pm:pm          // <--  presentational model envoded by query string

    // -- TODO - explicitly model relations of dependent page resouces
    // prev:PageState       <-- in effect a list of dependencies that this page need


};


export type Page = PageState<any,any> // convenience type



/**
 *   resources encodes  representaion of the interpretation of the resouce 
 *     in terms of  type, location and paramaterization. But not as a representations of data
 * ie.  project/name/pname/refid  
 *      ie {type:"ref", rurl:"project/name/pname/refid", params:{user:"name", pname:"pname", ref:"refid" }  }
 */
 export type ResourceStatus = {
    leafUrl?:string   // <--  leaf url, for simplicity absent a more complex manifest
    isLoading: boolean; //
    isReady: boolean; //
    errorMsg?: string[]; //
}






/**
 *  Resolve page from url, in the context of a MediaEnv (and cache), instantiating resource if necessary
 *  and returning unknown 
 */
export const resolvePageState = <media>(env:MediaEnv<media>, rurl0:string,  cache0:PageCache):PageModel => {
    var page 

    // -- parse the query string
    const rs = rurl0.split('?')  
    const rurl = rs[0]
    const query:string = rs.length > 1 ? rs[1]  : ""
    const pm = query.length > 0 ? queryString.parse(query) : {}

    
    var cache = cache0

    const prev = Cache.retrieve(rurl, cache)

    if (prev) {
      
      if (prev.query === query) {
        return {page:prev, cache}                // <-- query unchanged
      }

      page = {...prev, query, pm}   
    
    } else {

      const resource = createResource(env, rurl)

      const status:ResourceStatus = newStatus(resource.manifest)
      page = { rurl, query, resource, status, pm}; 
      
      // --- resolve any page links
      ({cache} = resolveLinks(page, env, cache));  

    } 
    var newCache = Cache.update(page.rurl, page, cache)
    if (!newCache) {
      console.log('x')
    }

    return {page, cache:newCache  }
    
}


  


/**
 * Creates an inital state for resource based on it's manifest.
 * 
 *  - isLoading - always starts false. It's up to another process to invoke this
 *  - leafUrl - when there's only a single resource to load
 *  - isReady - starts true only when there's no resources to load
 * 
 */
const newStatus = (manifest:ResourceManifest<any>):ResourceStatus => ({
      leafUrl: ResourceManifest.toSimpleUrl(manifest),
      isLoading:false,
      isReady: manifest.$ === 'None'
    }) 


/*

export function setPageData(state: Model, rurl: string, data: any, links?: { [id: string]:string}): Model {
    const page = getPageStatus(state, rurl)
    const page1 = { ...page, data, status: setReady(page) }
    if (links) {
      page1.links = links
    }
    const newState = setPage(state, page1); 
    return newState
  }
  
   
  
  export function setPage(state: Model, page: PageState<any, any>): Model {
    var resources = { ...state.resources }; // <-- slow for large numbers of resources?
    resources[page.resource.rurl] = page;
    
    // -- update current page if this resource happens to be the current page
    // but this breaks also around pmodel
    // TODO - make page a string
    if (state.page && state.page.resource.rurl == page.resource.rurl) {
      return {...state, resources, page}
    }
    return {...state, resources}
  }
  
  export const pageTorurl = (page:PageState<any,any>):string => {
    return page.resource.rurl
  }
  
  export const addPageLinks = (page0:PageState<any,any>, refs:{[id:string]:string}) => {
    var links = page0.links || {}
    links = {...links, ...refs}
    return {...page0, links}
  }
  
  export const getPageStatus = (state:Model, rurl:string) => {
    var page:PageState<any,any> = state.resources[rurl]
    return page
  }
  
  */
export const isReady = (page?:PageState<any,any>):boolean => (page! && page!.status.isReady)

