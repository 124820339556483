import { P } from "../../anyonic/dsl/dsl-api"
import { FBUser } from "../../model/page/user/FBUser"
import { OFFLINE, ONLINE, presence0, PresencePM, UserPresence } from "./PresencePM"

export type MuPM<pm> = ((v?:P<pm>) => pm)  & DefaultReducer<pm,pm>


export type MuPMFn<pm> =   
      ( (  (v?:P<pm>)                   => pm   ) 
   &    (  (f:(mu:MuPM<pm>, s:pm) => void) => pm) ) 
   &   DefaultReducer<pm,pm>      
 

export type DefaultReducer<pm,r> = {  // <-- use r to either reuturn a value or chain
  Put: (pm:pm) => r
  Set: (o:P<pm>) => r
  Append: (o:P<pm>|any) => r  // TODO - restrict to subset of array types
  Toggle: (o:P<pm>|any) => r  //  but for now, the P<pm>  will at least enable prompt
}

type LeafMu<pm,r> = ((o:P<pm> ) => r) & DefaultReducer<pm, r>

type Mu0<pm, r> = {
  pm: LeafMu<pm,r>
  // _ps: ... etc
}


type MuFn<pm, cmds, r> = (mu:( cmds & Mu0<pm,cmds>), s:Readonly<pm>) => r

export type Mu<pm, cmds> = 
    ((f?:(MuFn<pm, cmds, void>)) => {pm:pm})    // $.mu( (mu, s) => void) ) => pm
     & cmds                                    // $.mu.CmdReducer
     & {
       pm:(p:P<pm>) => {pm:pm}
     } // $ Mu0<.. ,pm>            // $.mu.pm.Cmd() 


 
 export type PresenceMu = {
   SetConnected: (connected:boolean) => any
   SetUsers : (users:{[uid:string]:FBUser}) => any
   SetUserConnection: (uid:string, connected:boolean) => any
   Clear: () => any
 } 
  
 
   
 export const PresenceMu:PresenceMu = { 

  
  // -- mu reducers 
  SetConnected: connected => mu => mu.pm({connected}),
  
  SetUsers : usersById => mu => {
    mu.pm({ usersById})
  },


  SetUserConnection: (uid:string, connected:boolean) => mu => mu.pm((mu, pm:PresencePM) => {
    const status = connected ? ONLINE : OFFLINE
    mu.Append({   
        presenceById:{
          [uid]: status
    }})

    var found = false 
    /*
    FIX_THIS - pressesnece   
    const userPresences = pm.userPresences.map( up => {
        if (!up || !up.user) {
          console.log("prescence issu ?? ")
          console.log(up)
          console.error("presence issue")

        } 
        if (uid === up?.user?.uid) {
          found = true
          if (up.status !== status) { 
            return UserPresence(up.user, status)
          }
        }
        return up
      })

    if (!found) {
      userPresences.push(UserPresence(pm.usersById[uid], status))
    }

    mu({userPresences})
    */
  }),

  Clear: () => mu => mu.pm.Put(presence0)



 } // mu