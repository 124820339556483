import { useEffect } from "react"
import { usePm$ } from "../../../anyonic/dsl/usePm"
import { fbPs } from "../../../firebase/fbPs"
import { useUI$ } from "../../../MediaContextProvider"
import { MuPM } from "../../../ps/firebase-ps/PresenceMu"
import { $UI } from "../../../ui/ps/useAppUIPs"
import { fromEntity } from "../../bubble-base/ref/eff/ProjectServerEff"
import {  RefDoc, RefDocPM } from "../../bubble-base/ref/model/RefDoc"
import { ProjectRefMu } from "./ProjectRefsMu"


export type RefsPM = {
  projectId?:string
  ok:boolean
  loading?:boolean
  failed?:boolean
  showComments:boolean
  title:string
  doc?:RefDoc  // <-- current state 
  docViews?:{
    annotated:RefDoc
    unannotated:RefDoc
  }
}

const RefsPM0:RefsPM = {
  ok:false,
  title:"...",
  showComments:true,
}


export const useProjectRefs = (project:string):{$:$Refs, pm:RefsPM} => {
  
  const $ui = useUI$()

  const {$, pm} = usePm$(() => ({
    pm:RefsPM0,
    mu:ProjectRefMu,
    
    $:RefsEff($ui.$),

  }))

  useEffect(() => {
    $.load(project)
  },[project, $])

  return {$, pm}

} 


export type $Refs = {
  mu:ProjectRefMu<RefsPM> & any,
  pm:MuPM<RefsPM>,
} & RefsEff


type RefsEff = {
  load: (projectId:string) => void
  toggleComments: () => void
}


const RefsEff = ($ui:$UI) => ($:$Refs):RefsEff => ({

  toggleComments:() => {
    $.mu.pm.Toggle({showComments:""})
    $.mu.SetView() // -- update doc for new comment value
  },

  load: async (projectId:string) => { 
      
    const {loading, projectId:oldProjectId} = $.pm()

    if (oldProjectId && oldProjectId !== projectId) {
      throw new Error(" TODO - handle change of project/page")
    }
    $.pm({projectId})

    try {
      if (!loading) {
        $.mu.SetDoc(null, true)
        $ui.setIsLoading(true)

        const doc:RefDocPM = await loadRefs(projectId)
        $ui.setIsLoading(false)

        $.mu.SetDoc(doc, false)

      }
    } catch (e) {
      console.log("loading failed :" + e.message)
      $.pm({loading:false, failed:true})
    }
  }

})





const loadRefs = async function(projectId:string) {
  
  const fb = fbPs()
  
  
  /*
  const fbDoc = await projectRef.get()
  
  if (!fbDoc.exists) {
    return null
    // error - unknown project??
  }*/
  
  //console.log("Document data:", doc.data());
  const docRef = fb.projectCoreRef(projectId)
  const fbDoc =  await docRef.get()

  if (fbDoc.exists) {
    const entity = fbDoc.data()
    const pm = fromEntity(entity)
    return pm
  } else {
    return null 
    // throw new Error("TODO - handle un created project")
  }



}   