import { usePage } from "../../../MediaContextProvider";
import { PageState } from "../../../model/page/resource/PageState";
import { useProjectRefs } from "../refs/useProjectRefs";

export const MyNotes = () => {
  const page:PageState<any,any>/*ProjectParams>*/ = usePage()
  const {project} = page.resource.params
  const {$, pm} = useProjectRefs(project);
  const {ok,doc} = pm
  if (!ok) {
    return (<div>All My Bubble Notes ... loading project </div>)
  }
  return <div>
    loaded
  </div>
   
}