import { fbPs } from "../../../../../firebase/fbPs"
import fb from "../../../../../firebase/firebase"
import { toEntity } from "../../../import/BubbleEntitty"

export const saveBubbleNotesAndTweaks = async (readingId, unsavedTweak, reading, tweaks, force, user, projectId, srcId) => {

  const fbs = fbPs()

  const readingEntity = toEntity(fb, projectId, srcId, user)
  const dataEntity = toEntity(fb, projectId, srcId, user, reading )




  const readingRef = fbs.bubbleNoteReading(readingId)

  let err = null


  return await fb.db.runTransaction((transaction) => {
       
    
    return transaction.get(readingRef)
        .then((doc) => {
          if (doc.exists && !force) {
            return {saveErr:"bubble notes already exist and must be appended, not overwritten"}
          } else {    
            // -- creation
            const id = doc.id
          
            const newReadingRef = fbs.bubbleNoteReading(id)
            const tweakRef = fbs.bubbleNoteTweak(id)
            const dataRef = fbs.bubbleNoteData(id)
            
            if (unsavedTweak) {
              const tweakEntity = toEntity(fb, projectId, srcId, user, tweaks )
              transaction.set(tweakRef, tweakEntity)
            }
            
            transaction.set(newReadingRef, readingEntity)
            transaction.set(dataRef, dataEntity )
          }
        })
      })

  
    }