import { useChannelsEditor } from "./useChannelsEditor"
import {useCurrentUser, usePage} from "../../MediaContextProvider"



export const ChannelsEdit = () => {

  const user = useCurrentUser()
  const page = usePage()

  const {$, pm} = useChannelsEditor(page, user)

  if ($) {
    console.log({pm})
  }
  
  
  return <div> 

 


  </div>
}