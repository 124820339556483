import { EditorConfig, TextNode } from "lexical";

// https://lexical.dev/docs/concepts/nodes#extending-textnode

export class FieldNode extends TextNode {
  
  __className:string
  __color: any
  __field:string

  static getType() {
    return "field";
  }

  static clone(node) {
    return new FieldNode(node.__field, node.__key);
  }
  constructor(field, key?) {
    super(field + ": ", key);
    this.__field = field
    this.__className = "antiform-field";
    this.__color = "#E65634" 
  }

  getField():string  { 
    return this.__field
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.style.color = this.__color;
    return element;
  }

  updateDOM(
    prevNode: FieldNode,
    dom: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    if (prevNode.__color !== this.__color) {
      dom.style.color = this.__color;
    }
    return isUpdated;
  }
}

export function $isFieldNode(node) {
  return node instanceof FieldNode;
} 

export function $createFieldNode(fieldName):FieldNode {
  const node = new FieldNode(fieldName).setMode("token");
  return node
}
