
// data LeafMedia a =  Json a | Text a | Img a



// data ResourceManifest media =
//   None 
//   | Leaf rel.  .media     // <-- extensibility mechanism 
//   | Page rel.  url. 
//   | Folder rel. children:*[]

// functorial in leaf media 


export type LeafMedia<a> = { 
        $:'Json', a:a
    } | {
        $:'Text', a:a
    } | { 
        $:'Img', a:a
    } 

export type ResourceManifest<media> = 
{
    $:'None'
} | {
    $:"Leaf", rel:string, media:media
} | {
    $:'Page', rel:string, rurl:string  // <-- not functorial in media
} | {
    $:'Folder', rel:string,  children:ResourceManifest<media>[] 
} // | {

  //  $:'HFolder'              // <-- Hierarchical folder
  // / idea is that the contents of the folder are dynamicly loaded
  //}



// -- constructors
const Text = <a>(a:a):LeafMedia<a> =>  ({$:'Text', a})
const Json = <a>(a:a):LeafMedia<a> =>  ({$:'Json', a})


const None:ResourceManifest<any> = {$:'None'};

const Page = <media>(rel:string, rurl:string):ResourceManifest<media> => ({$:'Page', rel, rurl })
const Leaf = <media>(rel:string, media:media):ResourceManifest<media> => ({$:'Leaf', rel, media})

const Folder = <media>(rel:string, children:ResourceManifest<media>[]):ResourceManifest<media> => 
    ({ $:'Folder', rel, children })


// -- utilities


/**
 * 
 * When a manifest is just a simple leaf asset, this extracts it. 
 * 
 */
const toSimpleUrl = (m:ResourceManifest<LeafMedia<string>>):string|undefined => {
    return (m.$ === "Leaf" ? m.media.a : undefined)
}

/**
 *  iterate over leafs, providing folder names
 *   - generatable code
 * 
 */
const eachLeaf = (m:ResourceManifest<any>, f:Function, out={}, path:string[] = []) => {

    switch (m.$) {
        case 'Folder': 
            const {rel, children} = m
            const path1 = path.concat()
            path1.push(rel)
            children.forEach(child => eachLeaf(child, f, out, path1))
            break
        default:
            f(m, path)
    }
} // eachLeaf


/** 
 * Extract page links 
 * 
 *  TODO - refactor t 
 * 
 */
const toLinks = (m:ResourceManifest<any>):{[rel:string]:string} => {
    const out:{[rel:string]:string} = {}
    eachLeaf(m, ((leaf:ResourceManifest<any>) => {
        switch (leaf.$) {
            case 'Page': 
                const {rel, rurl} = leaf
                if (out[rel]) {
                    throw new Error(`duplicate page rel: ${rel} \n 1. ${rurl} \n 2. ${out[rel]}`)
                }
                out[rel] = rurl
        }
    }))

    return out
} 

const fmap = <a,b>(m:ResourceManifest<a>, f:(a:a) => b):ResourceManifest<b> => {
    switch (m.$) {
        case "None": 
        case "Page":   
            return m   
        case "Leaf": return Leaf(m.rel, f(m.media))
        case "Folder": return Folder(m.rel, m.children.map(v => fmap(v, f)))
    }
    
}


const toExt = v => (v.substring(v.lastIndexOf('.')+1, v.length))
 
const toLeaf = (rel:string, url:string):ResourceManifest<LeafMedia<string>>  => {
    var leaf 
    const ext = toExt(url)
    if (ext === "json") leaf = Json(url)
    if (ext === "txt") leaf = Text(url)
    
    if (!leaf) {
        throw new Error(`unsuppored media ${url}`)
    }
    return Leaf(rel, leaf)
}

/**
 * utility to build
 * 
 */
const r = (def:{[rel:string]:string}):ResourceManifest<any> =>  {
    var keys = Object.keys(def)
    if (keys.length === 0) return None
    if (keys.length === 1) return toLeaf(keys[0], def[keys[0]])
    return Folder("assets", keys.map(rel => toLeaf(rel, def[rel])))
}




export type FlatManifest = {
    pages:{[rel:string]:string}
    leaves:{[rel:string]:LeafMedia<string>}
}

const flatten = (rm:ResourceManifest<LeafMedia<string>>):FlatManifest => {
    const out:FlatManifest = {
        pages:{},
        leaves:{}
    }
    flatten_low(rm, out, "", 0)
    return out
}

const flatten_low = (manifest:ResourceManifest<LeafMedia<string>>, out:FlatManifest, path:string, depth:number) => {
    switch (manifest.$) {
        case "Leaf":
            out.leaves[`${path ? path + "." : ""}${manifest.rel}`] = manifest.media
            break
        case "Page":
            out.pages[`${path ? path + "." :""}${manifest.rel}`] = manifest.rurl
            break
        case "Folder":
            const newPath = depth > 0 ? (`${path ? path + "." :""}${manifest.rel}`) : "";
            manifest.children.forEach(child => flatten_low(child, out , newPath, depth +1)  )
            break
        //case "None":

    }
}

export const ResourceManifest = {
    Json, Text, fmap,
    None, Page, Leaf, Folder, 
    r, toSimpleUrl, toLinks,
    flatten
 }
 