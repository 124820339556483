import { fbPs } from "../../../../firebase/fbPs"
import fb from "../../../../firebase/firebase"
import { FBUser } from "../../../../model/page/user/FBUser"
import { $UI } from "../../../../ui/ps/useAppUIPs"
import { pretweak } from "../../../notes/model/doc/tweak/BubbleTweak"
import { $ProjectEditor } from "../../useProjectRefDocEditorPs"
import { SrcRef, RefDocPM } from "../model/RefDoc"

export type SourceKeys = {[ref:string]:string} // [MuRef0X] -> document id

export type ProjectServerEff = {

  start: (projectId) => void
  save: (doc:RefDocPM) => void
  load: (projectId:string) => void
  resolveSources:  (toResovle:{[ref:string]:SrcRef} ) => Promise<SourceKeys>

} 


export const  ProjectServerEff =  ($ui:$UI) => ($:$ProjectEditor):ProjectServerEff => {
  const fb = fbPs()

  return {
    
    start: (projectId) => {

      var docRef = fb.projectCoreRef(projectId)
      
      $ui.setIsLoading(true)
      $.pm({loading:true})

      docRef.get().then((doc) => {
        $.pm({loading:false})
        if (doc.exists) {

          const entity = doc.data()
          const data = fromEntity(entity)
          $.dePersist(data)
          $ui.setIsLoading(false)
        } else {
          $.xDoc.doesNotExist(projectId)
        }
      }).catch((e) => {
        console.log("Firebase could not load project", e);

        if (e.code === "unavailable") {
          $.setUnavailable()
        }
      });



    
    }, 

    save: (doc:RefDocPM) => {
      const {user, projectId} = $.pm()
      const entity = toEntity(projectId, user, doc )
      

  
      // Projects/$project/refs/core=>Entity<RefDocPM>
      const docRef =   fb.projectCoreRef(projectId)
       
      //rojectsRef.doc(projectId).collection("p").doc("refs")
        

      $ui.setIsSaving(true)
      docRef.set(entity)
      .then(() => {
        console.log("Document successfully written");
        const txt = $.getTxt()
        // TODO - CORNER_CASE: what if document has change ... need a cancellation mechanism
        const unsaved = (txt !== doc.txt)  // THIS is borken
        $.pm({ unsaved })
        $ui.setIsSaving(false)
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        $ui.setIsSaving(false)
      });
      
    }, 
  
    load: (projectId:string) => {
      const fbs = fbPs()

      $ui.setIsLoading(true)
      const docRef = fbs.projectCoreRef(projectId)        
      docRef.get().then((entity0) => {
        if (!entity0.exists) {
          $.xDoc.doesNotExist(projectId)
        } else {

          const entity = entity0.data()
          const data =  fromEntity(entity)
          $.setDoc(data)
        }
        $ui.setIsLoading(false)
      
      })
    }, 



    resolveSources: (toResolve:{[ref:string]:SrcRef} ) =>  $.run(function*($$) {
   
      const srcKeys = yield $$.await(() =>  resolveSrcDoc($ui, toResolve))
    
      return srcKeys 

     
    })
  }
}

const resolveSrcDoc = async ($ui:$UI, toResolve:{[ref:string]:SrcRef}):Promise<SourceKeys> => {


  const fbs = fbPs()


  const srcRef = fbs.bubbleSrcRef()

  // 1. 1st instance ... just get all src docs
  const docs = await loadAll(srcRef)

  const refToId = (ref:SrcRef):string|undefined => {
    const ids = Object.keys(docs)
    for (var k of ids) {
      if (pretweak(ref.name) === pretweak(docs[k].name)) {
        return k
      }
    }
  } //  

  var srcKeys:SourceKeys = {}

  for (var k of Object.keys(toResolve)) {
    const ref:SrcRef = toResolve[k]

    var srcId = refToId(ref)
    
    if (!srcId) {
      $ui.setIsSaving(true)
      await fb.db.runTransaction( transaction  => {
        const data = { name:pretweak(ref.name) } 
        const docRef =  fbs.bubbleSrcDocRef(ref.ref)
        return transaction.get(docRef)
          .then(doc => {
            if (!doc.exists) {
              transaction.set(docRef, data)
            } else {
              throw new Error(`reference [${ref.ref}] already exist: for now we restrict to a single docment per ref`)
            }
            $ui.setIsSaving(false)
        }).catch(e => {
            // the other possibility is that the ref (ie [])
            console.log("probably either offline,  or there's an invalid key")
            console.error(e)

            throw e
          })
      
      })
   
    }

    srcKeys[k] = srcId

  }

 return srcKeys
    

}

// -- firebase
export const loadAll = async doc =>  doc.get().then(q => {
    var out = {}
    q.forEach(doc => {
      const k = doc.id
      const data = doc.data()
      out[k] = data
    })
    return out
  })
  

type ProjectEntity = {
  owner:string,
  title:string,
  _id:string,
  created:any,
  updated:string,
  data:string // of RedDocPM
}




type Entity<pm,keys extends string[]> = {
  owner:string
  ownerName:string
  keys: any  // TODO  make into {[key:string]:string}

  updated:any
  data:string  // <-- serialization of pm
  
  _ignore?:pm // for lint
  _ignore2?:keys
}


const toEntity = (projectId:string, user:FBUser, pm:RefDocPM):Entity<RefDocPM, ["project"]> => {

  return {
    owner:user.uid,
    ownerName:user.name,
    keys:{
      project:projectId
    },
    updated:fb.TIMESTAMP(),
    data:JSON.stringify(pm)
  }

}



export const fromEntity = (entity:ProjectEntity) => {
  const {data, _id:projectId} = entity
  console.log(`retrieved data for project ${projectId}`)
  const pm:RefDocPM = JSON.parse(data)
  return pm
}
