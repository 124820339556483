
import { fbPs } from "../../../firebase/fbPs"
import { Tags } from "../model/doc/BubblePM"

import { $Bubbles, TagPsCache } from "../model/view/useBubbleNotes"
import { IBubbleTagServer } from "./IBubbleTagServer"



export const BubbleTagServer = ($:$Bubbles):IBubbleTagServer =>  ({


  stop: () => {
    const cached = $.refs().tagServer
    if (cached?.running) {
      cached.cancelFn()
    }
    $.ref.tagServer(null)
  },


/**
 * Manage presence api
 *   
 *  - Query server for current list of users
 *  - Query connection status and update a <presence| table
 *  - Query s
 * 
 */
  start:  (_rurl:string, readingId:string, user:string) => {
    // -- not sure we really want to stop the server. 
    const tagServerURI = `${_rurl}.${readingId}.${user}`


    const cached:TagPsCache = $.refs().tagServer

    const prevURI = cached?.tagServerURI
  
    const rurlChanged = (prevURI !== tagServerURI)
    const serverRunning = cached?.running
    
    var needToStart = false

  
    if (!rurlChanged) {

        if (serverRunning) {
          return // <-- running ad all is fine
        } else {
          needToStart = true    
        } 

    } else {
      // -- stop old server
      if (serverRunning) {
        $.tagServer.stop() // removes cache
      } else {
        needToStart = true
      }
    }
    

    if (needToStart) {
      const _ps = fbPs() // <-- could inject this
      const tagRef = _ps.getTagCollectionRef(readingId, user) 
    
  
      console.log(`starting <BubbleTags|${tagServerURI}`)

     
    
      var _isFirst = true


      // -- TODO - abstract
      const _unsubsubscribe = tagRef.onSnapshot(qs => {
          
        if (_isFirst) {
          
          var tags = {}
          qs.forEach((doc) => {
            var k = doc.id;
            var o = doc.data();
            tags[k]= o
          });
          $.mergeTags(tags)
         
          _isFirst = false

        } else {

          qs.docChanges().forEach((dx) => {
            if (dx.type === "added" || dx.type === "modified") {
              const k = dx.doc.id
              const tags = dx.doc.data()
              $.setTag(k, tags)
            } else if (dx.type === "removed") {
              const k = dx.doc.id
              $.setTag(k,null)
            }
          })
    

        }
    
      })
        
      // -- cache
      $.ref.tagServer({
        tagServerURI,
        running:true,
        ref:tagRef,
        cancelFn: () => {
          if (_unsubsubscribe != null) {
            _unsubsubscribe()
          }
        }
      })

      


    }


  },


  publishTags: (did:string, v:Tags)  => {
    //const {tagServerURI} = $.mu.pm()
    
    // -- validate data 

    var entity = null
    if (!v || Object.keys(v).length === 0) {
      entity = null
    } else {
      entity = {}
      // make a copy 
      Object.keys(v).map(k => (entity[k] = true))
    }

    const cache = $.refs().tagServer


    if (cache?.running) {
      const {ref} = cache

      if (entity) {
        ref.doc(did).set(entity)
          .then(v => {
            console.log('sent tag ' + did)
          }).catch(e => {
            throw new Error('failed')
        }) 
      } else {
        ref.doc(did).delete()
          .then(_ => {
            console.log('deleted  tags')
          })
          .catch(e => {
            console.error(e)
            throw e
          })
      }
    }
  } 




})
