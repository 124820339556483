import { pretweak } from "../../../notes/model/doc/tweak/BubbleTweak"
import { SourceKeys } from "../eff/ProjectServerEff"
import { RefDoc, RefGroup, SrcRef, Comment } from "./RefDoc"

const REF_EXP = /^\s*\[([^\s[]*)\]\s+(.*)$/

export type Result<a> = {ok:boolean, ref?:a}

export const parseRef = (v:string):Result<SrcRef> => {
  
  const match = REF_EXP.exec(v)
  
  if (!match) {  
    return {ok:false}
  }

  const ref = match[1]
  const name0 = match[2]

  const name = pretweak(name0)
  return {ok:true, ref:{$$:"Ref", ref, name}}

}

const GRP:RegExp = /^\s*([#]+)\s*(.*)\s?$/

export const parseGroup = (v:string):{ok:boolean, n?:number, title?:string} => {
  const match = GRP.exec(v)
  if (!match) {
    return {ok:false}
  }
  const n = match[1].length
  const title0 = match[2]
  const title = pretweak(title0)
  return {ok:true, n, title}
}

const EMPTY:RegExp = /^\s*$/

const isEmpty = line => line.match(EMPTY)

export const parseRefDoc = (lines:string[]):{ok:boolean, doc:RefDoc} => {
  var groups = []


  const lines1 = lines.map((txt,i) => ({txt,i}))
  const nonEmptyLines = lines1.filter(v => (!isEmpty(v.txt)))

  if (nonEmptyLines.length === 0) {
    return {ok:true, doc:{title:"...", groups}}
  }

  const {i:i0, txt:projectTitle}  = nonEmptyLines[0]


  const lines2 = lines1.filter((l,j) => (j >= i0))
  const titleLines = lines2.filter(({txt, i}) => ((i0 === i) || parseGroup(txt).ok ))
  var level = -1
  
  for (var j = 0; j < titleLines.length; j++) {
    const isFirst = j === 0;
    const isLast = j === titleLines.length - 1;
    const {txt} = titleLines[j];
    var name
    if (isFirst) {
      name = ""
    } else  {
      const { n, title} = parseGroup(txt)
      level = n
      name = title
    } 
    const startIndex = titleLines[j].i + 1;
    const endIndex = isLast ? lines2.length -1 : (titleLines[j+1].i -1)
    var refs = []

    for (var k = startIndex; k <= endIndex; k++  ) {
      const line = lines1[k].txt
      const {ok, ref} = parseRef(line)
      if (ok) {
        refs.push(ref)
      } else {
        refs.push({$$:"Comment", comment:line})
      }
    }
    var group:RefGroup = {name, refs}
    if (level > 1) {
      group.level = level
    }
    groups.push(group)
  }


  return {ok:true, doc:{title:projectTitle, groups}}

}


export const extractRefs = (doc:RefDoc):{ok:boolean, errs?:{[ref:string]:string}, byRef:{[ref:string]:SrcRef}} => {
  var byRef = {}
  var errs = {}

  const {groups} = doc
  for (var g of groups) {
    for (var item of g.refs) {
      if (item.$$ === "Ref") {
        const k = item.ref
        if (byRef[k]) {
          errs[k] = errs[k] || []
          errs[k].push(item)
        } else {
          byRef[k] = item
        }
      }
    }
  }

  if (Object.keys(errs).length > 0) {
    return {ok:false, errs, byRef}
  } else {
    return {ok:true, byRef}
  }


}


export const isResolved = (byRef:{[refId:string]:SrcRef}, srcIds:SourceKeys):{resolved:boolean, srcIds:SourceKeys}  => {
  var rids = Object.keys(byRef)
  const missing = rids.filter(k => (!srcIds[k]))
  const sids = Object.keys(srcIds) 
  const extraneaous = sids.filter(k => (!byRef[k]))
 
  var srcIds1 = srcIds
  if (extraneaous.length >0) {
    srcIds1 = {...srcIds}
    extraneaous.forEach(k => (delete srcIds1[k]))
  }

  return {
    resolved:(missing.length === 0),
    srcIds:srcIds1 
  }

}

// pretenting that this is functorial in Comment and Ref
export const fmap = (doc:RefDoc, fr?:((r:SrcRef) => SrcRef), fc?:((r:Comment) => Comment)):RefDoc => {  
  const groups = doc.groups.map((g:RefGroup)  => {
    const refs = g.refs.map(v => 
        ((v.$$ === "Comment")
             ? (fc ? fc(v) : v)
             : (fr ? fr(v) : v)))
    return {...g, refs} 
  })
  return {...doc, groups}
}

export const filter = (doc:RefDoc, fr?:((r:SrcRef) => boolean), fc?:((r:Comment) => boolean)):RefDoc => {
  const groups = doc.groups.map((g:RefGroup)  => {
    const refs = g.refs.filter(v => 
      ((v.$$ === "Comment") 
       ? (fc ? fc(v) : true)
       : (fr ? fr(v) : true)))
    return {...g, refs} 
  })
  return {...doc, groups}
}

// -- filter to remove whitespace comments

export const removeAllComments = (doc:RefDoc):RefDoc => filter(doc, null, _ => false)
export const removeWSComments = (doc:RefDoc):RefDoc => filter(doc, null, nonEmptyComment)


const WS:RegExp = /^\s*$/
const nonEmptyComment = (r:Comment) => {
  const filter  = !r.comment.match(WS)
  return filter
}




// -- xmap, and irregualar recursion scheme  (probably a zyloprotomutumorphism or some such)


export type XMapFns<pm> = {
  hit: (v:(Comment|SrcRef)) => boolean
  preHitC: (v:Comment) => pm
  postHitC: (v:Comment) => pm
  refMap: (r:SrcRef) => pm
}

const reduceGroup = <pm>(vs:(Comment|SrcRef)[], fns:XMapFns<pm>) => vs.reduce(
  ({out, isHit}, v) => {
      var pm:pm;
      if (v.$$ === "Ref") {
        pm = fns.refMap(v) 
      } else {
        pm = isHit ? fns.postHitC(v) : fns.preHitC(v)
      }
    out.push({...v, pm})
    return {out, isHit: isHit || fns.hit(v)}
  }
  ,{out:[] as (Comment|SrcRef)[], isHit:false}
)

export const xmap = <pm>(doc:RefDoc, fns:XMapFns<pm>):RefDoc => {
  return {
    ...doc,
    groups: doc.groups.map(g => {
      const refs = reduceGroup(g.refs, fns).out
      return {...g, refs}
    })
  }
}