
import { keys } from "../../anyonic/dsl/dsl-api"
import { usePresence } from "../../MediaContextProvider"
import { FBUser } from "../../model/page/user/FBUser"
import { UserPresence } from "../../ps/firebase-ps/PresencePM"
import { Avatars } from "./Avatars"

export const Presence = () => {
    
  const pm  =  usePresence()    
  const {user, usersById, connected} = pm


  return (<div>
    <h1>who's online</h1>
    <div>Me: {user!.name}</div>
    <div>Current status: {connected ? "online" : "offline"}</div>
    
    <div>
      <>
    
      all users:
      { keys(usersById,( _, user)  => {
        console.log(user.name)
        return renderUser(user)
      })}
        </>
    </div>
    
    <div>
        all online users:
        {renderPresences(pm)}
    </div>
<div>
  <Avatars/>
</div>

  </div>)

}


const renderPresences = pm => pm.userPresences.map( (up:UserPresence) => {
  return (
    <div key={up.user.uid}>
      Room member: {up.user.name} :  {up.status}
    </div>
  )
})



const renderUser = (user:FBUser) => (
<div key={user.uid}>
  <div> name: {user.name}   </div>
</div>)

    


