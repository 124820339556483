import * as React from 'react';
//import CssBaseline from '@mui/material/CssBaseline';
import { Fab } from '@mui/material';
import { SxProps } from '@mui/system';

// -- icons
//import AddIcon from '@mui/icons-material/Add';
//import EditIcon from '@mui/icons-material/Add';
import TagIcon from '@mui/icons-material/Tag';

import { AppToolBarLea } from './AppToolBarLea';
//import { useAppStylesLea } from './useAppStylesLea';
import { useUI$ } from '../../../MediaContextProvider';
import { styled, keyframes } from '@mui/system';
import { RED } from '../../theme/theme-lea';
import { MyTabs, TABS, useRouteMatch } from '../../../AppTabs';


/*
export const LayoutLea = ({children}) => {

  /.const cs = useAppStylesLea();
  //const $:MediaPsPM = React.useContext(MediaContext)
  const pm = useUI()
  
  
  return (
    <div className={cs.root}>
      <CssBaseline />

      <AppToolBarLea  cs={cs}  />

      <main className={cs.content}>
        <div className={cs.toolbar} />
        <Box className="route-container" width={ pm.drawerOpen ? 0.48 : 1}>  
          {children}
        </Box>
{/*
        <BubbleDrawer> 
          <h2>bibliography goes here</h2>
          {/* <Bibliography/> }
        {/*</BubbleDrawer> }

      </main>
    </div>
  );
}



*/




const fabStyle = {
  position: 'absolute',
  top: 5,
  right: 5,
  color: "white", //'common.white',
  bgcolor: "black", //green[500],
  '&:hover': {
    bgcolor: RED //[600],
  },
};

const fabStyleSel = {
  position: 'absolute',
  top: 5,
  right: 5,
  color: RED, //'common.white',
  bgcolor: "black", //green[500],
  '&:hover': {
    bgcolor: "white" //[600],
  },
};

const fabSel =  {
  sx: fabStyleSel as SxProps,
  icon: <TagIcon/>,
  label: 'Add',
}

const fabNoSel = {
  sx: fabStyle as SxProps,
  icon: <TagIcon/>,
  label: 'Edit',
}
 

export const Root = styled('div')({
      top: '0px',
      minHeight: '100vh',
      backgroundImage: `url(${process.env.PUBLIC_URL}/img/bg.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center top'
    })
    



    const animateDown = keyframes`
      0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        visibility: visible;
        opacity: 1;
      }
      100% {
        -webkit-transform: translateY(100px;
        transform: translateY(100px);
        visibility: visible;
        opacity: 1;
      }
    `

    const animateUp = keyframes`
      0% {

        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        visibility: visible;
        opacity: 1;
      }
      100% {


        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        visibility: visible;
        opacity: 1;
      }
    `;
 //   margin-top: 0px;
//        margin-top:100px;
 //   const animateUp1 = keyframes`
 //   0% {}
 //   100%   { margin-top: -100px; }`
   
    
    const DownBox = styled('div')(() => ({
      animation:`${animateDown} 0.3s ease)`
    }))
    
    const UpBox = styled('div')(()=> ({
      animation:`${animateUp}  0.3s ease`
    }))
    
    

export const LayoutLea = ({children}) => {
  const {$, pm} = useUI$()

  const fab = pm.tagFilter ? fabSel  :fabNoSel
  
  const routeMatch = useRouteMatch(TABS);
  const currentTab = routeMatch?.pattern?.path;

  
  
  return (


    <Root>
      {pm.showTagfilter  && 
        <Fab onClick={_ => {
          $.tagFilter(!pm.tagFilter)
        }} 
          size="small" sx={fab.sx} aria-label={"filter tags"} >
          {fab.icon}
        </Fab>}
        <AppToolBarLea/>

          {MyTabs(currentTab)}
          {currentTab
            ? 
              <UpBox>
                {children}
              </UpBox>
            : <DownBox>
                {children}
            </DownBox>}
    
    
    </Root>)

      }


