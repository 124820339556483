import { $Bubbles } from "../useBubbleNotes"

export type BubbleFilterEff = {
  chapter: (id:string) => void
  // tag
  section: () => void
}
 
export const BubbleFilterEff = ($:$Bubbles):BubbleFilterEff => ({
  
  chapter: (ch:string) => {
    const {$pg} = $._ps()
    var v = ch === "*" ? undefined : ch
    $pg.$.nav.setQuery(v ? {ch:v} : undefined)
    $.mu.SetChapterFilter(v)
  },


  section: () => {
    const {fpm:{section}} = $.pm()
    // -- handle query here   
    $.mu.SetSectionFilter(!section)
  }




})

// -- beginnings of media api

export type ChapterDef = {id:string, label:string}
export type ChaptersPM = ChapterDef[]

