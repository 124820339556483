import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

import { TreeViewPlugin } from "./plugins/tree/TreeViewPlugin";
import MyCustomAutoFocusPlugin from "./plugins/MyCustomAutoFocusPlugin";

import editorConfig from "./editorConfig";
//import onChange from "./onChange";
//import { FieldPlugin } from "./plugins/form/AntiformPlugin";
//import RefPlugin from "./plugins/form/RefPlugin";
//import CatPlugin from "./plugins/form/CatPlugin";
import { OnStartPlugin } from "./OnStartPlugin";

// <EmoticonPlugin />
// <KeywordsPlugin/> 
// <FieldPlugin />
// <RefPlugin />
// <CatPlugin />

export const Editor = ({onChange, onStart}:{onChange:any, onStart?:any}) => {
  return (
    <LexicalComposer   initialConfig={editorConfig}>
      <div className="editor-container">
        
        <PlainTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
        /> 
        <OnChangePlugin onChange={onChange} 
          ignoreSelectionChange={true}
          ignoreInitialChange={false}/>

        <OnStartPlugin onStart={onStart}/>
        <HistoryPlugin />
        <TreeViewPlugin />
       
        <MyCustomAutoFocusPlugin />
      </div>
    </LexicalComposer>
  );
}

function Placeholder() {
  return <div className="editor-placeholder">Enter some plain text...</div>;
}
