

export type ChannelsMu<r> = {
  Tmp: () => r
}

export const ChannelsMu:ChannelsMu<any> = {
 Tmp: () => {
  
 }
}