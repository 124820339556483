
import {  registerForm0, RegisterFormPM  } from "../../view/login/Register";
import firebase from "../../firebase/firebase"
import { usePm$ } from "../../anyonic/dsl/usePm";
import { FBUser } from "../../model/page/user/FBUser";
import { toFBUser } from "../auth/useCurrentUserPs";
import { validateMu, change } from "../form/form-ps";


const DEFAULT_AVATAR = "public/avatars/a1.jpg"
/**
 * 
 * OPEN_ISSUES
 *  1. for algebra submit & change duplicate code 
 *  2. hardcoding of pm instead of form 
 *  3.
 * 
 */

export const useRegisterPs = ():{pm:RegisterFormPM, $:any} => usePm$(() => ({
  pm:registerForm0,
  $: { 
    register,  // <-- internal logic of registration 
    submit,   // 
    change,    //  
    setUser
    }} 
))


export const register = $ => (email, password, username) => $.ps((Ok, Err) => {
  firebase.auth
    .createUserWithEmailAndPassword(email, password)
    .then(createdUser => {
        console.log(createdUser);
        createdUser.user!
        .updateProfile({
          displayName: username,
          photoURL:  DEFAULT_AVATAR                
        })
        .then(() => {
          // note that this this is likely to have updated the logged in user. 
          const user:FBUser = toFBUser(createdUser.user) 
          Ok(user)
        }).then(() => {
          console.log('x')
        })
    .catch(Err) 

  })
  .catch(Err)
})


export const updateAvatar = $ => (user:FBUser, avatar:string) => $.ps( (Ok, Err) => {
  
  firebase.auth.currentUser.updateProfile({
      photoURL:avatar
  })
  .then(_ => {
    firebase.database.ref("users").child(user.uid).update({avatar}) 
    .then(_ => Ok({...user, avatar}))
  })
  .catch(Err) 
})


export const setUser = $ => (user:FBUser) => {
  return firebase.database.ref("users").child(user.uid)
    .set({name:user.name, avatar:user.avatar, uid:user.uid})
    .catch(e => {
      console.log("error")
    })
    .then(() => {
      console.log(`user ${user.name} registered`)
    })
}


// -- TODO - duplication of code 

const submit =  $ => e => $.run(function*($$) { 
  e.preventDefault();

  const state = $.mu(mu => mu.pm(validateMu)) // TODO - chance to $.mu.form(validateMu)
  const {ok:validates, validatedValues:v} = state.pm  // FIX THIS - validate isn't really an effect
  
  if (validates) {
      
    $.mu.pm({isSubmitting:true})
    
    const {ok, v:user, err} = yield $$.await(() => $.register(v.email, v.password, v.userName))
    
    if (!ok) {
      $.mu.pm({err:err.message, isSubmitting: false})
      return
    } 
    
    yield $$.await( () => $.setUser(user)) // 






    // higher level process will listen for login success ... nothihg
    console.log('user')
    // -- set user data here ... 
 
    
    //yield $$.Set({isSubmitting:false})   // <-- not going to rest 
      
  }
})