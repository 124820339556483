import { ActionHandler, HandlerSpec, keys } from "./dsl-api"
import { batchReduce, batchQueue, batchCmd } from "./dsl-batch"


/**
 * This object returns a function that implements 
 * 
 */
const createMu = (nss, ctors, muActions, leafMu, dbg) => {
  
  return (queue, state0) => {

    var mu = {}  // <-- which we'll instrument 
    nss.forEach(ns => {
     
      // $$.pm( )
      mu[ns] = o => {  // <-- TODO - use a prototype or some context mechanism
        if (o instanceof Function) {
          o(mu[ns],state0[ns])
        } else if (o instanceof Object) {
          if (dbg) dbg.change(ns, o)
          queue.push({ns, o});
        }
        return mu // chain 
      }
      
      //mu => mu.Cmd(args)    -- top level commands defined by mu-reducers
      // defined view {
      //    Cmd: (a, b, c) => (mu, s) => ...  
      // }
      //
   

      keys(muActions[ns] , (name, fn) => {  // mu => mu.pm.MyLeafMuReducer 
  
        mu[ns][name] = (...args) => {    
          const muR = fn.apply(null, args) // (...args) => (mu, s) => ...
          mu[ns](muR)  //  
          return mu
        }
      })

      keys((ctors[ns] || {}), (name, con) => { 
        const isFn = (con instanceof Function) 
        if (isFn) {
          mu[ns][name] = (...args) => {
            const cmd = isFn ? con.apply(null, args) :con   // <-- ie. $.mu.pm.Append(...)
            queue.push({ns, cmd})  // <-- case of leaf cmd
            return mu  // <-- chain
          }

        } else {
          //TODO - handle constant constructor
        }
        
      })
      
    })

    keys(leafMu, (name, fn) => {
      mu[name] = (...args) => {    
        const muR = fn.apply(null, args); // 
        muR(mu, state0)
        return mu
      }
    })

    return mu
  }
}


/**
 * $.mu implments an api that 
 *   a) wrapper around the $$.mu that transforms mu-reducers into (batched) commands
 *   b) executes them 
 * 
 *
 * 
 */
export const $mu = (spec:HandlerSpec) => {
  
  const {$$, refs, stateRef} = spec

  const getState = () => stateRef.current 

  var  _mu = function (o) {  
    if (o) {
      const cmd = $$.mu(o)  // $.mu( (mu,s) => ... )
      return batchReduce(cmd, spec)
    } else {
      return getState()
    }
  } 

  keys(refs, k => {  // <-- iterate over models {[key]:{current:data}  }
    //const f = $$[k]  // <-- $.mu.myPm( ... ) 
    _mu[k] = o => {
      if (o === undefined) {                 //   i.  $.mu.pm()  -- returns value
        return getState()[k]
      } else {  //  ii.  $.mu.pm( (mu,s) => ... ) -- mu reduerce
        return _mu(mu => mu[k](o))[k] // $.mu.pm(o) for $.mu(mu => mu.pm(o))
      } 
    }

    // leaf cmds, ie  $.mu.pm.Put( ... arg)
    const handlers = spec.byNs[k].handlers
    keys(handlers, (cmdName, handler:ActionHandler ) => {
      _mu[k][cmdName] = (...args) => {  // $.mu.ns.Cmd
        const cmd = handler.cons.apply(null, args)
        // apply cmd 
        const out = batchReduce(batchCmd(cmd, k), spec)
        return out
      }
    })
    
    keys(spec.muReducers, (name:string, f) => {  // $.mu.TopLevelMuReducer
      if (isMu(name)) {
        _mu[name] = (...args) => {
          const o = f.apply(null, args)
          return _mu(o) //mu => mu[name].apply(null, args))
        }
      }
    })

    //-- $.pm.MuReducer   <-- from leaf Mu reducers
    keys(spec.muActions[k], (name, muR) => {
      _mu[k][name] = (...args) => {
          const fn = muR(...args)  // (..args) => (mu, s) => ...
          return _mu(mu => {  
            mu[k](fn)   // $.mu.pm(leafMuReducer)
          })[k]            // $.mu(mu => mu.pm(leafMuReducer) )
        }
      }
    )

  })

  

  return _mu
}

const isMu = v => (v.charAt(0) !== '$')  // ignore $:{...} and $$:{...} etc

/**
 *
 * Instrument $$ to translate mu-reducesr into commands
 * 
 *  
 * 
 *  
 * 
 */
export const $$mu = (refs, ctors, muReducers, muActions, stateRef, dbg?) => {
  // -- curry some state for efficiency 
  
  const nss = Object.keys(refs)  // namespaces of models

  const getState = () => {
    return stateRef.current 
  }  // toGetState(ks, refs)  // <-- TODO - inject get state
  
  const check = (ns, o) => {
    if (nss.indexOf(ns) < 0) throw new Error(`unknown state ${ns}`) 
    if (!(o instanceof Object)) {
      throw new Error(`invalid change object on ${ns}`)
    }
  }

  const _dbg = {
    change:   (ns, o) => {
      check(ns, o)
    }
    // TODO - add debug callback here
  }

  // $$.mu( muReducer ) -- top level reducer
  const _mu = create$Mu(getState, nss, ctors, muActions, muReducers, _dbg, dbg)
  
  return _mu
}

const create$Mu = (getState, nss, ctors, muActions, muReducers ,_dbg, dbg) => {

  const createMutator = createMu(nss,ctors, muActions, muReducers ,_dbg ) 

  // -- basic $.mu( (mu, state) => ... )          -- mu reducer 
  //          $.mu( {p: {changed props} , etc} )  -- basic mutation
  //          $.mu()                              -- returns unmodified state
  // 

  const _mu = function (o?) {

    var state = getState()
    var queue
    if (o) {
      queue = []
      if (o instanceof Function) {
          // -- mu( mu, state) =>  ... )
        const mu = createMutator(queue, state);  // -- possibility for 
        /*out = */(o as Function)(mu, state);
        // if this is a lens, we can reuse out 
      } else {
        // -- mu({v:"simple mutator"}) 
        keys(o, (ns, o) => {
          if (_dbg != null) dbg(ns,o)
          queue.push({ns, o}) 
        })
      }
      return batchQueue(queue)  // <-- the final batch command 

    } 
    // -- no op   yield $.state()  (TO OPTIMIZE)
    return batchQueue([])   
  }

  return _mu
}

// -- combinators


/**
 *  mu-reducer combinator 
 * 
 *  ie. $.mu( mus({   
 *      pm: mutatorX,
 *      psm: mutatorY, 
 *  })) 
 *
 */
export const mus = o => mu => keys(o, (ns, f) => mu[ns](f))
