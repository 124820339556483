import { EditorState, LexicalEditor } from "lexical"
import { extractForm } from "../form/extractForm"
import { generateContent } from "./TreeContent"
import { $Tree, TreeTimeTravelEff } from "./useTreePs"


type Cancel = () => void 

export type TreeEff0 = {

  // -- mutations
  //pm:$MuLeaf<TreePM,{}>

  setContent: (v: string) => void
  //setCurrentEditorStates: (state: EditorState) => void
  pushTimeStampedEditorStates: (state: EditorState) => void
  toggleIsPlaying: () => void
  setTimeTravelEnabled: (timeTravelEnabled: boolean) => void

  // -- other effects

  timetravel: TreeTimeTravelEff
  tree:TreeEff

} //



export type TreeEff = {
  watchContent: (editor:LexicalEditor) => Cancel
  wft: (editor:LexicalEditor) => Cancel
}


export const TreeEff0 = ($:$Tree):TreeEff0 => ({

  //pm: $.mu.pm,

  // -- simple mutators
  setContent: content => $.mu.pm({ content }),

  pushTimeStampedEditorStates: editorState => {
    $.mu.pm.Append({ timeStampedEditorStates: [Date.now, editorState] })
  },

  toggleIsPlaying: () => $.mu.pm.Toggle({isPlaying:true}),

  setTimeTravelEnabled: timeTravelEnabled => $.mu.pm({ timeTravelEnabled }),

  tree:TreeEff($),

  timetravel: TreeTimeTravelEff($)


})



export const TreeEff = ($:$Tree):TreeEff => ({

  watchContent: (editor:LexicalEditor ) => {

    $.ref.editor(editor) 

    const {timeTravelEnabled} = $.mu.pm()

    const state:EditorState = editor.getEditorState()
    $.setContent(generateContent(state));

    return editor.registerUpdateListener(({editorState}) => {
      
      const compositionKey = editor._compositionKey;
      
      const state = editor.getEditorState()
      const treeText = generateContent(state);
      
      const form = extractForm(state)

      const compositionText = compositionKey !== null && `Composition key: ${compositionKey}`;

      $.setContent([treeText, compositionText].filter(Boolean).join('\n\n'));
      $.mu.pm({form})

      if (!timeTravelEnabled) {
        $.pushTimeStampedEditorStates(editorState) 
      } 

    })
  },

  wft: (editor:LexicalEditor) => {
    // -- what exactly does this do??
  
    const element = $.refs().treeElement;
  
    if (element !== null) {
      // @ts-ignore Internal field
      element.__lexicalEditor = editor;
  
      return () => {
        // @ts-ignore Internal field
        element.__lexicalEditor = null;
      }
    }
  }, 

  
})