import * as React from "react";
import { usePage } from "../../MediaContextProvider";

const ResourceStatus: React.FunctionComponent<{rurl?:string }> = ({rurl}) => {
  
  const page = usePage()
  
  rurl = rurl || page.rurl

  let { isLoading, isReady, errorMsg } = page.status;
  
  return (
    <div>
      <h1>Project</h1>
      <p> url: {rurl}</p>
      <h2 style={{textAlign:"center"}}>{isLoading ? "Loading" : (errorMsg ? "Error" : "")} </h2>
      <h2 style={{textAlign:"center"}}>{isReady ? " Ready ... ": " not even loading..." }   </h2>
      {errorMsg ?
        <div>
          <p>loadError: {errorMsg ? errorMsg.join("\n") : ""}</p>
          <p>url: {page.status.leafUrl}</p>
        </div>
      :
        ""}
    </div>
  );
};

export default ResourceStatus;
