import { REF, SEC, SEC2 } from "../../import/colMap"


export type Pgs = string|string[]
export type GroupedList<a> = (a | a[])[]


// BubbleNotes/:BubbleSrc
export type BubbleSrc = {
  title:string
  ref:string   // ie [Cutts07] 
  // owner
  authors?:string[]
  bib?:any  // more technical bibliographical data can go here
}

// 
export type BubbleReading = {
  notes:Notes
  pages:Pages
}


export type PgIds = GroupedList<string>
export type Pages = {[pg:number]:PgIds}
export type Notes = {[did:string]:BubbleNote}




// --  type that can be removed, (and should not impact on the hashing)
export const DEFAULT_TYPE = "Excerpt"
export const DEFAULT_COL = "FFFFFF"

export const isSectionTitle = (note:BubbleNote) => 
  (note.pm?.type === "sec" || (note.col === SEC2) || (note.col === SEC))


export const isReference = (note:BubbleNote):boolean => 
    (note.pm?.type === "ref" || (note.col === REF))



export type BubbleNoteType = 'sec' | 'ref' | 'excerpt'

export type BubbleNote = {
  pg:number
  text:string
  type?:string  
  col?:string
  pm?: BubbleNoteInternalPM  

} 

export type BubbleNoteInternalPM = {
  type?:BubbleNoteType
  //isSec?:boolean  // is Section
  //isRef?:boolean  // is Reference
  indent?:number
}

// -- pmodel 

