import {  Reducer } from "./dsl-api"


var count = 0 
var _globalCache:{[id:symbol]:any} = {} // <-- All global cacheing stored here 

// -- for testing
export const resetEntityCache = () => _globalCache = {}

export const EntityCache = (id:symbol) => { 

  var cache:any = _globalCache[id] = _globalCache[id] || {}

  return Reducer(
  {
    Insert: (uri, v) => ({$:'Insert', uri, v}) // <-- this could be generated
  }, {
    Insert: (s, {uri,v}) => {
      if (cache[uri] === v && s._cache === cache) {
        return s
      }
      Object.freeze(v) 
      cache[uri] = v
      return {cache, count:count++} //  <--  TODO   
    }
  }, {
    Get: uri => s => s._cache[uri]
  }, {cache, count})
}

/**
 * 
 * Administrative interface to caches
 * 
 */
export const CacheAdmin = () => Reducer({
    Reset: () => ({$:"Reset"}), // easily derived/ inferred
  },{
    Reset: _ => {
      Object.getOwnPropertySymbols(_globalCache).forEach(k => {
        _globalCache[k] = {}
      })
      return {cache:_globalCache, count:count++}
    },
  }, {
    // -- note exposed as mu reducers
    SelectAll: _ => {
      return ({..._globalCache})
    },

    CacheIds: _ => Object.keys(_globalCache)
  }, _globalCache)





