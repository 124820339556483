


/**
* Partitioning algorithm
* 
* This is for managing the situation when we have a list of data, and two different, 
* and possibly overlapping partions - the cannonical example being pages and chapters
* (where, in general, pages are not merely a sub-section of chapters)
* 
*  so gives two parititionings on the same list of data
*   chapters:  ch1: [a b       c ]  ch2: [d e f ] 
*   pages:     pg1: [a b] pg2: [c        d e f ] 
* 
*  This algorithm implements the later partitioning within the former:
*   
*   ~ [ [a b] ,[c] ] ,  [[d,e,f]] 
* 
*  But additionally annotating the page label:
* 
*   ie. [ {pg:1, content:[a b]}, {pg:2 ... }] etc
* 
*  For convenience when fmapping into a more specific structure)
* 
*   
*/
export const partition = <a,p>(pgs:(a[])[], pgNos:{[n:number]:p}, chs:(a[])[] ):(Part<a,p>)[][] =>  { 
    
  type ChPgAccState = {
    pgIndex:number  // <-- index of the page 
    pgNo:p
    pgLen:number 
    pgUnconsumed:number
  }

  type ChPgAcc<a,p> = {
    state:ChPgAccState
    all:Part<a,p>[][]
  }

  const createAccState = (n:number):ChPgAccState =>  ({
    pgIndex:n,
    pgNo: pgNos[n],
    pgLen: pgs[n].length,
    pgUnconsumed:pgs[n].length
  })

  const nextPg =(acc:ChPgAcc<a,p>, all:Part<a,p>[][]):ChPgAcc<a,p>  => ({
    state: (pgs.length > acc.state.pgIndex + 1) ? createAccState(acc.state.pgIndex +1) : null as any,
    all  
  })
  

  const acc0:ChPgAcc<a,p> = {
    state: createAccState(0),
    all:[]
  }

  const out = chs.reduce( (acc0, ch:a[]) => {
    
    const chLen = ch.length
    var acc = acc0
    var {all} = acc
    var chapterContent:Part<a,p>[] = []

    var toConsume = chLen  //  <-- number of chapter elements yet to be partitioned by page
    
    while (toConsume !== 0) {
      const {pgUnconsumed ,pgNo} = acc.state
      
      if (pgUnconsumed === toConsume){ 
        //  pg  [ ...  a b ] 
        //  ch   ...   a b ] <-- consume page a b and iterate to next page and next chapter
        const content:a[] = ch.splice(-toConsume);
        const part:Part<a,p> = {label:pgNo, content}
        chapterContent =[...chapterContent, part ]
        all = [...all, chapterContent]
        return  nextPg(acc, all)

      } else if (pgUnconsumed < toConsume) {
        //    pg   [.. b c ] pg [c  ...]
        //    ch   [ . d b       c]      <-- take b and c from page and iterate to next page
        const content = ch.splice(0,pgUnconsumed)
        toConsume = toConsume - pgUnconsumed
        ch = ch.splice(-toConsume)
        chapterContent = [...chapterContent, {label:pgNo, content}]
        acc = nextPg(acc, all)  // <-- keep iterting on page 

      } else if (pgUnconsumed > toConsume) {
        //    pg  [ ... c f e f ... ]
        //    ch  [ ... c d] ch [e f ...]   <-- take c and d and iterate to next chapter
        chapterContent = [...chapterContent, {label:pgNo, content:ch}]
        all = [...all, chapterContent]
        acc.state.pgUnconsumed = pgUnconsumed - toConsume
        return {...acc, all}
      } 
    }

    return acc0
  }, acc0)
  
  return out.all

} 
// -- partioned cont
export type Part<a,p> = {label:p, content:a[]}