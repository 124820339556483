import { useMemo, useState } from "react"
import { toPlayerUrl } from "./vimeo-model"
import Player from '@vimeo/player';
import { Mu } from "../../../anyonic/dsl/dsl-api";
import { dsl } from "../../../anyonic/dsl/dsl";
import { useStart } from "../../../anyonic/dsl/usePm";


export type VimeoPlayerPM = {  // <-- only what the view needs to know to render. 
    imageLoaded: boolean,
    playerOrigin: string,
    showingVideo: boolean,
    thumb: any
    playerURL?:string  // <-- this invokes rendering of the iframe 
}
 
// -- internal state of vimeoProcess.
//  -  Does not trigger view updates, so it is not managed by a react state hook
export type VimeoPsM = {
    videoId:string
    player:any   // <-- vimeo player  
    options:any
    release?:() => void   // <--  
}


export type VimeoPs =  {   // <-- api exposed by $
    registeriframe: (iframe:any) => void;
    playAt: (t:number) => void
}



const pm0:VimeoPlayerPM = {
    imageLoaded: false,
    playerOrigin: '*',
    showingVideo: false,  //this.props.autoplay,
    playerURL:undefined,
    thumb: null
}

const psm0:VimeoPsM = {
    videoId:'',
    player:null,
    options:{ byline:false}
}


export const useVimeoPs = (videoId:string, options:any):{pm:VimeoPlayerPM, $:VimeoPs} => {
    
    const [pm, update] = useState(pm0)   // <-- presentation model. 

    const $:any = useMemo(() => dsl({
            pm:Mu(pm0),        // Set Get, Put, Append       <-- pmodel
            psm:Mu(psm0), // SetVimeoPsM,   VimeoPsM <-- psModel - not exposed to view
        }, { 
            $:{
                start,
                registeriframe,  // <-- exposed to view component 
                playAt
            }
        }, update
        ), []) // 

    useStart($, [videoId, options])

 
    return {pm, $}
} 


const start = $ => (videoId:string, options) => $.mu((mu, state) => {

    const {pm, psm}:{pm:VimeoPlayerPM, psm:VimeoPsM} = state

    // var player = psm.player

    if (psm.videoId !== videoId) {
        const playerURL = toPlayerUrl(videoId, options)
        if (pm.playerURL !== playerURL) {
            
            if (psm.player) {  
                psm.release!()     
            }
            
            mu
             .psm({videoId, options, player:undefined})
             .pm({playerURL})

            // -- at which point react will (probably) trigger a render adding the new iframe
            //    so best not to do anything until else here
        
        
            // -- what we really want is to 
            // const player = yield $$.until("<player|")   
        }
    }

})

// type PcCmd a b  ~ a ->  Promise b
const registeriframe = $ => iframe => $.mu( (mu, state) => {

    //const pm:VimeoPsM = yield $$.Get
    const psm:VimeoPsM = state.psm

    if (psm.player) {
        psm.player.destroy()
    }

    const player = new Player(iframe, psm.options )
   
    const release = () => {   
        player.destroy()
    }

    mu.psm({player, release})  // <-- no actions, just records this. Will not trigger a re-render


}) 





const playAt = $ => t => $.mu( (_, state) => {
    const psm:VimeoPsM  = state.psm()
    
    const {player} = psm

    if (player) {
        player.setCurrentTime(t).then(function(seconds) {
            // seconds = the actual time that the player seeked to
            console.log(`t -> ${seconds}s`)
            player.play()
        }).catch(function(error) {
            switch (error.name) {
                case 'RangeError':
                    // the time was less than 0 or greater than the video’s duration
                    break;
        
                default:
                    // some other error occurred
                    break;
            }
        });

    }
})





// -- model






/*
const fetchVimeoData = () => {
    if (this.state.imageLoaded) {
      return;
    }
    const id = this.props.videoId;

    jsonp(
      `//vimeo.com/api/v2/video/${id}.json`,
      {
        prefix: 'vimeo'
      },
      (err, res) => {
        if (err) {
          debug('jsonp err: ', err.message);
          this.onError(err);
        }
        debug('jsonp response', res);

        this.setState({
          thumb: res[0].thumbnail_large,
          imageLoaded: true
        });
      }
    );
  };

*/