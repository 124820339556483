import { filterkv, Result } from "../../../../anyonic/dsl/dsl-api"
import { MuPM } from "../../../../ps/firebase-ps/PresenceMu"
import { ProjectServerEff, SourceKeys } from "./ProjectServerEff"
import {  RefDocPM, RefDoc } from "../model/RefDoc"
import { CoDocEff, EditorEff, IDocCoEff, IEditorEff, IXDocEff, XDoc } from "./EditorEff"
import { FBUser } from "../../../../model/page/user/FBUser"
import { $UI } from "../../../../ui/ps/useAppUIPs"
import { extractRefs, isResolved, parseRefDoc } from "../model/parseRefDoc"
import { $ProjectEditor, ProjectPM } from "../../useProjectRefDocEditorPs"
 

export type ProjectEff = {

  server:ProjectServerEff,
  editor:IEditorEff<RefDoc>,

  // -- coEffect
  coDoc:IDocCoEff,  
  xDoc:IXDocEff,


  pm: MuPM<ProjectPM>

  //create: () => void


  start: (projectId:string, user:FBUser) => void
 
  setDoc: (doc:RefDocPM) => void,

  
  getTxt: () => string  // <-- delegated 
  


  check: () => void 
  res: () => void


  dePersist: (data:any)  => void
  validate: (txt:string, doc:RefDocPM) => RefDocPM
  resolve: (doc:RefDocPM) => Promise<Result<RefDocPM>>
  
  setUnavailable: () =>  void  // <-- offline, and cannot create
}


export const ProjectEff = ($ui:$UI) => ($:$ProjectEditor):ProjectEff => ({
  
  server:ProjectServerEff($ui)($),
  editor:EditorEff($),
  coDoc:CoDocEff($),
  xDoc:XDoc($),


  start: (projectId, user:FBUser) => {  
    const prev = $.pm()
    $.pm.Set({
      user,
      projectId,
      loaded:false,
      validates:false,
      resolved:false,
      saveRequired:false,
      doc:null,
      exists:"unknown",
      unavailable:false,
    })

    $.mu._ps({
      // TODO - stop muliple loading processes
      txt:null
    })
    if (prev.projectId !== projectId || !prev.loading) {
      $.server.start(projectId)
    }
  },

  //https://stackoverflow.com/questions/52850099/what-is-the-reg-expression-for-firestore-constraints-on-document-ids

 
  pm: $.mu.pm,
  
  setUnavailable: () => {
    $.pm({exists:"unknown", unavailable:true, loading:false})
  },

  getTxt: () => {
    const txt = $.editor.currentTxt()
    $.mu._ps({txt})
    return txt
  },


  dePersist: doc => {
   // const {projectId} = $.pm()

    $.setDoc(doc)
  },


  check: () => {
    const txt = $.getTxt()
    const {doc} = $.pm()
    $.validate(txt, doc)
  },
  

  validate: (txt:string, doc0:RefDocPM) => {

    if (doc0.txt === txt) {
     return doc0
    } else if (txt) {
      const {ok, doc:refDoc} = parseRefDoc(txt.split("\n")) 
      if (!ok) {
        $.pm({validates:false})
      } else {
        const {ok:ok2, errs, byRef} = extractRefs(refDoc);
        if (!ok2) {
          const duplicateKeys = Object.keys(errs).join(" ")
          console.log(duplicateKeys , byRef)
       
          $.pm({validates:false})
        } else {


          const srcIds = doc0.srcIds
          const {resolved, srcIds:newSrcIds} = isResolved(byRef, srcIds)
          if (srcIds !== newSrcIds) {
            // corner case: extraneous source ids. When some have been deleted.
            //  we really don't want to persist out dated references ...
            //  but at the same time, we don't want to have to unnecessarily 
            //  re-resolve them.
            console.log(" TODO - handle the case where there are extraneous src ids")
          }
          const doc:RefDocPM = {
            ref:refDoc,
            srcIds: doc0.srcIds,
            validated:true,
            resolved,
            txt:txt
          }


          $.pm({validates:true, resolved,  doc}) 
          return doc
        }         
      
      }

      

    }
  },


 

  setDoc: (doc0:RefDocPM) => {
    const txt = $.editor.setDoc(doc0)
    const doc = $.validate(txt, doc0)
    $.pm({doc, loaded:true, loading:false, exists:true, unsaved:false, resolved: doc.resolved})
  },

  res: () => {
    const {doc} = $.pm()
    $.resolve(doc)
  },

  resolve: (doc:RefDocPM) => {
    const txt = $.editor.currentTxt()
    if (txt !== doc.txt) {
      throw new Error("validate before resolving ")
    }

    // -- omit any references for which we already have a source document
    const {byRef} = extractRefs(doc.ref)
    const prevKeys = doc.srcIds
    const unresolved = filterkv(byRef, k => !prevKeys[k])

    return $.ps(Ok =>  $.server.resolveSources(unresolved )
        .then((srcIds:SourceKeys) => {
          const doc1:RefDocPM = {
            ...doc, 
            resolved:true, 
            srcIds
          }
          Ok(doc1)
        })
      )
  } 
})




