import { MediaEnv } from "../../page/resource/MediaEnv"
import { isReady, Page } from "../../page/resource/PageState"
import { $Page } from "./usePage"

import { axiosGet } from "../../../ps/resource/json/loadJsonP"
import { ResourceManifest } from "../../page/resource/ResourceManifest"
import { NavEff } from "./NavEff"


export type PageEff = {
  page: {
    setLocation: (location:any, navigate:any) => void
    resolve: (rurl:string) => Page
  }


  load: (page:Page) => void

  nav: NavEff
}

export const PageEff = (env:MediaEnv<any>) => ($:$Page):PageEff => {

 return {
  page: _pageEffects(env)($),
  nav: NavEff($),

  load: () => {
    const {page, _loading} = $.mu()
    const {rurl} = page
  
    if (_loading[rurl]) {
      return
    }

    if (!isReady(page)) {
      $.mu._loading({[rurl]:true})
      // BUG: this invokes rendering
      
      const url = ResourceManifest.toSimpleUrl(page.resource.manifest)

      if (url && shouldLoad(page)) {

        $.run(function*($$)  {
          
          yield $$.wait(1)
          $.mu.LOAD_START(rurl)
          
        // console.log(` --- loading --- ${ url}`)
          const {ok, v, errs} = yield $$.ps( axiosGet(url) ) 
          
          $.mu._loading({[rurl]:false})

          if (ok) {
            $.mu.LOAD_COMPLETE(page.rurl, v )
          } else {
            $.mu.LOAD_ERROR(page.rurl, errs[0])
          }
        })

      }
    }
  },



  
}
}



export type PageAPI = {
  setLocation: (location:any, history:any) => any
  resolve: (rurl:string) => any


  /* -- this works /w the mu
    loadStart: (rurl:string) => void
    loadComplete: (rurl, data) => void
    loadError: (rurl, e) => void
  */ 

}

//const pageMu:PageMu<void> = {






const _pageEffects = (env:MediaEnv<any>) => ($:$Page):PageAPI =>  ({

  // -- this is an internal effect, should not be exposed 
  setLocation: (location, navigate) =>  {
    $.mu((mu,s) => {
      const {pathname, search} = location
      const path = pathname + (search ? `${search}` : "")
      const {page, cache} = env.resolvePage(path, s._ps.cache ) 

      mu._ps({cache, location, navigate})
      mu.page.Put(page) 
    })

    return $.mu().page
  },

  resolve: (rurl:string) =>  $.mu((mu,s) => {
    const {page, cache} = env.resolvePage(rurl, s._ps.cache)
    mu({  pm:{page}, _ps:{cache} })  // <-- will only invoke re-rendering if page has changed
  }),

  
})
    


export const shouldLoad = (page:Page) => (page && !(page.status.isLoading) && !(page.status.isReady))

  