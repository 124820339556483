import { FBUser } from "../../../model/page/user/FBUser"
import { AnyonDoc } from "../model/AnyonDoc"
import { DocStatus } from "./DocState"

export type AnyonPM = {

  status:DocStatus
  docId?:string
  entity:any
  doc?:AnyonDoc
  target?: {
    docId:string
    user:FBUser  
  }
}

export const AnyonPM0:AnyonPM = {
  
  status: {
    ok:false,
    loadingStarted:false,
    isLoading:false
  },
  // -- target

  // - depersisted
  entity:null
  
}



