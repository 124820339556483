import { SyntheticEvent } from "react";

import { BubbleTweak } from "../../doc/tweak/BubbleTweak";
import { prevNote } from "../../doc/tweak/MergeTweak";
import { $Bubbles } from "../useBubbleNotes";
import { saveBubbleTweaks } from "../backend/saveBubbleTweaks";

export type TweakEff = {
  save: () => void
  merge: (e:SyntheticEvent, did:string, splat?:string) => void
  toggleChapter: (e, did:string) => void
  indent: (e, did:string, decrement?:boolean) => void
  setType: (e, did:string, type:'sec' | 'ref') => void
}

export const TweakEff = ($:$Bubbles):TweakEff => ({
  
  indent: (_, did, decrement?:boolean) =>  {

    const pm = $.pm()
    const {tweaks} = pm
    const tweak0:BubbleTweak = tweaks[did]

    const indent0 = isNaN(tweak0?.indent) ? 0 : tweak0.indent 
    const indent = Math.min(2, Math.max(0, (indent0 + (decrement ? -1 : 1))))

    var tweak = tweak0 ? {...tweak0} : {}
    tweak.indent = indent

    $.mu.AddTweak(did,tweak)
    $.mu.UpdateTagging(pm.allTags, pm.fpm)
    
  },


  toggleChapter: (_,did:string) => {

    const pm = $.pm()
    const {tweaks} = pm
    var tweak:BubbleTweak = tweaks[did] || {}
    var delta:BubbleTweak 

    var del = undefined
    if (tweak.ch) {
      del = "ch"
    } else {
      delta = { 
        ch: {label:null }
      }
    
    }

    $.mu.AddTweak(did,delta, del  )
    
  },

  setType: (e, did, type) => {
    const pm = $.pm()
    const {tweaks} = pm
    var tweak:BubbleTweak = tweaks[did] || {}
    var delta:BubbleTweak = {type}

    // determine if this is unnecessary
    if (type === tweak.type) {
      return
    }
    

    $.mu.AddTweak(did,delta)
    $.mu.UpdateTagging(pm.allTags, pm.fpm)
    

  },



  save:  async () => {
    const {tweaks, unsavedTweaks, tweaksSaving, readingId} = $.pm()

    if (!unsavedTweaks || tweaksSaving || !readingId) { 
      return
    }

    await saveBubbleTweaks(readingId, tweaks)


    const {tweaks:prevTweaks} = $.pm()
    console.log("tweaks saved")
    $.pm({
      unsavedTweaks: (prevTweaks !== tweaks),
      tweaksSaving:false
    })


  },


  merge: (_:SyntheticEvent, did:string, splat?:string) => {
    const pm = $.pm()
    const {reading} = pm 
    const to = prevNote(reading.pages, did)
    
    if (to) {

      $.mu.AddTweak(did, {merge:{to, splat}})
    }

  }

})

