import { Box, styled } from "@mui/system";
import ArticleIcon from '@mui/icons-material/Article';
import { BG, LINK, RED } from "../../../../ui/theme/theme-lea";
import { $Bubbles } from "./useBubbleNotes";
import { Group, Note, NoteNode, NtTags, PgActions, PgTags } from "../doc/BubblePM";

import { includes } from "../doc/noteTagging";
import { BubbleNote, isReference, isSectionTitle } from "../doc/BubbleModel";



export const renderNote = (pm:PgTags&PgActions, note: NoteNode<any,any,any>, $:$Bubbles, i)  => {// -  //cpTxt: any, quoteFn:any, navSection:any, isSec:any, isSel:any, i:any) =>

 
  if (note.$$ === "Group") {
      return <NotesGroup  pm={pm} key={i} $={$} group={note}/>
  } else if (note.$$ === "Note") {
    if (isSectionTitle(note.note)) {
      return <NoteSection key={i} pm={pm} bnote={note} $={$}/>
    } else if (isReference(note.note)) {
      return <NoteReference key={i} pm={pm} bnote={note} $={$}/>
    }
    return <LeafNote key={i}  pm={pm} bnote={note} $={$}/>
  }

  // -- splat
  return <div> ...</div>

  
/*
  <div  key ={i}
    className={
      "bubble-wrap" +
      (note.col == "green" ? " bubble-wrap-section-title" : "")
    }></div>
}*/
  
}


/* 
        render group
        ( 
      <div  key ={i} className="bubble-group bubble-colour-tagged">
        {renderGroup(note.nodes, $) /*, cpTxt, quoteFn, navSection, isSec, isSel )}
      </div>
*/


 


  const NoteReference = ({$, bnote, pm}:{$:$Bubbles, pm:PgTags,  bnote:Note<any>}) => {
    const {note} = bnote
  
    return (
      
      <Box sx={{
          pb:'15dpx',
          mb:'15px',
          ml:'17px',
          display: 'inline-flex',
          borderWidth:'3px',  
          borderColor: toCol(bnote, pm),
          my:'3px',
        }}>

        <Box   sx={{
            fontFamily:"Ubuntu",
            pl:"10px",
            display:"flex",
            flexGrow:1,
            lineHeight: 1.1,

            my:'3px',
          }}>
        
      
      <ArticleIcon sx={{fill:"#fbe807", pt:'1px'}} />
        <Box  sx={{ml:'10px'}}>

    
          {note.text}
          {"  "}  

          {false && <NoteLink onClick={e => $.copy(e, bnote)}>
            copy
          </NoteLink>}
          
          {renderTags(pm, bnote, $)}
    
          {false &&  <NoteLink onClick={e => $.quote(e,bnote)}>
            quote
          </NoteLink>}
          </Box>

      </Box>
    </Box>)

  }
  const NoteSection = ({$,pm,bnote}:{$:$Bubbles, pm:PgTags, bnote:Note<any>}) => {
 
    return (
      <Box sx={{
          pb:'10px',
          mr:"70px",
          mt:"10px",
          mb:"30px",
          borderLeft:5,
          borderWidth:'3px',  
          borderColor: toCol(bnote, pm),
          my:'3px',
        }}>

        <Box sx={toSectionSx(bnote.note)}>

        {bnote.note.text}
        {renderTags(pm,bnote, $, true)}
       
        
      </Box>
    </Box>)

  }


  const LeafNote = ({$,bnote, pm}:{$:$Bubbles, pm:PgTags,  bnote:Note<any>}) => {

    return (
      <Box  sx={{
          pb:'10px'
        }}>

        <Box sx={{
            fontFamily:"Ubuntu",
            pl:"10px",
        
            flexGrow:1,
            lineHeight: 1.1,
            borderLeft:5,
            borderWidth:'3px',  
            borderColor: toCol(bnote, pm),
            my:'3px',

          }}>
        <span onClick={e => $.tag(e, bnote, "1")}>
         {bnote.note.text}
        </span>
      
        {"  "}  
        
        {bnote.pm?.showComp  &&
        <NoteLink >
          copy
        </NoteLink>}
        
        {renderTags(pm,bnote, $)}
  
        {false &&  <NoteLink onClick={e => $.quote(e, bnote)}>
          quote
        </NoteLink>}

      </Box>
    </Box>)

  }

  const toCol = (note:Note<NtTags>, pm:PgTags) => {
    
    const {did} = note
    if (pm.taggings[did]) {
      return RED 
    } else {
      return BG
    }

  }


  export const NotesGroup = ({$,pm,group}:{$:$Bubbles,pm:PgTags, group:Group<any,any,any>}) => {

    return (
    <Box sx={{
        borderRight:3,
        borderColor:LINK,
        pl:"5px",
        pt:"0px",
        pb:"-5px",
        mb:"10px"
      }}>
      {group.notes.map((note, j) => renderNote(pm, note, $, j))}
      


    </Box>)
  
    
  }



  const renderTags = (pm:PgTags&PgActions, note:Note<any>, $:$Bubbles, isSection:boolean = false ) => {
    const {taggings, allTags} = pm
    const {did} = note

    const data = allTags.map(tag => {
      const selected = taggings[did]  && includes(taggings[did],tag)
      return {tag, selected}
    })

    const tweakable:boolean = !!pm.actions   //<-- FIX_THIS
    const taggable:boolean = !pm.actions 
    return (<>
      {taggable && data.map(({tag, selected},) => {
        if (selected) {
          return (<TagSelected key={tag} onClick={e => $.tag(e, note, tag)}> 
            #
          </TagSelected>)
        } 
        return (<TagNotSelected key={tag} onClick={e => $.tag(e, note, tag)}> 
          #
        </TagNotSelected>)


      })}
      {tweakable  ?
      
        isSection
        ? (<>
          <TagNotSelected key="__tweak" onClick={e => $.tweak.toggleChapter(e, note.did)}>
            -Ch
          </TagNotSelected>
          <TagNotSelected key="__tweakInc" onClick={e => $.tweak.indent(e, note.did, true)}>
            {"<<"}
          </TagNotSelected>
          {" "}
          <TagNotSelected key="__tweakDec" onClick={e => $.tweak.indent(e, note.did)}>
            {">>"}
          </TagNotSelected>
        </>)
        : <TagNotSelected key="__tweak" onClick={e => $.tweak.toggleChapter(e, note.did)}> 
            +Ch
          </TagNotSelected> 
          :null}
      {tweakable &&
        <>
          <TagSelected key="__tweak_merge" onClick={e => $.tweak.merge(e, did)}>
            +merge
          </TagSelected>
        
          <TagSelected key="__tweak_merge2" onClick={e => $.tweak.merge(e, did, "...")}>
            +...
          </TagSelected>

          <TagSelected key="__tweak_merge3" onClick={e => $.tweak.setType(e, did, 'sec')}>
            {'>>'}
          </TagSelected>
          <TagSelected key="__tweak_merge4" onClick={e => $.tweak.setType(e, did, 'ref')}>
            {'[ref]'}
          </TagSelected>

          
        </>}
       
    </>)

  }


  export const TagSelected = styled('span')(({theme}) => `
    color: ${theme.palette.slide.RED};
    text-decoration: none;
    text-decoration-color: ${theme.palette.slide.LINK};
    padding-left: 5px;
    font-family: 'Ubuntu';
    font-size:20px;
    margin-left: 7px;
    &:hover {
      color: ${theme.palette.slide.LINK};
      text-decoration: underline;
      text-decoration-color: ${theme.palette.slide.RED};
    }
`)


export const TagNotSelected = styled('span')(({theme}) => `
  color: ${theme.palette.slide.LINK};
  text-decoration: underline;
  text-decoration-color: ${theme.palette.slide.RED};
  padding-left: 5px;
  font-family: 'Ubuntu';
  font-size:20px;
  margin-left: 7px;
  &:hover {
    color: ${theme.palette.slide.RED};
    text-decoration-color: ${theme.palette.slide.LINK};
  }
  `)




  export const NoteLink = styled('span')(({theme}) => `
    justify-self: flex-end;
    color: ${theme.palette.slide.LINK};
    text-decoration: underline;
    text-decoration-color: ${theme.palette.slide.RED};
    padding-left: 5px;
    font-family: 'Ubuntu';
    font-size:20px;
    margin-left: 7px;
    &:hover {
      color: ${theme.palette.slide.RED};
      text-decoration-color: ${theme.palette.slide.LINK};
    }
`)


// --- TODO - extract to typography


const toSectionSx = (note:BubbleNote):any => {

  // TODO - use typography
  const index = note.pm!.indent

  if (index === 0) {
    return Title
  } else if (index === 1) {
    return H1
  } else {
    return H2
  }
 
  
}


const Title:any = 
  {
    fontSize:30,
    color: RED,
    ml:"10px",
    flexGrow:1,
    lineHeight: 1.1,
    borderBottom:1,
    borderWidth:'2px',  
    borderColor: '#666666',
  }

  const H1:any = 
  {
    fontSize:25,
    color: RED,
    ml:"10px",
    flexGrow:1,
    mt:5,
    lineHeight: 1.1,
  }


  const H2:any = 
  {
    fontSize:20,
    color: RED, 
    mt:5,
    ml:"10px", 
  }
