import { UIState } from './UIState';

export const  uiState0:UIState = {
  menuOpen: false,
  drawerOpen: false,

  requireSave: false,
  saveInProgress: "no",
  showBaseDoc:false,
  canQuote: null,

  // -- when editing
  showTagfilter: true,
  tagFilter: false,

  isLoading:false,
  isSaving:false,
  msg:undefined
}


export const uiReducer = (state: UIState = uiState0, action) => {

  if (action.type === "UI_ACTION") {
    const {payload:a} = action
    switch (a.$) {
      case "SET_MENU": 
        return {...state, menuOpen:a.open}
      case "SET_DRAWER":
        return {...state, drawerOpen:a.open}
      case "SET_REQ_SAVE":
        return {...state, requireSave:a.v}
      case "SET_SAVE_IN_PROGRESS":
        return {...state, saveInProgress:a.v}
      case "SET_SHOW_BASE_DOC":
        return {...state, showBaseDoc:!state.showBaseDoc}
      case "SET_CAN_QUOTE":
        return {...state, canQuote:a.v}
      case "SET_MSG": 
        return {...state, msg:a.msg}
    }
  }
  return state
}


