import { fmap, keys } from "../../../../../anyonic/dsl/dsl-api"
import { SEC2 } from "../../../import/colMap"
import { BubbleReading, BubbleNote, PgIds, Notes, BubbleNoteInternalPM } from "../BubbleModel"

export type Tweaks = {[did:string]:BubbleTweak}

export type ChapterTweak = {
  label:string|null
  title?:string
}

/**
 * Tweaks are annotations upon the imported notes, that put then into a 
 *   more useful form, but while retaining the imported text as connonical 
 * 
 *  1. default tweakings. 
 *      o remove beginning and trailing w/s
 *      o de-hyphenate (ie  "This should not be hy-  phenatd"")
 *  
 * 2. chapter annotation 
 *    ch: {
 *      label:"1"  -- or "intro" or "Appendix A"  
 *      title:"Chapter 1" <-- use this when
 *     }
 * 
 * 2. splats & trims
 *    o distill with ...
 *    o edit with [distillations]
 *    o trim inadvertently added/removed text
 *    
 * 3. merge. merge multiple notes (ie. fron diffent pages)
 *    o 
 * 
 */
export type BubbleTweak = {                            
  ch?:ChapterTweak
  
  verbatim?:boolean  // <-- omit default tweaks
  type?:'sec'|'ref'  // <-- set type of note
  // -- replace text 
  // [start, end] indices must not overlap
  // ie   "this is a [Very, longwinded description] of ... " 
  splats?: BubbleSplat[]

  // -- merge to a parent 
  merge?:MergeTweak
  indent?:number


}

export type MergeTweak = {
  to:string //
  splat?:string
}

export type BubbleSplat = {
  i1:number,
  len:number,  
  txt?:string,        // defaults to "..."
  quitely?:boolean,   // omit the [Alternate ]
  invisibly?:boolean  // -- hide that this is a change 
}



const WS1:RegExp = /^(\s+)/;
const WS2:RegExp = /(\s+)$/;
const WS3:RegExp = /\s(\s+)/
const HYPHEN = /[^!\s-](-\s+)/g;

const H_END = /-$/  // hyphenated, with no trailing whitespace

/*
 * 1. remove leading and trailing ws.
 * 2. remove any "my wor- d " hyphenations
 */
export const pretweak = (v0:string): string => {
  const v1 = v0.replace(WS1, "")
  const v2 = v1.replace(WS2, "")
  var v3 = v2
  while (v3.match(WS3)) {
    v3 = v3.replace(WS3, " ")

  }
  const v4 = v3.replace(HYPHEN, v => {
    return v.charAt(0)
  })
  return v4
}
  
const toIndent = (note:BubbleNote, tweak:BubbleTweak) => {
  if (!isNaN(tweak?.indent)) {
    return tweak.indent
  }
  if (note.col === SEC2) {
    return 1
  }
  return 0
}

  export const applyTweaks = (reading:BubbleReading, tweaks:Tweaks):BubbleReading => {
    const {pages:pages0, notes:notes0} = reading
    
    // apply pre-tweaks
    const notes = fmap<BubbleNote, BubbleNote, Notes>(notes0, (v:BubbleNote, did) => {
      const tweak:BubbleTweak = tweaks[did]

      let pm:BubbleNoteInternalPM = {
        indent:toIndent(v,tweak)
      }
      if (tweak?.type) {
        pm.type = tweak.type
      }
      return {...v, text:pretweak(v.text), pm}
    })


    const merged:{[did:string]:string} = {}
    const hasMerged:{[did:string]:string} = {}
    

    // -- perform merging
    keys(tweaks, (did, tweak:BubbleTweak) => {
      const {merge} = tweak
      if (merge) {
        const child = notes[did]
        const parent = notes[merge.to]
        if (parent) {
          if (hasMerged[did]) {
            throw new Error("merges need to be chained in order")
          }
          hasMerged[merge.to] = merge.to
          merged[did] = did;
          notes[merge.to] = {
            ...parent,
            text: mergeTxt(parent.text, child.text, merge.splat)
          }
          delete notes[did]

         }

      }
    })
    // -- remove merged page from reading
    const unmerged = did => !merged[did]



    const pages = fmap(pages0, (pgs:PgIds) => pgs.map( p => {
        if (p instanceof Array) {
          var p1 = p.filter(unmerged)
          if (p1.length === 0) return null
          if (p1.length === 1) return p1[0]
          return p1
        }
        return unmerged(p) ? p : null
      }).filter(v => (v && (v.length !== 0)))
    )
    

    return {
      pages,
      notes
      // TODO - return merge data to map tags
    }
  }



  export const mergeTxt = (t1:string, t2:string, splat?:string) => {
    var txt
    if (splat && splat.length > 1) {
      txt = t1 + " " + splat + " " + t2
    } else {
      txt = t1 + (t1.match(H_END) ? "" : " ") + t2
    }
    return pretweak(txt)
  }