import { useEffect } from "react"
import { usePm$ } from "../../anyonic/dsl/usePm"
import { MuPM } from "../../ps/firebase-ps/PresenceMu"
import { AnyonPM, AnyonPM0 } from "./pm/AnyonPM"
import { AnyonPSM, AnyonPSM0 } from "./AnyonPSM"
import { AnyonEff } from "./eff/AnyonEff"
import { AnyonMu0, AnyonMuFn } from "./AnyonMu"
import { useUI$, useUser } from "../../MediaContextProvider"

 

export type $Anyon = {
  mu:AnyonMuFn
  pm:MuPM<AnyonPM> 
  _ps:MuPM<AnyonPSM>
  ps:any  
  run:any
  
}  & AnyonEff

 
export const useAnyonPs = (id):{$:$Anyon, pm:AnyonPM} => {
  const user = useUser()
  const $ui = useUI$()

  const {$, pm} = usePm$(() => ({
    pm:AnyonPM0,
    _ps: AnyonPSM0,
    mu:AnyonMu0,

    $:$ =>  ({
      pm:$.mu.pm,
      _ps:$.mu._ps,
      ...AnyonEff($, $ui.$)
    })

  })) as {$:$Anyon, pm:AnyonPM}

  useEffect(() => {
    $.start(id, user.user)
    return () => $.stop()
  }, [id,user.user, $])

  return {$, pm}
}