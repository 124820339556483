import { P } from "../../anyonic/dsl/dsl-api"
import fb from "../../firebase/firebase"
import { DefaultReducer } from "../../ps/firebase-ps/PresenceMu"
import { AnyonPM  } from "./pm/AnyonPM"
import { AnyonPSM } from "./AnyonPSM"


// mu.pm({subset of properties of pm})
//   .Set( )  <-- etc 
export type LeafMu<pm,r> = ((o?:P<pm> ) => r) & DefaultReducer<pm, r>



export type Mu0<pm, ps, r, r1> = {
  pm: LeafMu<pm, r>
  _ps: LeafMu<ps,r1>  
}
/**
 * 
 * 
 * 
 */
export type MuFn<pm, ps, cmds, r> = (mu:( cmds & Mu0<pm,ps, void, void>), s:{pm:pm, _ps:ps}) => r


export type Mu<pm, ps, cmds> = 
  ((f?:(MuFn<pm, ps, cmds, void>)) => {pm:pm, _ps:ps})    // $.mu( (mu, s) => void) ) => {pm,ps}
    & cmds                        // $.mu.CmdReducer -- top level mu commands
    & {     
      pm:LeafMu<pm,pm>           // $.mu.pm()  , $.mu.pm({p}),   $.pm.Cmd(...)
      _ps:LeafMu<ps,ps>
    } // $ Mu0<.. ,pm>            // $.mu.pm.Cmd() 


    

    


export type AnyonMu<r> = {
  SetDocExists: (docId:any, exists:false, isLoading?:boolean) =>r
 
 
  CreateDoc: (docId:string) => r
  SetDoc: (docId:string, text:string) => r
}




export type AnyonMuFn = AnyonMu<MuFn<AnyonPM, AnyonPSM, AnyonMu<any>, void>>



export const AnyonMu0:AnyonMuFn = ({
  
  SetDocExists: (docId:any, exists:false, isLoading?:boolean) =>  (mu,s) => {
    mu.pm({status:{...s.pm.status, docId, exists, isLoading}})
  },

  SetDoc: (docId:string, text:string) => (mu,s) => {
    mu.pm({doc:{text}})
    mu.pm({status:{...s.pm.status, 
      ok:true,
      docId,
      isLoading:false,
      canSave:false,
      isSaving:false,
      exists:true, 
      canValidate:true 
     }})
  },

  CreateDoc: (docId) => (mu, {pm}) => {
    const {user} = pm.target 
    
    const entity = {
      text:"new document",
      owner:user.name,
      created:fb.TIMESTAMP()
    }
    
    mu.pm({
      docId,
      entity,
      doc:{text:"new document"},
      status:{...pm.status, ok:true, docId, isLoading:false, exists:true, canValidate:true, }
    })
  }


})

