import * as React from 'react';

import { UserState } from './model/page/user/UserState';
import { isReady } from './model/page/resource/PageState';
//import { Layout } from './ui/layout/LayoutAsad';
import { Login } from './view/login/Login';
import { usePage, useUser } from './MediaContextProvider';
//import { LayoutLea } from './ui/layout/lea/LayoutLea';
//import { ConstructionOutlined } from '@mui/icons-material';
import ResourceStatus from './media/util/Resources';
import { LandingBG } from './ui/theme/styles-lea';
import { LayoutLea } from './ui/layout/lea/LayoutLea';
import { RENDERERS } from './AppRenderers';
//import { CandlePaintingMedia } from './unit/candlepaint/__stories/III_CandlePaintingMedia';
//import { AudioEffTestStory } from './react-ct-stories/mediaPs/eff/audio/__test/AudioEffTestStory';
//import { BasicTimeline } from './react-ct-stories/timeline/__stories/I_BasicTimeline';

//import {CTLexTest}  from "ct-lex"
//const LOGIN = "/login" 

// -- debug only 
/*
export const App3 = () => {
  return (

    <Layout>
      <App2/>
    </Layout>)
}



*/

//var CANDLEPAINTER = true

export const App = () => {
 /*if (CANDLEPAINTER) {
    return (<Root>
      {/*<AudioEffTestStory/>
            <BasicTimeline/>
<CTLexTest/> 
                <CandlePaintingMedia/>
       </Root> }*/


  
  
  

 // } */
  return App1()



}

export const App1 = () => {
  
  const user:UserState = useUser()    // <-- this needs to be run at the top level to listen for user login
  //const page = usePage()  
  
  if (user.isLoading) {
    return <div>  ... Loading </div> // <-- only happens while auth is initializing
  } else  if (!user.user) {  // <-- no user, require login
    return <Login/> 
  }

  


  return (

      <LayoutLea>
        <PageView/>
      </LayoutLea>) 


}



//<Tab label="Project" value="/dproject" to="/drpoject" component={Link} />

//<Tab label="draw" value="/draw/:pic" to="/draw/pic" component={Link} />



export const App2 = () => {
  //const {$, pm} = usePagePs(appEnv)  // <-- TODO - move this to a media context
  const user:UserState = useUser()    // <-- this needs to be run at the top level to listen for user login

  if (user.isLoading) {
    return <div> Loading </div>
  } else  if (!user.user) {//} && (page.rurl !== LOGIN)) {
    return <Login/> 
  }

  return <LandingBG>
      <PageView/>
    </LandingBG>

  }
  /*
  return ( 
      <LayoutLea>
        <PageView/>
      </LayoutLea>
    )

} 
/*
const toRedirect = (page, user ) =>  {
  console.log('redirecting', {page, user})
  return <RequireLogin/>

}

*/




//<Link to={LOGIN}>login</Link>
/*
const RedirectToLogin = () => {
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  
  useEffect( () => {  
    if (pathname !== LOGIN) {
      dispatch(NavActions.LOGIN() )
    }
  }, [pathname, dispatch])

  return <div> 
    path "{pathname}"
    ... redirecting to login
  </div>
}*/

const PageView = () => {
  const page = usePage()
  
  if (!isReady(page)) {
    return <ResourceStatus rurl={page.rurl}/>
  }
  var component =  RENDERERS[page.resource.type]
  return component || RENDERERS['404']
}
 





