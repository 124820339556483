import { ProjectData } from "../project/Project";
import { Ref } from "../Ref";

const toRefLink = (ref: Ref, url: string) => {
  return `${url}/${ref.ref}`;
};

export type FoldersPM = {
  folders:Folder[]
  project
}

export type Folder = {
  path:string
  items:FolderItem[]
}

export type FolderItem = {
  link:string
  name:string
  path:string
  ref:string
  authors:string[]
}

/**
 * 
 * Collects references by folder
 *
 */
export const toFolders = (data: ProjectData, url: string) => {
  var paths: { [path: string]: Ref[] } = {};
  var refs: { [ref: string]: Ref } = {};

  for (var node of data.resources) {
    let { path, ref } = node;
    if (ref) {
      refs[ref] = node;
    }
    paths[path] = paths[path] || [];
    paths[path].push(node);
  }

  var pathNames = Object.keys(paths);
  pathNames.sort();

  var out:any[] = [];
  
  for (var path of pathNames) {
    out.push({
      path: path,
      items: paths[path].map((o) => ({ ...o, link: toRefLink(o, url) })),
    });
  }


  const folders =  { folders: out, project: data };

  const folders1 = hackFolders(folders)

  return folders1
};

const hackFolders = (folders) => {
  return { ...folders,
    folders: H.map(({v0,v1}:any) => {
    var origional = folders.folders.find(e => (e.path === v0))
      if (!origional) {
        console.log('cant find ' + v0)
        throw new Error("BUG - in folder hak")
      }
    return {...origional, path:v1}
  }),
  }

}

const H = [

  {v0:'02 - Epistemic', v1:"Epistemic Injustice and Hyper-vigilance"},
  {v0:'01/Multiculturalism & SJ', v1:"Multicultrualism in Social Justice" },
  {v0:'01/Humanism', v1:"Humanism"},
  {v0:'01/Liberation Psychology', v1:"Literation Psychology"},
  {v0:"01/Thesis' - Cutts and McNalley", v1:"Thesis & Reviews on Social Justic in Counselling"},
  {v0:'01/Training', v1:"Training"},
  {v0:'01/Frameworks', v1:"Framework literature"},
  //6:'01/Unclassified'
  {v0:'01/clinical practice', v1:"Clinial Practice"}]

