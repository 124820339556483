
// -- minimal representation of firebase user
// data FBUser = FBUser uid. name. avatar.   // -- everything else is generateable


export type FBUser = {
  $:'FBUser'
  uid:string
  name:string
  avatar:string
}


export const FBUser = (uid:string, name:string, avatar:string):FBUser => 
  ({$:'FBUser', uid, name, avatar})

