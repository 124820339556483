import { EditorConfig, TextNode } from "lexical";

// https://lexical.dev/docs/concepts/nodes#extending-textnode

export class CatNode extends TextNode {
  
  __className:string
  __color: any


  static getType() {
    return "cat";
  }

  static clone(node) {
    return new CatNode(node.__field, node.__key);
  }

  constructor(cat, key?) {
    super(cat, key);
    this.__className = "antiform-cat"     //"antiform-field";
    this.__color =  "#E65634"   //  "#51ACC5"  // "#E65634" 
  } 




  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.style.color = this.__color;
    return element;
  }

  updateDOM(
    prevNode: CatNode,
    dom: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    if (prevNode.__color !== this.__color) {
      dom.style.color = this.__color;
    }
    return isUpdated;
  }
}

export function $isCatNode(node) {
  return node instanceof CatNode;
} 

export function $createCatNode(fieldName):CatNode {
  const node = new CatNode(fieldName).setMode("token");
  return node
}
