//import { EmojiNode } from "./nodes/EmojiNode";
import { KeywordNode } from "./nodes/KeywordNode";
import { AntiformNode } from "./plugins/form/AntiformNode";
import { CatNode } from "./plugins/form/CatNode";
import { FieldNode } from "./plugins/form/FieldNode";
import { RefNode } from "./plugins/form/RefNode";
import ExampleTheme from "./themes/ExampleTheme";

const editorConfig = {
  theme: ExampleTheme,
  namespace: 'antiform',
  onError(error) {
    throw error;
  },
  nodes: [ KeywordNode, FieldNode, AntiformNode, RefNode, CatNode]
}; 

export default editorConfig;
