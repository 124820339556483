import firebase from "./firebase"




var _fbPs // singleton source of base firebase services

export type Timestamp = any
export const TIMESTAMP = () => firebase.database.ServerValue.TIMESTAMP


export const fbPs = () => {

  if (!_fbPs) {
    _fbPs = {
      // -- services
      '<auth|':firebase.auth,
      '<connected|': firebase.database.ref(".info/connected"),
      
      // -- concieveably, this might be injected via app name

      '<users|':firebase.database.ref("users"),   
      '<presence|': firebase.database.ref("presence"),

      '<typing|': firebase.database.ref("typing"),  // <-- TODO - integration this with presence/status
  
      
      // --  messages 
      '<channels|': firebase.database.ref("channels"),   
      '<privateMessages|': firebase.database.ref("privateMessages"),
      '<messages|': firebase.database.ref("messages"),
      
      // --  tags  - probably 
      
      '<storage|': firebase.storage.ref(),  


      // -- project resources, 
      '<Projects|': firebase.db.collection("Projects"),
      // -- sources - be referenced by multiple projects
      '<BubbleSrc|': firebase.db.collection("BubbleSrc"),
      // -- notes 
      '<BubbleNotes|': firebase.db.collection("BubbleNotes"),
      '<BubbleTags|': firebase.db.collection("BubbleTags"),

      '<Anyon|': firebase.db.collection("Anyon"),


      listeners:[]

    }

    _fbPs = {
      ..._fbPs,
      //   BubbleNotes/:BubbSrc
      // 
      // Projects/$project/refs/core=>Entity<RefDocPM>
      projectCoreRef: (projectId:string) => _fbPs['<Projects|'].doc(projectId).collection('content').doc("refs"),
      
      bubbleSrcRef: () => _fbPs['<BubbleSrc|'],
      bubbleSrcDocRef: (ref:string) => _fbPs['<BubbleSrc|'].doc(ref),
      //BubbleSrcRef: (id:string|undefined) => _fbPs['<BubbleNotes|'].doc("src").collection("src"),
      //BubbleReading: (id:string) => _fbPs['<BubbleNotes'].doc('')
      
      
      bubbleNoteReading: (reading) => _fbPs['<BubbleNotes|'].doc(reading),
      // <BubbleNotes/{:reading}/  <-- this can be queried etc.
      //        .notes.data   :: Entity<BubbleReading>  <-- cannonical data
      //        .notes.tweaks :: Entity<BubbleTweaks>    
      bubbleNoteCollectionData: (reading) => _fbPs['<BubbleNotes|'].doc(reading).collection("notes"),

      bubbleNoteData: (reading) => _fbPs['<BubbleNotes|'].doc(reading).collection("notes").doc("data"),
      bubbleNoteTweak: (reading) => _fbPs['<BubbleNotes|'].doc(reading).collection("notes").doc("tweaks"),

      bubbleNotesQuery: (user:string, src:string) => {
        return _fbPs["<BubbleNotes|"]
         //   .where("owner", "==", user)
            .where("src", "==", src)
      }, 

      getTagCollectionRef: (readingId:string, user:string) => {
        return _fbPs['<BubbleTags|'].doc(readingId).collection(user)
      },

      // -- documents
      getAnyonDoc: (id:string) => {
        return _fbPs['<Anyon|'].doc(id)
      }


    }
  }

  
  return _fbPs
}
