import * as React from "react" 
import { useLoginPs } from "../../ps/user/useLoginPs";
import { BaseForm } from "./BaseForm";


export const Login =  () =>  {
  const {pm, $} = useLoginPs() 
  
  return BaseForm(pm, $)
}
  
export const Loading = () => {
  return <div>Loading... </div>
}

    
export const Logout = () => {
    const {$} = useLoginPs()
    return <div>
      <a href="/logout" onClick={$.logout}>logout</a>
    </div>
}