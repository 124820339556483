
export type Handlers = {[name:string]:ActionHandler}


// -- internal representation of interpreter 
export type HandlerSpec = {
  defs:{[ns:string]:DslDef<any>},   // <-- definitions 
  byNs:{[ns:string]:{def:DslDef<any>, handlers:Handlers}}   // <-- reference defs, 
  ctors:{[ns:string]:{[cmdName:string]:Function}}   // <-- constructors by namespace
  handlers:Handlers,   // <-- low level objects to execute commands
  muReducers:{[ns:string]:any},
  muActions:{[ns:string]:any}, // P<pm> || MuFn(...)

  $$:any              // <-- access all command constructors 
  
  // --  internal state objects 
  refs:{[ns:string]:{current:any}},  // <-- mutable state TODO - implement as proper reducer 

  
  //pm:any // <-- cache mutable pm here
  //pmKeys?:string[] // <-- when defined, pm is composed of multiple keys

  stateRef:{current:any}  // <-- persisted state 
  updating:{[ns:string]:boolean}    // <-- namespaces that require update

  //prevState?:any   // --for optimization

  update:(visited:{[ns:string]:any}) => void    // <-- let this function handle invoke side effects
                              //   - reporting changes back to state 
                              //   - invoke other update ReducerDef fns (and manage order)
}

/**
 *  Each term is represented in a low leven action handler
 * 
 */
export type ActionHandler = {    // <-- low level mush of data in which to implement execution engine
  // -- definition of handler
  def:DslDef<any>,           
  ns:string,         //  namespace 
  name:string,       //  term name 
  nsname:string,     //  for convenince ~ ns::name
  isSelector?:boolean,  // <-- for reducers, distinguishes between selector and action  
  isGen?:boolean,       // <-- distinguishes between an expected generator and a promise 
  
  // -- constructor ie yield $$.MyTermConstructor(...), probably wrapped.
  cons:any              // <-- term constructor, ie $$.Term(...). May be wrapped 

  // -- state and 
  ref:{current:any},     // <-- local (mutable) state persisted here
  fn:any,               // <-- action to be take when this action is recive
}


export type DslDef<a> = {
    $:'Reducer', 
      actions:(name:string) =>  any,
      reducers: {[name:string]:((state:any, action:any) => any)},
      selectors: {[name:string]:(state:any) => any},   // extension of reducer to effect albegras
      state:a       // <-- TODO, extract initial state ?
    //  setState?:any   // <-- optional action to inform context of state change
  } | { 
    $:'Dispatch',  
        actions:(name:string) => any,
        dispatch:(action:any) => void,  // <-- TODO - rename
        selectors?:Selectors 
  } | {
    $:'Async', 
      actions:{[name:string]:any}, 
      isGen?:boolean
  }
  
   
const REDUCER = "Reducer"

export const isDef = o => {
  return (o instanceof Object) && o.$ === REDUCER
}

export const Reducer = <a>(actions, reducers, selectors, state:a):DslDef<a> =>  ({$:REDUCER, actions, reducers, selectors, state })
export const Dispatcher = <a>(actions, dispatch, selectors?):DslDef<a> => ({$:'Dispatch', actions, dispatch, selectors})
export const Async = <a>(actions, isGen?):DslDef<a> => ({$:'Async', actions, isGen})



// -- Cancellable promise
export class CPromise<T> extends Promise<T> {
    public cancel:() => void  =  () => undefined 
    // TODO - override then, catch, finally to also return a CPromise
} 
//(resolve: (value: unknown) => void, reject: (reason?: any) => void

// TODO - implement as constructor
export const cPromise = <a>(executor:any ):CPromise<a> => {
  var cancel 
  var p:CPromise<any> = new CPromise((resolve, reject) => {
    cancel = executor(resolve, reject)
  } ) 
  p.cancel = cancel || (() => undefined)
  return p
}

// -- specification fot the dsl algebra

// -- maybe a nice way to do implement this is via a convention 
//   cmds( {
//      myCmd: args => function*($$, $) { ... }  <-- a generator
//      myCmdP: args => Promise( ..)          <-- P suffix denotes promise instead
//   })
export const cmd = (actions):DslDef<any> => Async(actions, true) 
export const cmdP = (actions):DslDef<any> => Async(actions, false)


export type Selectors = { $:'Selectors', selectors:{[name:string]:(state:any) => any},  stateRef:{current:any} }
export const Selectors = (selectors, stateRef) => ({$:'Selectors', selectors, stateRef})



// -- Simple state reducer, as a default implementaiton.
//   
//   typeical useage /w react would be:
//   const [state, setState] = useState(...)
//   $.run = dsl({pm:Mu(pm0)} )   
//    ... 
// 
//    $.mu.pm.Set({x:1, y:2})    <-- incrementally sets x and y properties only
//    $.mu.pm.Put(myModel)       <-- replaces entire data structure
//    $.mu.pm.Append({xs:["newX"],  <-- appends arrays, inserts into hashes
//                    obj:{newKey:value}}) 
//    $.mu.someModel.Toggle({flag:true})  <-- toggles boolean 
//  
//    const state = $.state().pm
// 
//  // -- equivalently 
//  $.run(function*($$) { 
//    const {Set, Put, Append, Get} = $$.mu.pm
//   
//    yield Set({p1:newValue, p2:etc})   
//    yield Put(newState)                
//    yield Append({messages:['another message]', errors:['another error] })  // -- append arrays 
//    var state:MyPM = yield Get 
// 
//    return state          
//   }
export type ReducerDSL =  {$:'Set', vs:any } | {$:'Put', s:any }  |  {$:'Toggle', vs:any} | {$:"Append", vs:any}


export const StateTerms =  {   
  Set: vs => ({$:'Set', vs}),        // <-- sets property {p:"new p"}
  Put: s => ({$:'Put', s}),          // <-- puts entire state state
  Toggle: vs => ({$:'Toggle', vs}),  // <-- toggles boolean (or nullable x?:boolean)
  Append: vs => ({$:'Append', vs})   // <-- appends array
}

export const StateSelect = {Get: s => s}

export const StateReducer = {
  Set: (state, {vs}) => ({...state, ...vs}),
  Put: (_, {s}) => s,
  Toggle: (s, {vs}) => Object.keys(vs).reduce((s,k) => {
    if ((s[k] != null && (typeof s[k] !== "boolean")) ) {
      throw new Error("Toggle requires a boolean")
    }
    s[k] = !s[k]
    return s
  }, {...s}),
  Append: (s, {vs}) => Object.keys(vs).reduce((s, k) => {
      const v0:any = s[k]
      if (!v0) throw new Error(`Append (on ${k}) requires an initial value`);
      (v0 instanceof Array) 
        ? (s[k] = [...(v0), vs[k]]) 
        : (s[k] = {...v0, ...vs[k]})  // { p:{ ... add/replace new values to object }  }
      return s
    }, {...s})
} // StateReducer



export type MuOptions = { 
  update?:Function
}


export const Mu = (state0) => 
  Reducer(StateTerms, StateReducer, {Get: s => s}, state0)



/**
 *  js friendly version of the Either monad
 *  
 *  data Result v = Ok .v ok:boolean | Err = err:{message:string} ok:boolean
 * 
 */
export type Result<a> = { ok:true, v:a}  | { ok:false, err:{message:string}}
export const Ok = <a>(v:a):Result<a> => ({ok:true, v})
export const Err = (err:{message:string}) => ({ok:false, err})

  
// -- util
export const keys = (o, f:(k:string, v:any) => void) => Object.keys(o||{}).forEach(k => f(k, o[k]))

export const keys2o  = (o,f) => {    
  const out = {}
  Object.keys(o||{}).forEach(k => f(k, o[k], out))   // basically a reducer
  return out
}
export const fmap = <a, b, T extends {[name:string]:a}>(o:T, f:(v:a, key:string) => b ):{[k in keyof T]:b} =>
   keys2o(o, (k,v,o) => (o[k] = f(v,k)) ) as {[k in keyof T]:b} 

export const filterkv = (o,f) => keys2o(o, (k,v,o) => {
  if (f(v)) {
    o[k] = v
  }
})
/** 
 * P ~ powerset type 
 * 
 * https://stackoverflow.com/questions/40510611/typescript-interface-require-one-of-two-properties-to-exist/49725198#49725198
 *
 *  https://github.com/sindresorhus/type-fest
 */
 export type P<T> = 
 { [K in keyof T]:   
   { [L in K]: T[L] } &
   { [L in Exclude<keyof T, K>]?: T[L] }
 }[keyof T] 
// | {}               // <-- empty set to complete the powerset
 
  
export const morph = <a>(o:a, ps:P<a>):a => {
  var out;
  keys(ps, (k,v) => {
    if (o[k] !== v) {
      out = out || {...o}
      out[k] =v
    }
  })
  return out || o
}

