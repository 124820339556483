import { $isElementNode, ElementNode } from "lexical";

export const  visitTree  = (currentNode: ElementNode, visitor, indent, SYMBOLS) => {
    const childNodes = currentNode.getChildren();
    const childNodesLength = childNodes.length;
  
    childNodes.forEach((childNode, i) => {
      visitor(
        childNode,
        indent.concat(
          i === childNodesLength - 1 ? SYMBOLS.isLastChild : SYMBOLS.hasNextSibling,
        )
      );
  
      if ($isElementNode(childNode)) {

        visitTree(
          childNode,
          visitor,
            
          indent.concat(
            i === childNodesLength - 1 ? SYMBOLS.ancestorIsLastChild : SYMBOLS.ancestorHasNextSibling,
          ), SYMBOLS
        );
      }
    });
  }
  
