import { FBUser } from "../../../model/page/user/FBUser"
import { $UI } from "../../../ui/ps/useAppUIPs"
import { $Anyon } from "../useAnyonPs"
import { AnyonFileEff } from "./AnyonFileEff"

export type AnyonEff = {
  file: AnyonFileEff
  
  start: (docId:string, user:FBUser) => void
  
  setDoc: (docId:string, entity:{text:string}) => void
  stop: () => void

}


const _docCache = {}

export const AnyonEff = ($:$Anyon, $ui:$UI):AnyonEff => ({
  file:AnyonFileEff($),

  start: (docId:string, user:FBUser) => {
    // -- set target
    //const cached 
    const cached = _docCache[docId] 
    if (cached ) {
      console.log('... cached')
    }

    $.pm({target:{docId, user}})
    
    // -- 
    $ui.setIsLoading(true)
    $.file.load()


  },

  setDoc: (docId:string, entity:{text:string}) => {
    $.mu.SetDoc(docId, entity.text)
    $.pm({doc:{text:entity.text}})
    $ui.setIsLoading(false)

  },

  stop: () => {

  }



})