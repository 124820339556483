import { Vimeo } from "../../components/video/video/Vimeo"
//import { useMedia } from "../../MediaContextProvider"
//import { useJsonPage } from "../../ps/resource/json/useJsonPage"

export const PVideo = () => {
  
  //const {page} = useJsonPage()
  const {$, pm:media} =  {} as any //  useMedia() // (page)  TODO - this used to depend on page

  
  
  if (!media.ready) {
    return <div>loading</div>
  }

  const {active, vid, poster} = media
  
  return (<div>
    <h1>Video Component</h1>
    {!active  && 
      <div style={{width:poster.width, height:poster.height}}>
        <img alt="video" src={poster.link_with_play_button} onClick={$.activate}/>
      </div>
    }
    {active &&
      <Vimeo poster={poster} id={vid} />
    }
  </div>)

}



