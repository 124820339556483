import { NotePage, Tag, Taggings, Tags, PgTags, NtTags, Chapter, eachPgNote, mergeTaggings, ChPages } from "./BubblePM"
import { mapChapters } from "./note-transforms"


export const includes = (tags:Tags, tag:Tag):boolean => (tags && tags[tag])

export const addTag = (tags:Taggings, did:string, tag:Tag ):Taggings => {
  const old:Tags = tags[did] || {}
  if (includes(old,tag)) {
    return tags          // <-- tag already exists
  }
  const newTags = {...old, [tag]:true}
  return {...tags, [did]:newTags}
}

export const removeTag = (tags:Taggings, did:string, tag:Tag ):Taggings => {
  const old = tags[did] || {}
  if (!includes(old, tag)) {
    return tags          // <-- tag not actually there
  }
  var newTags:Tags =  {...old} //old.filter(v => (v !== tag))
  delete newTags[tag]

  var  out = {...tags}
  if (Object.keys(newTags).length === 0) {
    delete out[did]
  } else { 
    out[did] = newTags
  }
  return out
}


export const hasTag = (tags:Taggings, did, tag):boolean  => {
  return tags[did] && (includes(tags[did], tag))
}

export const toggleTag = (tags:Taggings, did:string, tag:string):Taggings => {
  if (hasTag(tags, did, tag)) {
    return removeTag(tags, did, tag)
  } else {
    return addTag(tags, did, tag)
  }
}






/**
 * Apply tags to a page. 
 *   - tags status is a function of the pmodel, applied dynamicaly. 
 *   - this function iterates over all the notes on a page (recursing through groups)
 *     and sets tags only for nodes present on this page. 
 *    ie. gives   alltags: { id1 :[tag, tag, tag], id2: [...]}  
 *        only when this page containes id1 or id1, and the tags have changed
 *        will the function return a new page object 
 *  
 */
export const applyTagsCh = <c,n>(
    chs:Chapter<c,PgTags, null, n, null>[], 
    taggings0:Taggings
  ):Chapter<ChPages, PgTags, null, n, null>[] => {
  
  const chs1 = mapChapters(chs, {
    
    pmap: (page, pages, i) => {

      var taggings = page.pm.taggings;
      var newTags:Taggings
    
      eachPgNote(page, ({did}) => {
        if (taggings[did] !== taggings0[did]) {    // <-- note strict, not shallow equals
          newTags = newTags || {...taggings}
          if (taggings0[did] != null) {
            newTags[did] = taggings0[did]
          } else {
            delete newTags[did]
          }
        }
      })

      if (newTags) {
        Object.freeze(newTags)
        const tags:Tags = mergeTaggings(newTags)
        return {...page.pm, taggings:newTags, tags}
      } else {
        return page.pm 
      }
    }
  })

  return chs1 as any 
}









 export const applytags = (pages:NotePage<PgTags,any,NtTags,any>[], allTags:Taggings):NotePage<PgTags,any,any,any>[] => {

  return pages.map( (page:NotePage<any,any,any,any>) => {
    
    var tags = page.pm.tags;
    var newTags;
    eachPgNote(page, ({did}) => {
      if (tags[did] !== allTags[did]) {    // <-- note strict, not shallow equals
        newTags = newTags || {...tags}
         if (allTags[did] != null) {
          newTags[did] = allTags[did]
         } else {
           delete newTags[did]
         }
      }
    })

    if (newTags) {
      Object.freeze(newTags)
      return {...page, tags:newTags}
    } else {
      return page   // <-- no new tags, so just return the origional page
    }

  })
}



