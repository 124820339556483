import { Link } from "react-router-dom"
import { usePage } from "../../MediaContextProvider"
//import {NestedList} from "../../ui/sidebar/NestedList"



export const PProject = () => {
	
	const page = usePage() // not necessarily a need for this. Loading should be automatic.
	
	return (<div>
		<h2>Project Page</h2> 
		{ProjectView(page!.data, page!.resource.params)}
	</div>)
}

const ProjectView = (data:any, params) => { 
    
	const {project} = params
	const {index} = data
	const folders = index.folderContent || []
	
	return <div>

		{Menu(folders, project)}


	</div>
} 



export const Menu = (folders, project) => {
	return (<div>
		{folders.map( folder => {
			const {uri, videos, name} = folder
			const folderLink = `/vid/${project}/${toId(uri)}` 
			return (<li key = {folderLink}>
				<Link key={folderLink} to={folderLink}>{name}</Link>   
				<ul>
						{videos.map(vid => {
							var link = `/vid/${project}/${toId(uri)}/${toId(vid.uri)}`;
							return (
								<li key={link}> 
									<Link to={link}> {vid.name}</Link> 
								</li>)
						})}
				</ul>
				</li>)
			})}
	</div>)

}




// TODO - refactor to somewhere
export const toId = uri  => {
    var split = uri.split('/')
    return split[split.length-1]
}
    





    
//const keys   =  (o, fn) => Object.keys(o).forEach(k => (fn(k, o[k])))
//const keymap =  (o, fn) => Object.keys(o).map(k => (fn(k, o[k])))



