import { SyntheticEvent } from "react"
import { shallowEqual } from "react-redux"
import { PageState } from "../../../../../model/page/resource/PageState"
import { FBUser } from "../../../../../model/page/user/FBUser"

import { IBubbleTagServer } from "../../../view/IBubbleTagServer"
import { Note } from "../../doc/BubblePM"
import { NotesFilter } from "../../NotesFilter"
import { BubbleViewPM, createNewPagePM } from "../pm/BubbleViewPM"
import { $Bubbles, DbStatus0 } from "../useBubbleNotes"
import copy from "clipboard-copy";
import { BubbleTagServer } from "../../../view/BubbleTagServer"
import { toggleTag } from "../../doc/noteTagging"
import { getBubbleNotes } from "../backend/getBubbleNotes"
import { BubbleReading } from "../../doc/BubbleModel"
import { $UI } from "../../../../../ui/ps/useAppUIPs"
import { TweakEff } from "./TweakEff"
import { Tweaks } from "../../doc/tweak/BubbleTweak"
import { BubbleFilterEff } from "./BubbleFilterEff"
import { $Page } from "../../../../../model/ps/pagePs/usePage"
import {  toReferenceStr } from "../pm/copyNotes"
import { queryForReading } from "../backend/queryForReading"
import { getBubbleSrc } from "../backend/getBubbleSrc"


const TAG = "1" // <-- hardcoded


export type BubbleEff<n> = {
    tweak:TweakEff
    filter:BubbleFilterEff
    $ui: () => $UI


    // -- ps
    start: ($pg:{$:$Page, page:PageState<any,NotesFilter>}, user:FBUser) => Promise<void>
  
    stop: () => void
  
    tagServer: IBubbleTagServer,
  
    // -- search
      onSearch: (txt:string) => void
      onClearSearch: (e:SyntheticEvent) => void
    
    getFilterPM: () => any
    pageNavAction: (item, nav) => void
  
    toggleSection: (note:Note<n>)   => void
    
    toggleTagFilter: () => void
    setTagfilter: (v:boolean) => void


    // -- database interface - called from tag server
    mergeTags: (tags) => void  // <-- import all tags 
    setTag: (k, v) => void    // <-- set single tag
    


    // --  copy and quote
    copy: (e: SyntheticEvent, note: Note<n>, asQuote?: boolean ) => void
    copyAll: (e:SyntheticEvent) => void
    quote: (e: SyntheticEvent, note: Note<n>, asQuote?: boolean ) => void
    tag: (e: SyntheticEvent|undefined, note:Note<n>, tag:string) => void

  }
  



export const BubbleEff = <n>($:$Bubbles, $ui:$UI):BubbleEff<n> => ({
    filter:BubbleFilterEff($),
    tweak: TweakEff($),
    tagServer:BubbleTagServer($),  // FIX_THIS - using old server
  
    stop: () => {
      $.tagServer.stop()
    },

    $ui: () => $ui,

    start: async ($pg:{$:$Page, page:PageState<any,NotesFilter>}, user:FBUser) => {
 
      $.mu({_ps:{$pg}})

      const {page} = $pg
      console.log(`pg: ${page.rurl}`)
      console.log(`q = "${page.query}" `)
      const  pm = $.pm()

  

      // -- see what's changed
      const {rurl, pm:query} = page 
      const {ch} = query  
      const docChanged = (rurl !== pm.rurl)
      const filterChanged = (ch !== pm.fpm?.ch)
       
      // -- two possibilities of filter changing
      // 1. the use has directly clicked the ui, invoking $.filter.chapter(ch)
    
      if (filterChanged) { 
        //$.filter.chapter(ch)  // <-- this will trigger a page update 
        const ch0 = pm?.fpm?.ch  || ""
        
        console.log(`q: ${ch0} => ${ch}`)
        console.log('x')
       // $.pm({fpm: {...pm.fpm, ch}})
      }
 
      $.pm({rurl, chapterSel:ch})   // <-- always save to set this
      
      if (docChanged) { 
        $.$ui().setIsLoading(true)
        $.stop()
        $.pm({
          // -- TO EXTRACT
          _notes:null, 
          fnotes:null, 
          tagState:{}, 
          tweaks:{}, 
          dbStatus:DbStatus0
        })
      } else if (filterChanged && pm.ok) {

        $.mu.SetChapterFilter(ch) // <-- this requires
      }
      


      if (!pm.ok || docChanged) {
  
        if (pm.isLoading) {
          // throw new Error(" TODO - handle already loading server")
          return
        }
  
        const {src, project} = page.resource.params
        $.pm({
          isLoading:true,
          status: "starting...",
          target:{ src, project, user}
         })
  
        // -- when ther is new information on this page
        const {_notes} = $.mu()
  
        var pmCached:BubbleViewPM = _notes.cache[rurl] 

  
        const needToCreate = true //!pmCached || !pmCached.ok

        if (needToCreate) {
          //if (canCreate) {
            // -- we have the 

          // -- in parallel, load the title 
          
          getBubbleSrc(src).then( docSrc => {
            if (docSrc) {
              const {name} = docSrc
              $.pm({title:name || "???", docSrc, srcRefName:src})
            } else {
              $.pm({title:"????", docSrc, srcRefName:src})
            }

          })
         
  
          const {ok, exists, readingId} = await queryForReading(user.uid, src)
  
          if (!ok || !exists || !readingId) {
            $.pm({
              readingId,
              status:"No bubble notes for this document yet ...",
              isLoading:false
            })
            return
          }
          
          var reading:BubbleReading
          var tweaks:Tweaks
          try {

            ({tweaks, reading} = await getBubbleNotes(readingId));
       
            $.pm({status:"...got data"})

          } catch (e) {
            $.pm({
              isLoading:false,
              status:"failed to load bubble notes: " + e.message
            })
            return
          }

          $.$ui().setIsLoading(false)
          $.pm({isLoading:false, status:"loading tags"})

          //$.pm(newPMBits) 
            
          // -- start tag listener 

          const {tagState}= $.pm()
          const fpm = {...pm.fpm, ch}
          const newPMBits = createNewPagePM(
            {reading, tweaks, tagState},
             readingId, pm.allTags,  pm.cfg, fpm)

          $.pm(newPMBits)

        } // creation

      
      } else {
        $.mu.pm.Put(pmCached)
        
  
        // other possibilities
        // - data on the page might have change  
        // - the query string might have changed
        
      }
      
      if (docChanged) {
        const {readingId} = $.pm()
        $.tagServer.start(rurl, readingId, user.uid )
      }
  
    },
  
    onSearch:  (txt:string) => {
    //	dispatch(navSearch(page, txt, nav))
    },
    
    
    onClearSearch: (e:SyntheticEvent) => {
        //dispatch(navSearch(page, "", nav))
    },
  
    quote: (e: SyntheticEvent, note: Note<n> ) => {
      console.log("TODO = quote")
    },
  
    copyAll:(e:SyntheticEvent) => {
      const txt = toReferenceStr($.pm())
      copy(txt)
    },  
    copy: (e: SyntheticEvent, note: Note<n> ) => {
        
    const {pm}:{pm:BubbleViewPM} = $.mu()


    const {name} = pm

        //let { ref,  } = pm.params
    const ref = "TODO - resolve project ref"
        var txt: string;
        //let { fullURL } = page.status;
    
        if (true) {
            txt = `${note.note.text}  ([${ref}] "${name}")`;
        } else {
      // TODO - handle case when we wish to quote
      
      // 

            // issue: what we have is the underlying resource url  http:server/model/etc
            //   we need to construct the resource uri
            //   which is a buisness logic that belongs in the reducer
    
            //var i = fullURL!.indexOf(`/data${rurl}`); // <-- FIX_THIS - hack. Does not belong in the view
            //var base = fullURL!.substr(0, i);
        
      //var rurl1 = `${rurl}?note=${note.did}`;
            //txt = `${base}${rurl1}`;
        }
    
        copy(txt);
    
/*
        if (!asQuote && e.shiftKey) {
            // navigate to link
            //dispatch(redirect({type:"REF", payload:{ to:txt}} )    ) /// .. {type:"NavTo", rurl:rurl1!})
            //history.push('/home?note=query', { note:note.did });
            //window.location.href = txt
            // -- Q: how to transform this
    
            dispatch({
                type: "REF",
                payload: page.resource.params,
                query: { note: note.did },
            }); // Q: how to add query?
        } else {
            dispatch({
                type: "Notification",
                msg: "copied quote to clipboard",
                details: txt,
            });
        }
    */
    },

    toggleSection: (note:Note<any>)   => {
      // TODO - toggle pmodel via section
    },    
  
  
    getFilterPM:   () => {
      console.log(" TODO - get filteredPM")
    },
  
    pageNavAction:  (item, nav) => {
      console.log("TODO = pageNav")
    },
  
  
    tag: (e: SyntheticEvent|undefined, note:Note<any>, tag:string) => {
  
      const pm = $.pm()
      if (!pm.ok || !pm.allTags.includes(tag)) {
        return // not a globalli=y allowed tag
      }
  
      // -- all of this can go into a mu reducer
      const {did} = note
      const tagState = toggleTag(pm.tagState, did, tag)
  
      // -- publish to server 
      $.tagServer.publishTags(did, tagState[did])
      
      // -- update view state 
      //    Note that this assumes that the database perseistance will succeed
      //    This optimism is justified for the firebase offline model.
      $.mu.UpdateTagging(tagState, pm.fpm)

  
    },
  
    toggleTagFilter: () => {
      const {tagFilter} = $.pm()
      $.setTagfilter(!tagFilter)
    },


    setTagfilter: (v:boolean) => {
  
      const {tagFilter, fpm} = $.pm()

      if (v !== tagFilter) {
        $.mu( (mu, {pm}) => {

        const fpm1 = {...fpm, 
          tag: v ? TAG:null
        } 

        if (pm.ok) {
          mu.UpdateTagging(pm.tagState, fpm1)  
        }
        mu.pm({tagFilter:v})
      
        })
        $.$ui().tagFilter(v)

      } 
    },
      
  
    // -- 1st instance,  server overwrites all tags
    mergeTags: tags => {
      $.mu( (mu,s) => {
    
        const pm:BubbleViewPM = s.pm
        
        mu.UpdateTagging(tags, pm.fpm)
  
    })
  },
  
    setTag: (k:string, v:{[tag:string]:boolean}|null) => $.mu( (mu,s) => {
  
      const pm:BubbleViewPM = s.pm
      const tags0 = pm.tagState
  
      if (shallowEqual(tags0[k], v)) {
        return 
      }
  
      var tags = {...pm.tagState}
  
      if (!v || (Object.keys(v).length === 0)) {
        delete tags[k] 
      } else {
        tags[k] = v
      }
      
      $.mu.UpdateTagging(tags, pm.fpm)
  
    }),
  
  
  
  
  
  })
  