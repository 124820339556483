import { styled } from "@mui/system";



export const STYLES_LEA :any = (theme:any) =>  {
return {
  root: {
    flexGrow: 1,
    margin:'0px'
  },

  flex: {
    flex: 1, 
  },

  paper2: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },

  menuButton: {
    marginLeft: -12, 
    marginRight: 10,
  },
  menuButton2: {
    marginLeft: 12,
    marginRight: -10,
  },
  palette: {
    primary:0x90a4ae,
    slideBG:0x222222
  },
  toolbarMargin: theme.mixins.toolbar,

  tabContainer: {
    marginLeft: "auto" // <--  pushes tabs to the right 
  }, 

  tab: {
    fontFamily: "Raleway", // <-- anything but Roboto. 
    textTransform: "none",  // <-- button defaults to uppercase 
    fontSize: "1rem",  // <-- rem instead of pixels, means same size on all screens, regardless of resolution
    minWidth: 5,    // <-- 
    marginLeft: "0px", // <- px means constant spacing regardless of device. rems would be different for different screens
                      //  px ~ specific spacings. Wheras sizing of elements, text, use responsive rems
    marginRight: "0px" // <- px means constant spacing regardless of device. rems would be different for different screens

    
  },
  tabTitle: {
    fontFamily: "Raleway", // <-- anything by Roboto. 
    textTransform: "none",  // <-- button defaults to uppercase 
    fontSize: "1.5rem",  // <-- rem instead of pixels, means same size on all screens, regardless of resolution
    minWidth: 10,    // <-- 
    marginLeft: "1px" // <- px means constant spacing regardless of device. rems would be different for different screens
                      //  px ~ specific spacings. Wheras sizing of elements, text, use responsive rems
    
  
  },
  form: {
    margin: '0px',
    backgroundColor:'transparent'
  },


  tagItem: {
    color: (theme as any).palette.common.headingPink
  },

  landing: {
    top: '0px',
    minHeight: '100vh',
    backgroundImage: `url(${process.env.PUBLIC_URL}/img/bg.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top'
  },



} 
}

const LOCAL:string = process.env.PUBLIC_URL

export const LandingBG = styled('div')(
    `top: '0px',
     minHeight: '100vh',
     backgroundImage: url(${LOCAL}/img/bg.jpg); 
     backgroundRepeat: 'no-repeat',
     backgroundSize: 'cover',
     backgroundPosition: 'center top'`)


  /*
  background-color: ${theme.palette.background.paper};
  box-shadow: ${theme.shadows[1]};
  border-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.spacing(2)};
  min-width: 300px;
`,
);*/
