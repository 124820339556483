import { parseRefDoc } from "../model/parseRefDoc"
import { RefDocPM } from "../model/RefDoc"

export type ProjectMu<r> = {
    Create: () => r
}
 

export const ProjectMu:ProjectMu<any> = ({

  Create: () => (mu,s) => {
    const txt = "Empty Project ..."

    const {doc:ref} = parseRefDoc([txt])

    const doc:RefDocPM = {
      ref,
      srcIds:{},
      validated:true,
      resolved:true,
      txt
    }
    // TODO - abstract 
    mu.pm({doc, loading:false, loaded:true, exists:true, unsaved:false})
  }
})