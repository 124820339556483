import { $Page } from "./usePage"
const queryString = require('query-string');

export type NavEff = {
  to: (rurl:string) => void

  login: () => void
  home: () =>  void
  
  //  CAT_DOCS:(id:string) => navTo(`/cat-docs/${id}`),
  //  CAT_DOCS_VIEW: id => navTo(`/cat-docs-view/${id}`)
  setQuery: (q:any) => void

}


export const NavEff = ($:$Page):NavEff => ({
  to: rurl => {
    const {navigate} = $.mu()._ps
    navigate(rurl) 
  },
  login: () => $.nav.to("login"),

  home: () =>  {
    $.nav.to("/") //({type:"HOME"}),
  },
  //  CAT_DOCS:(id:string) => navTo(`/cat-docs/${id}`),
  //  CAT_DOCS_VIEW: id => navTo(`/cat-docs-view/${id}`)

  setQuery: (q:any) => {
    const {navigate, location} = $.mu._ps()
    const search = q ? queryString.stringify(q) : ""
    console.log(`setting query ${search}`)
    if (location.search !== search) {
      navigate(location.pathname + (search && search.length > 0 ? "?" + search : ""))
    }
  }
})