import { P } from "../../../../../anyonic/dsl/dsl-api"
import { PageState } from "../../../../../model/page/resource/PageState"
import { FBUser } from "../../../../../model/page/user/FBUser"
import { $Page } from "../../../../../model/ps/pagePs/usePage"
import { FilterPM } from "../../../filter/FilterStatePM"
import { BubbleReading } from "../../doc/BubbleModel"
import { Chapter, ChPages, NoteAction, PgActions, PgTags, Tag, Taggings } from "../../doc/BubblePM"
import { initTags } from "../../doc/note-transforms"
import { Tweaks } from "../../doc/tweak/BubbleTweak"
import { createChapters } from "../../doc/tweak/ChapterTweak"
import { NotesFilter } from "../../NotesFilter"
import { BubbleFilterPModel } from "../../tag/tag-filter" 
import { ChaptersPM } from "../eff/BubbleFilterEff"
import { BubbleViewCfg, DbStatus } from "../useBubbleNotes"


export type BubblePs = {
  $pg?:{$:$Page, pg:PageState<null,NotesFilter>}
}

export type BubbleViewPM = {
	ok:boolean
  cfg:BubbleViewCfg,
  
  isLoading?:boolean, 
  status:string,
  rurl?:string

  target?:{
    user:FBUser
    src:string // <-- src doc
    project:string
  }

  // -- depersisted data
  readingId?:string,  // <-- determined by query on documet + user (maybe projects)
  reading?:BubbleReading  //
  tagState?:Taggings  // <-- serviced by the 
  tweaks:Tweaks,
  
  dbstatus : {
    tagsLoaded:boolean
    dataLoaded:boolean
    tweaksLoaded:boolean
  }

  // -- pmodel
  _notes:Chapter<any,PgTags&PgActions,any,any, any>[]

  // -- tag filter
  tagFilter:boolean,
  fpm:BubbleFilterPModel,  // <-- filer or dont' filter on tag
  fnotes:Chapter<any,PgTags&PgActions,any,any, any>[]  // <-- with tag filtering applied

  // -- chapter filter
  chapterSel?:string,
  chaptersPM:ChaptersPM,


  title?:string // <-- loaded from the source document
  srcDoc?:any      // BubbleSrc
  srcRefName?:string   // <-- name of the src ie [Cutts07]
  docSrc?:any  // <-- c.f. .doc()

  allTags:Tag[],


  // -- tweak persistence
  unsavedTweaks?:boolean,
  tweaksSaving?:boolean,

  // -- ui filter state
  filter:any
	filterPM:FilterPM[]

  dbStatus?:DbStatus

  name:string
}

export type BubblePagePM = {
  allTags:string[]
  pg:string
}

const ALL_TAGS = ["1"]

export type BubbleDocState  = {
  // -- core persisted data 
  reading:BubbleReading 
  tweaks:Tweaks
  tagState:Taggings

  // -- reafactor to abstact context  
  // readingId
  // sourceId
  // userId 
}


type x = any
export const createNewPagePM = (
    
    // -- state from db
    docState:BubbleDocState,
    readingId, // 
    allTags:Tag[],
    cfg:BubbleViewCfg,
    fpm:BubbleFilterPModel
  ):P<BubbleViewPM> => {
  
    const {reading, tweaks, tagState} = docState
    // const { ref, name, notesAuth } = data;
  
    //const notes =  data //toPagePM(data.notes, tagNames )   // <-- this fails .. probably attempting to mutate somehing in the origional ds

    // -- Note: this is really "active tags, and injectable into the pmodel striaghtforwardly enough"
    allTags = ALL_TAGS // TODO - generalize to more than one tag, inject elsewehere
    

    const actions:NoteAction[]|undefined = cfg.editTweaks 
        ? [
            {label:"#Ch", action:"section"}
            // merge
            // label  
          ] :  undefined 
  

    
    const notes:Chapter<ChPages,PgTags&PgActions,x,x,x>[] = createChapters(
      reading, tweaks,
      initTags(tagState, allTags, actions)
    )
  

    const chaptersPM0:ChaptersPM = notes.map((ch:Chapter<ChPages,x,x,x,x>)  => ({
        id:ch.pm.n.toString(),
        label: ch.pm.n.toString()  // TODO - add labels etc
      })) 



    const chaptersPM = chaptersPM0.length > 1 ? [...chaptersPM0, {id:"*", label:"all"}] : chaptersPM0



    const filter = {} 
    const filterPM = []
  
    return {
      ok:true, 
      isLoading:false,
      status:"...waiting for tags",
      allTags,

      // -- state from db
      
      readingId,   // <-- spec
      
      reading,     
      tweaks,
      tagState,  

      chaptersPM,

      fpm,
  
      tagFilter:false,
     
      _notes:notes, // <-- unfiltered version
      fnotes:notes, 

      name:"TODO - add document name",  // -- needs to be editable in the database

      filter,
      filterPM,
    }
  }
  

  
  