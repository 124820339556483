//import { LocationStatus } from "../util/LocationStatus"s
//import { Chamber } from "../../bubbleChamber/Draw";
//import { MsgChannels } from "../../media/bubble-chat/view/MsgChannels";
//import { ImageUpload } from "../../media/img/upload/ImgUpload";
//import { ChannelsEdit } from "../../media/channels/ChannelsEdit";
import { Slide } from "../../media/notes/view/typeography/Slide";
//import { CJS } from "../../to-extract/create-ts/easeljs";
//import { AudioVis } from              "../../unit/candlepaint/__stories/AudioVisualizeTimelineTest"
//import { CandlePaintingMedia } from "../../unit/candlepaint/__stories/III_CandlePaintingMedia";
//import { FigureGuideEditor } from "../../unit/candlepaint/auth/guide/__stories/II_FigureGuideStory";
//import { LineStory } from "../../unit/candlepaint/auth/guide/__stories/I_LineStory";
//import { MagicAnimalCandlePainter } from "../../unit/candlepaint/MagicAnimalCandlePainter";
//import { TestStaffPoc } from "../../unit/create-ts/StaffPoc";
//import { Substrate } from "../../unit/substrate/Substrate";

//    <Chamber/>
//    <Editor/>
 //   <MsgChannels/>
 //<Substrate/>
 //<ChannelsEdit/>
 //
  //<MagicAnimalCandlePainter/>

//  
//<BasicTimeline/>

  ///<TestStaffPoc/>
 // <BasicBoxAuth/>
// <DragBoxApp3/>
//<BasicTimeline/>
  // 
  // <FigureGuideEditor/>
    // <CandlePaintingMedia/>
  //  <LineStory/>
export const Home = () => {
  //CJS.init(window);
/*

    /   <AudioVis/> }
    <CandlePaintingMedia/>
    
    {/*<FigureGuideEditor/>}
  */
  return (<Slide supTitle="HOME" title="editing channels ..">
    {msg()}
  </Slide>) 

}



const msg = () => (<>

<p>
    Hi Lea ... click on the "Project references tab" to see the list of references. 
  I've added some bubble notes for references around concerns of the epistemic (including epistemic justice). 

    </p>


  <p> 
   Bubble notes should work. and the "copy all"/ "copy all tagged" button 
   will copy all bubble notes / all selected bubble notes to the clip board. 
</p>

<p> 
  when looking at bubble notes, click on the purple house to get back the the reference list. 
  (The animations are a bit wonky ... work in progress :) )
 </p>
 <p>
 Speak soon!
 </p>

  <p>
    i
  </p>
</>)