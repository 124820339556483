import { renderNote } from "./BubbleLeaf";

import { RefLink } from "./type/RefLink";
import { Box, Divider } from "@mui/material";
import { usePage$ } from "../../../../MediaContextProvider";
import { $Bubbles, BubbleViewCfg, useBubbleNotesPs } from "./useBubbleNotes";
import { BubbleViewPM } from "./pm/BubbleViewPM";
import { Slide } from "../../view/typeography/Slide";
import { LINK, RED, YELLOW } from "../../../../ui/theme/theme-lea";
import { Chapter, ChPages, NotePage, PgTags } from "../doc/BubblePM";
import { Link } from "react-router-dom";
import { MLink } from "../../refs/RefGroupView";
import { ChaptersPM } from "./eff/BubbleFilterEff";
import { styled } from "@mui/system";
 
//import { navAction, navSearch, navSection }from "./navAction";

// -- TODO - abstract as higher order component


const readCfg:BubbleViewCfg = {
  editTweaks:false
}


export const BubbleView = ({cfg}:{cfg?:(BubbleViewCfg)}) => {
  const $pg = usePage$()
  
  const {$, pm}:{$:$Bubbles, pm:BubbleViewPM} = useBubbleNotesPs($pg, cfg||readCfg)
  return (<Slide supTitle={renderSupTitle(pm,$)} title={renderTitle($,pm)}>
    {renderPm($,pm)}
  </Slide>)
  
}


const renderSupTitle = (pm:BubbleViewPM, $:$Bubbles) => {

  return <div>
    BUBBLE NOTES
    <ChapterSelector pm={pm.chaptersPM} sel={pm.fpm.ch} $={$}/>
    {" "} filter:
    {pm.fpm.section 
      ? <ChSelected key="_sel1" onClick={$.filter.section}> 
          section
        </ChSelected> 
      : <ChNotSelected key="_sel2" onClick={$.filter.section}> 
          section
        </ChNotSelected>
    }

    {" "} action:
      <ChNotSelected key="copy" onClick={$.copyAll}>    
        copy all {pm.fpm.tag ? "tagged" : ""}
      </ChNotSelected>
    {" "}


      
    <EditLinks pm={pm} $={$}/>
   </div>
}


const EditLinks = ({pm,$}:{pm:BubbleViewPM, $:$Bubbles}) => {
  const user = pm.target?.user?.name
  
  if (!pm.target || (user !== "ian" &&  user !== "lea")) {
    return null
  }

  const {src, project} = pm?.target
  const editLink = `/BubbleNotes/${src}/${project}/edit`
  const tweakLink = `/BubbleNotes/${src}/${project}/tweak`

  return (<>

    <Link to={editLink}>
      {"  "}
      <MLink>
        edit
      </MLink>
     </Link>

     <Link to={tweakLink}>
      {"  "}
      <MLink>
        tweak
      </MLink>
     </Link>

    {pm.tweaksSaving  
      ?  (<MLink>
            saving tweaks
         </MLink>)
      :  (pm.unsavedTweaks) 
         ?  <MLink onClick={$.tweak.save}>
             save tweaks
           </MLink>  
          : null
      }
      </>)

}


const renderPm = ($:$Bubbles, pm:BubbleViewPM) => {
  if (pm.isLoading) {
    return (<div>... loading </div>)
  } 
  if (!pm.ok) {
    return (<div>{pm.status} </div>)
  }

  return (<>
    {pm.fnotes.map((ch, i) => <ChapterView key={ch.pm.id} $={$} chapter={ch}/>)}
  </>)

}
//  <NoteLink onClick={$.copyDoc}/>

const ChapterView = ({chapter, $}:{chapter:Chapter<ChPages, PgTags, any, any, any>, $:$Bubbles}) => {
  return (<>
   <YellowDivider label={chapter.pm.label} pl={1}/>
    {chapter.pages.map((page, i) => <NotesPage key={i} $={$} page={page}/>)}
  </>)
}
    
export const YellowDivider = ({label, pl}:{label:string, pl?:number}) =>  
    <Divider  sx={{
      color: YELLOW,
      pl: isNaN(pl) ? pl : 20  , pr:35,pb:2, pt:5,
      "&::before": {
        borderTop: "thin solid #888888"
      },
      "&::after": {
        borderTop: "thin solid #888888"
      }
    }} textAlign="left" light={true}>
   {label}
    </Divider>


const renderTitle = ($:$Bubbles, pm:BubbleViewPM) => (
  <NotesMainTitle pm={pm}>
    <Box sx={{ml:'15px', color:(pm.fpm?.tag ? RED : LINK), fontSize:26}} onClick={$.toggleTagFilter}>
      {pm.fpm?.tag ? "*" : "#"}
    </Box>
  </NotesMainTitle>)


const NotesMainTitle = ({pm,children}:{pm:BubbleViewPM, children:any}) => (
  <Box sx={{display: 'inline-flex'}}>
    {pm.title || "..."} {" "} [<RefLink refId={pm.srcRefName || ""}link={null}/>]
    {children}
  </Box>)

const PgNo = ({pg}) => (
  <Box sx={{
      alignSelf:"flex-start",
      color: 'slide.RED',
      fontSize: 25,
      flexGrow:0,
      flexShrink:0,
      flexBasis:'40px',
      pt:"4px",
      width:'80px'

    }}>
      {pg}
  </Box>)

// -- encodes application state of node filtering

 





/*
   <span>
        <FilterUI pm={pm} $={$} nav={nav} />

        <div className={"bubble-breadcrumbs-bar"}>
          <Link to={nav("/")}>projects</Link> {" > "}
          <Link
            to={nav(`/project/${pm.params.user}/${pm.params.pname}`)}>
            {pm.name}
          </Link>{" "}  
          {" > "}
          {filterFn ? (
            <>
              <Link to={nav(pm.rurl)}>[{pm.params.ref}]</Link>{" "}
              {" > "}
              {pm.filter!.note! ? " ~selection " : " ~filtered"}
            </>
          ) : (
            `[${params.ref}]`
          )}
        </div>
        <div style={{height:120}}>


          <img style={{width:75, height:100,  marginBottom:0,
              marginRight:10,
              float:"left", borderRadius:"5" }} 
              src={`/data/project/${params.user}/${params.pname}/__img_s/${params.ref}.png`}/>




          <h1 style={{textAlign:"center"}}>  
          {params.pname} 
          {"  "}
          <span style={{fontSize:15}}>[{params.ref}]</span> 
          </h1>

        </div>
        {notes.map((note, i) => renderPage(note, $, i  ))  /* cpTxt, navSect,  isSec, isSel, i)) 
        </span> 
        */



// -- TODO  - memoize
const NotesPage = ({page,$}:{page:NotePage<PgTags, any, any,any>, $:$Bubbles}) => {
  let {  nodes, pm } = page;

  return (<Box sx={{ 
    display: 'flex',
    width: "100%",
    pb:8,
    mb:'5px'
  }}> 

    <PgNo pg={pm.pg } />

    <Box sx={{ 
        flexGrow:1,  // <-- 
        left: 0,    // <-- 
        ml:"20px"
      }}>
     
     {nodes.map((note, i) => renderNote(pm, note, $, i))}

    </Box>

  </Box>)
}



//<SearchBar txt={filter.txt!} onSearch={onSearch}  onClear={onClearSearch}/>
//</div>



const ChapterSelector = ( {pm, sel, $}:{pm:ChaptersPM,sel:string|undefined, $:$Bubbles}) => {

  //const notSel = {color:YELLOW}
  //const isSel = {color:RED}
  console.log(`view: ch = ${sel}`)
  
  if (pm.length <= 1) {
    return null
  }
  return (<>

    {pm.map(({id,label }) => {
      return (id === (sel || "*")) 
        ? <ChSelected key={id} onClick={_ => $.filter.chapter(id)}> 
            {label}
          </ChSelected> 
        : <ChNotSelected key={id} onClick={_ => $.filter.chapter(id)}> 
          {label}
        </ChNotSelected>
    })}
    </>)
   
}



export const ChSelected = styled('span')(({theme}) => `
  color: ${theme.palette.slide.YELLOW};
  text-decoration: none;
  padding-left: 5px;
  font-family: ${theme.palette.slide.font1};
  font-size:20px;
  margin-left: 7px;
  &:hover {
    color: ${theme.palette.slide.RED};
  }`) 

export const ChNotSelected = styled('span')(({theme}) => `
  color: ${theme.palette.slide.LINK};
  text-decoration: none;
  padding-left: 5px;
  font-family: ${theme.palette.slide.font1};
  font-size:20px;
  margin-left: 7px;
  &:hover {
    color: ${theme.palette.slide.RED};
    text-decoration-color: ${theme.palette.slide.LINK};
  };
  &:active {
    color: ${theme.palette.slide.YELLOW};
  }`) 