import { fbPs } from "../../../../../firebase/fbPs"
import { ReadingResolution } from "../../../import/BubbleNoteServerEff"

/**
 * 
 *  
 */
export const queryForReading = async (user:string, src:string):Promise<ReadingResolution> => {
  const fbs = fbPs()

  const q = fbs.bubbleNotesQuery(user, src)

  return q.get().then(qs => {
    var all:{[reading:string]:any} = {}
    qs.forEach((doc) => {
      const k = doc.id
      var data = doc.data()
      all[k] = data
    })

    const readings = Object.keys(all) 
    const numReadings = readings.length
    if (numReadings === 1) {
      const k = readings[0]  // <-- TODO - hardcoding a single reading here, support multiple readings
      const data = all[k]
      if (data.src !== src) {// || data.owner !== user) {
        throw new Error("data integrity ...")
      }
      return {ok:true, exists:true, readingId:k}
    } else if (numReadings > 2) {
      throw new Error("TODO - handle multiple readings") //   <--  FIX_THIS support for multiple readings
    } else if (numReadings === 0) {
      return {ok:true, exists:false}
    }
  })
}