const COLOURS = {
    FFFFFF: "white",
    "99B5FF": "blue",
    // .ref 
    FFEA99: "yellow", // => .ref
    
    // shorhand for merge to previous in group 
    FF99FF: "pink",
    //FF9E99: "red",
    
    // -- .sec 
    "00A595": "green", 
    "FF9E99": "green",  // <-- this is actuall pinkish, but used in the desktop 

    // -- .sec2
    "99FFF4": ".sec2",  //
    "000000": "clear", 
  }

  export const SEC = ".sec"
  export const SEC2 = ".sec2"
  const HI = ".hi"
  export const REF = ".ref"

  export const colMap = (col0:string):string => {
    console.log("colMap", col0)

    var col = COLOURS[col0]
    if (!col || col === "white") {
        return undefined
    }
    if (col === 'pink')  {
        return undefined
    }
    if (col === "green") {
        return SEC
    } 
    if (col === SEC2) {
        return SEC2   // <-- FIX_THIS: breaks colour semantics
                      //    (remove hardcoding)
    }
    if (col === "yellow") {
        return REF
    }
    if (col === "blue") {
        return HI
    }
   
    return "#" + col
  }

