
import { uiState0 } from "../model/uiReducer"
import { createStorePs$, SStore } from "../../anyonic/dsl/createStorePs$"
import { MuPM } from "../../ps/firebase-ps/PresenceMu"
import { UIState } from "../model/UIState"


export type $UI = {
  mu:any 
  pm:MuPM<UIState>
  run:any
} & UIEff


type UIEff = {
  toggleMenu: () => void
  toggleDrawer: () => void
  toggleShowBase: () => void
  quote: (o:any) => void
  openDrawer: (v:boolean) => void

  
  showTagFilter: (v:boolean) => void
  tagFilter: (v:boolean) => void

  setIsLoading: (v:boolean) => void
  setIsSaving: (v:boolean) => void
}

export const createAppUIPs = (store:SStore<any>):$UI => {
  
  return createStorePs$(
    store,
    {

      pm: uiState0,

      PM: {
        _ps : {
          TODO: "reinstate { '<CatDoc[]|':firebase.db.collection('CatDoc')})"
        }
      },

      mu:{
        pm:UIMu
      },

      $: $ => ({
        pm:$.mu.pm,
        ...UIEff($)
      })
    

    })

    
}



const UIEff = ($:$UI):UIEff => ({

  setIsLoading: (isLoading:boolean) => {
    $.pm({isLoading})
  },
  setIsSaving: (v) => $.setIsLoading(v),


  toggleMenu: () =>  $.mu.pm.TOGGLE_DRAWER(),

  toggleDrawer:  () => $.run(function*($$) {
    //const model = $.mu()
    const pm = yield $$.UIState
    yield $$.SET_MENU(!pm.menuOpen)
  }),

  toggleShowBase: () => $.mu.pm.SET_SHOW_BASE_DOC(),

  openDrawer: open => $.mu.pm.SET_DRAWER(open),

  quote:o => $.run(function*($$){
    const {docId, tag, sel} = o
    // -- FIX_THIS - architectural hack. Need a mechanism to route the action to the 
    //    appropriate process context
    yield $$.await(() => o.$.run(function*($$) {
        yield $$.quote(docId, tag, sel)  // FIX_THIS --- very hacky
      }))
  }),


  showTagFilter: (showTagfilter:boolean) => {
    $.mu.pm({showTagfilter})
  },
  tagFilter: (tagFilter:boolean) => {
    $.mu.pm({tagFilter})
  }
})




export type UIMu<r> = {
  SET_MENU: (open:boolean) => r
  TOGGLE_DRAWER: () => r
  SET_DRAWER: (drawerOpen:boolean) => r
  SET_REQ_SAVE: (requireSave:boolean) => r

  SET_SAVE_IN_PROGRESS: (saveInProgress:boolean) => r

  SET_SHOW_BASE_DOC: () => r
  SET_CAN_QUOTE: (v:boolean) => r
  SET_MSG: (msg:any) => r
}

export const UIMu:(($:$UI) => UIMu<any>) = $ => ({
  SET_MENU: open => mu => mu({open}),
  SET_DRAWER: drawerOpen =>  mu => mu({drawerOpen}),
  TOGGLE_DRAWER: () => mu => mu.Toggle({drawerOpen:"yep"}),
  SET_REQ_SAVE: requireSave => mu => mu({requireSave}),

  SET_SAVE_IN_PROGRESS: saveInProgress => mu => mu({saveInProgress}),

  SET_SHOW_BASE_DOC: () => mu => mu.Toggle({showBaseDoc:"yep"}),

  SET_CAN_QUOTE:(canQuote) => mu => mu({canQuote}),
  SET_MSG: msg => mu => mu({msg}),

})