import {firebaseConfig} from './config';
import firebase from 'firebase/compat/app';

import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import "firebase/compat/storage";

  
export class Firebase {
  
  auth:any
  db:any
  TIMESTAMP:any
  database:any
  storage:any

  constructor() {
    firebase.initializeApp(firebaseConfig)

    // https://firebase.google.com/docs/auth/web/auth-state-persistence

    firebase.firestore().enablePersistence({synchronizeTabs:true})
      .then(v => {
          console.log('offline enabled')
      })
      .catch((err) => {
      if (err.code === 'failed-precondition') {
          throw new Error("failed b/c of multiple tabs")  
        // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          throw err
      }
      firebase.firestore().settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED

      })



  });
    this.auth = firebase.auth()
    this.db = firebase.firestore()
    this.TIMESTAMP = () => firebase.firestore.FieldValue.serverTimestamp() 
    this.database = firebase.database()
    this.storage = firebase.storage()

  }

  async register(name, email, password) {
    const newUser = await this.auth.createUserWithEmailAndPassword(email, password)

    await newUser.user.updateProfile({
      displayName: name,
      photoURL: `/public/avatars/${name}`
    })
  }


  async login(email, password) {
    var result =  await this.auth.signInWithEmailAndPassword(email, password)
    return result 
  }

  async logout() {
    await this.auth.signOut()
  }

  async resetPassword(email) {
    await this.auth.sendPasswordResetEmail(email)
  }

}

// -- singleton firebase instance 
var fb = new Firebase()


export default fb 


