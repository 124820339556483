import { Tab, Tabs, keyframes, styled } from "@mui/material";
import { Link, matchPath, useLocation } from "react-router-dom";
import CottageIcon from '@mui/icons-material/Cottage';
import { Box } from "@mui/system";

//https://animista.net/play/entrances/slide-in-elliptic/slide-in-elliptic-left-fwd

const animateIn = keyframes`
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
            transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
            transform-origin: 1800px 50%;
    opacity: 1;
  }
`;

const animateOut = keyframes`
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
            transform-origin: 1800px 50%;
    opacity: 1;
  }  

  100% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
            transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 1;
  }

`;




const TabCompIn = styled('div')(() => ({
  animation: `${animateIn} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
}))


const TabCompOut = styled('div')(() => ({
  animation: `${animateOut} 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
}))

export function MyTabs(currentTab) {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  
 // BubbleNotes:"/BubbleNotes/:src/:project/view", 
 // BubbleNotesEdit:"/BubbleNotes/:src/:project/edit",
  
 // ProjectView:"/Project/:project/view", 
 // ProjectEdit:"/Project/:project/edit",
  

/*
    '/dproject',
    '/dproject/:user/:pname', 

    '/BubbleNotes/:src/:project',
    '/Project/:project/edit',

    '/dproject/:user/:pname/:ref', 
    "/channels/:gruop", 
    "/anyon/:user/:doc/:ver", 
    "/draw/:pic"*/


  //      <Tab label="Thesis" value="/dproject/:user/:pname" to="/dproject/lea/thesis" component={Link} />
//   <Tab label="Notes" value="/dproject/:user/:pname/:ref" to="/dproject/lea/thesis/Fricker07" component={Link} />
//      <Tab label="Doc" value="/anyon/:user/:doc/:ver" to="/anyon/bob/doc1/etc" component={Link} />

  

//      <Tab label="Channels" value="/channels/:group" to="/channels/home" component={Link} />
  

  return currentTab 
      ? 
        <TabCompIn>
          {renderTabs(currentTab)}
        </TabCompIn>
      : 
        <>

          <TabCompOut>
            {renderTabs(currentTab)}
          </TabCompOut>
          <TabCompIn>
            < IconLink/>
          </TabCompIn>
        
      
     
        </>
        
  
}

const IconLink = () => (
    <Box sx={{pt:'15px', pl:'15px', pr:'15px'}}>
      <Link to="/Project/heaney/view" >
        <CottageIcon/>
      </Link>
    </Box>)
 

const renderTabs = ( currentTab) => (
  <Tabs value={currentTab}>
    {TABS.map((v, i) => 
     <Tab key={i} label={LABELS[i]} value={v}
        to={TO[i]} component={Link}/>
        //(<Tab label={LABELS[i]} value={v} component={ Link}/>)
      )}

    </Tabs>)
   /*
    <Tab label="Project" value={'/Project/:project/view'}
                           to={'/Project/heaney/view'} component={Link}/>

    <Tab label="Notes" value={'/BubbleNotes/:src/:project/view'}
                        to={'/BubbleNotes/Mills97/heaney/view'} component={Link}/>

    <Tab label="logout" value="/logout" to="/logout" component={Link} />
    <Tab label="presence" value="/presence" to="/presence" component={Link} />
    <Tab label="etc" value="/Project/:project/edit" to="/Project/heaney/edit" component={Link} />

  </Tabs>)*/



export function useRouteMatch(patterns: readonly string[]) {
  const { pathname /*, search */} = useLocation();
  //console.log("location: " + pathname)
  //console.log("    q =  " + search)
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}


const NAV = [
  ["Home",         '/',                                 '/'                     ],
  ["Project References",    '/Project/:project/view',           '/Project/heaney/view'  ],
  //["Project2",    '/Project/:project/view',           '/Project/thesis/view'  ],

  
  //["Notes",      '/BubbleNotes/:src/:project/view',  '/BubbleNotes/Mills97/heaney/view'],
  
  
  
  
 // ["etc",        '/Project/:project/edit',           '/Project/heaney/edit']
 // ["My Bubble Notes", "/MyNotes/:project", "/MyNotes/heaney"],
  
 // ["Edit Thesis", '/Project/:project/edit', '/Project/thesis/edit' ],
 ["Presence",   '/presence',                        '/presence'],
 ["logout",     '/logout',                          '/logout'],
 ["Edit Referencess", '/Project/:project/edit', '/Project/heaney/edit' ],

 ["Old Thesis (not working)",       '/dproject/:user/:pname', '/dproject/lea/thesis']

 
]










const LABELS = NAV.map(vs => vs[0])
export const TABS = NAV.map(vs => vs[1])
const TO = NAV.map(vs => vs[2])






/*
'/dproject',
'/dproject/:user/:pname', 

'/BubbleNotes/:src/:project',
'/Project/:project/edit',

'/dproject/:user/:pname/:ref', 
"/channels/:gruop", 
"/anyon/:user/:doc/:ver", 
"/draw/:pic"*/