import { usePage } from "../../../MediaContextProvider"
import { useAnyonPs } from "../useAnyonPs"
import { DocStateUI } from "./DocStateUI"

export const Anyon = () => {
    const page = usePage()
    const {anyon} = page.resource.params
    const {$, pm} = useAnyonPs(anyon)
    
    if (!pm.status.ok) {
        return <DocStateUI $={$} ds={pm.status}/>
    }
    return <>
        --- got document text ---
        {pm.doc.text}
    </>
}

export const Status = ({})