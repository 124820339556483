import { Taggings } from "../model/doc/BubblePM"
import { applyTagsCh } from "../model/doc/noteTagging"
import { BubbleTweak, Tweaks } from "../model/doc/tweak/BubbleTweak"
import { BubbleFilterPModel, filterChapters } from "../model/tag/tag-filter"
import { BubbleViewPM, createNewPagePM } from "../model/view/pm/BubbleViewPM"
 
export type BubbleMu<r> = {
  SetPM: (pm:BubbleViewPM) => r

  UpdateTagging: (tagState:Taggings, fpm:BubbleFilterPModel ) => r
  
  AddTweak: (did:string, tweak?:BubbleTweak, del?:string ) => r
  
  SetChapterFilter: (id?:string) => r
  SetSectionFilter: (section:boolean) => r
}

export const BubbleMu:BubbleMu<any> = {
   
  SetPM: pm => mu => {
    mu._notes.Insert(pm.rurl, pm)
    mu.pm.Set(pm)
  },

  UpdateTagging: (tagState:Taggings, fpm:BubbleFilterPModel) => (mu, {pm}) => {
    if (!pm._notes) {
      console.log("got tags before notes")
      return 
    } 
    // --  agregates tags and notes into the notes pmodel
    const notes = applyTagsCh(pm._notes, tagState)
    
    // -- reapply filtering (ie when we're only showing tagged entries) 
    const fnotes = filterChapters(notes, fpm)

    const pm1:BubbleViewPM = {...pm, fnotes, _notes:notes, tagState, fpm}

    // -- set pm and cache
    mu.SetPM(pm1)
  }, 


  AddTweak: (did:string, tweak?:BubbleTweak, del?:string, ) => (mu, {pm}:{pm:BubbleViewPM}) => {

    var newTweaks 

    if (tweak) {
      newTweaks = addTweak(pm.tweaks, tweak,did)
    } else if (del) {
      var tweak1 = {...pm.tweaks[did]}
      delete tweak1[del]
      newTweaks = {...pm.tweaks, [did]:tweak1} 
    } else {
      return
    }


    const newPMBits = createNewPagePM({
      reading:pm.reading,
      tagState:pm.tagState,
      tweaks:newTweaks
    }, pm.readingId, pm.allTags,pm.cfg, pm.fpm)
  
    // -- FIX_THIS - wrong lifecycle    mu.UpdateTagging(pm.allTags, pm.fpm)
    mu.pm(newPMBits)
    mu.pm({unsavedTweaks:true})
    
  }, 
  


  // -- filter

  SetChapterFilter: (id?:string) => (mu, {pm}:{pm:BubbleViewPM}) => {

    const fmp = {...pm.fpm, ch:id}
    mu.UpdateTagging(pm.tagState, fmp)
    mu.pm({chapterSel:id})
    
  },

  SetSectionFilter: (section:boolean) => (mu, {pm}:{pm:BubbleViewPM}) => {

    const fpm = {...pm.fpm, section}
    mu.UpdateTagging(pm.tagState, fpm)  // FIS_TJHIS

  }

  
}

const addTweak = (tweaks:Tweaks, tweak:BubbleTweak, did:string):Tweaks  => {
  var newTweaks = tweaks[did] || {}
  newTweaks = {...newTweaks, ...tweak}

  return {...tweaks, [did]:newTweaks}

}
