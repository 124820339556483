

import { FBUser } from "../../../model/page/user/FBUser"
import { queryForReading } from "../model/view/backend/queryForReading"
import { saveBubbleNotesAndTweaks } from "../model/view/backend/saveBubbleNotesAndTweaks"

import { $Upload } from "./useBubbleNotesImport"

export type BubbleNoteServerEff = {
  save: (force?:boolean) => Promise<void>
  start: (targetId:string, srcId:string, user:FBUser) => void
  getReading: (user:string, src:string) => Promise<ReadingResolution>
} 

export const BubbleNoteServerEff = ($:$Upload):BubbleNoteServerEff => ({
  
  getReading: (user:string, src:string)  => queryForReading(user, src),

  start: (projectId:string, srcId:string, user:FBUser) => {
    $.pm({target:{  projectId, srcId, user }})
  },


  save: async (force?:boolean) => {
    const {reading, target, unsavedTweak, tweaks} = $.pm()
    const {srcId, projectId, user} = target
    
    // -- 

    const {ok, exists, readingId} = await $.server.getReading(user.uid, srcId)
    console.log("readingId: ", readingId)
    if (!ok) {
      $.pm({saveErr:"possible there are more readings in the query than we can handle"})
    }

    if (!force) {
      if (exists && !force) {
        $.pm({couldForceSave:true, saveErr:"bubble notes already exist and must be appended, not overwritten"})
        return
      } 
    }
    
    // -- create entities

    try {
      await saveBubbleNotesAndTweaks(readingId, unsavedTweak, reading, tweaks, force, user, projectId, srcId)
      $.pm({saveOK:true, unsavedTweak:false, saveErr:null})         

    } catch (e) {
      $.pm({saveErr:"failed to persist (possibly offline?)" + e.message})

    }

  }
})

   



export type ReadingResolution = {
  ok:boolean
  exists:boolean 
  readingId?:string
}

