const axios = require("axios");

const CancelToken = axios.CancelToken;


// string -> Promise object
export const loadJsonP = (url: string) => async () => {
  const apiData = await axios.get(url);
  console.log(apiData);
  return apiData;
};



export const axiosGet =  url =>  (Ok, Err) => {

    const source = CancelToken.source();    

    axios.get(url, {
        cancelToken: source.token, 
        onDownloadProgress: function (evt) {
          // Do whatever you want with the native progress event
          //const total = parseFloat(evt.currentTarget.responseHeaders['Content-Length'])
          const total = evt.srcElement.getResponseHeader('content-length')
          const {loaded} = evt
          const pct = toPct(loaded, total)
          console.log(`progress ${loaded} (${pct}) `)
          
    }}).then(v => {
        Ok(v.data)
    }).catch(e => {
        if (!axios.isCancel(e)) {    // <-- don't treat cancellation as an error
          var err = e.toString()
          console.log('---- error ---- \n ' + err)  
          Err(e) 
        } else {
          console.log(' cancelled')  // <-- for debug only
        }
    })

  return () => {
    source.cancel()
  }
  
}

const toPct = (loaded, total) => (valid(total) ? `${Math.floor((loaded/total) * 1000) / 10}%` : "")

const valid = n => (!isNaN(n) && n > 0)


