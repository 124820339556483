import hash from "object-hash"
import { BubbleNote, DEFAULT_TYPE } from "./BubbleModel"

/**
 * Digests the note into an md5. 
 * 
 * Bubble note  content is minimal an cannonical. 
 * 
 *  
 */
export const digest = (note:BubbleNote):string => {
  const {text, type, pg} = note // ignore colour

  
  const keys:any = {text,pg}
  if (type && type.length > 0 && (type !== DEFAULT_TYPE) ) {
    keys.type = type
  }

  const v = hash(keys, {algorithm:"md5"})  
  return v

}