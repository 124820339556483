import { LexicalEditor } from "lexical"
import { useMemo, useState } from "react"
import { $ } from "../../../../anyonic/dsl/mu-types"
import { $muEff } from "../../../../anyonic/dsl/usePm"
import { TreeEff0 } from "./TreeEff"



type Cancel = () => void


export type $Tree = $<{pm:TreePM},{},TreeRefs, TreeEff0>




export type TreePM = {
  timeStampedEditorStates: any
  isPlaying: boolean
  content: string
  timeTravelEnabled: boolean

  form:any
}

const TreePM0 =  {
  timeStampedEditorStates: [],
  content: '',
  timeTravelEnabled: false,
  isPlaying: false,
  editor:null,
  form: {
    fields:["name", "description"],
    values:{
      name:"NONE",
      description:"NONE"
    }
  }

}

export type TreeRefs = {
  playingIndex: number
  treeElement:HTMLPreElement | null
  input: HTMLInputElement | null
  editor:LexicalEditor |null
}

const TreeRefs0:TreeRefs = {
  playingIndex:0,
  treeElement:null, // HTMLPreElement | null>(null);
  input:null,  // HTMLInputElement | null
  editor:null
}
/*
type Setters<T> = {
  [P in keyof T]: (v:T[P]) => void
}

const RefSetters = <T>($:$Tree, p0:T):Setters<T> => 
  keys2o(p0, (k, _, o) => {  
    o[k] = v => $.mu._ps({[k]:v})
  }) as Setters<T>
*/


export const useTreePs = (): { $: $Tree, pm: TreePM } => {
  
  const [pm, update] = useState({pm:TreePM0})

  const $:$Tree = useMemo(() => 
    $muEff<{pm:TreePM}, TreeEff0, {}, TreeRefs>({
      pm:{pm:TreePM0},
      refs:TreeRefs0,
      mu:{},
      $:($:$Tree) => TreeEff0($),
      update
    }), [])
  
  return {$, pm:pm.pm}



}


export type TreeTimeTravelEff = {
  start: () => void
  transport: (e:any) => void
  exit: () => void
  playback: () => Cancel

}

export const TreeTimeTravelEff = ($:$Tree):TreeTimeTravelEff => ({

  start:()  => {


    const {timeStampedEditorStates} = $.mu.pm()
    const {editor} = $.refs()
    const rootElement = editor.getRootElement();

    if (rootElement !== null) {
      rootElement.contentEditable = 'false';
      $.ref.playingIndex(timeStampedEditorStates.length - 1);
      $.setTimeTravelEnabled(true);
    }

  }, // click

  transport: (e) => {
    const pm:TreePM = $.mu.pm()

    const editorStateIndex = Number(e.target.value);
    const timeStampedEditorState = pm.timeStampedEditorStates[editorStateIndex];

    if (timeStampedEditorState) {
      $.ref.playingIndex(editorStateIndex)
      $.refs().editor.setEditorState(timeStampedEditorState[1]);
    }
  }, 

  exit: () => {
    const pm:TreePM = $.mu.pm()
    const {editor} = $.refs()
    const rootElement = editor.getRootElement();

    if (rootElement !== null) {

      rootElement.contentEditable = 'true';

      const index = pm.timeStampedEditorStates.length - 1;
      const timeStampedEditorState = pm.timeStampedEditorStates[index];
      
      editor.setEditorState(timeStampedEditorState[1]);
      const input = $.refs().input 

      if (input !== null) {
        input.value = String(index);
      }

      $.setTimeTravelEnabled(false);
      $.mu.pm({isPlaying:false});
    }
  }, 


  playback: ():Cancel => {
    const {editor} = $.refs()

    let timeoutId;

    const $refs = $.refs()
    const pm:TreePM = $.mu.pm()

    const index = $refs.playingIndex    // the effect closure shoudl take care of this
    const input = $refs.input;


    const play = () => {

      const totalEditorStates = pm.timeStampedEditorStates.length;
      const currentIndex = $refs.playingIndex            //playingIndexRef.current;

      if (currentIndex === totalEditorStates - 1) {
        $.mu.pm({isPlaying:false});
        return;
      }
 
      const currentTime = pm.timeStampedEditorStates[currentIndex][0];
      const nextTime = pm.timeStampedEditorStates[currentIndex + 1][0];
      const timeDiff = nextTime - currentTime;

      timeoutId = setTimeout(() => {
        $refs.playingIndex++; 

        if (input !== null) {
          input.value = String(index);
        }

        editor.setEditorState(pm.timeStampedEditorStates[index][1]);
        
        play();

      }, timeDiff);
    };

    play();

    return () => {
      window.clearTimeout(timeoutId);
    };
  }


})