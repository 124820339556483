import { ActionHandler, DslDef, Handlers, HandlerSpec, keys } from "./dsl-api";
import { $$mu } from "./mu";
import { AsyncUtil } from "./dsl-util";
import { batchMutate } from "./dsl-batch";

// -- generateable 
export const PROMISE = Symbol('promise')  

type Ref = {current:any}
const publicNs = (ns:string) => (ns.indexOf('__') !== 0)
const LANG = "__lang"

/**
 * Update composite reducer 
 */
export type CompositeReducerUpdate = (state1:any, visited?:{[ns:string]:any}, state0?:any) => any

/**
 *   agregates the langauge, exposes it on $ and $$, and creates the (stateful) underlying 
 *   execution environment
 * 
 *   dsl elements are 
 *    
 *    Reducer 
 *       - specifies simple reducer algebra
 *         ie. $.mu.pm.Cmd(...)      <-- invokes the command Cmd
 *       - may expose non-trivial selectors (but default is a simple getter)
 *    
 * 
 *     Use cases:
 * 
 *        1. react state
 *           - mutations are followed by an effectful update, triggering react render
 *           - but can be batched
 * 
 *        2. local process state 
 *           - updates do not trigger render
 * 
 *        3. global cache 
 *           o read only => global injection mechanism 
 *           o writeable => singleton shared state.  
 * 
 *       This is modeled via 
 * 
 *          1. a persisted, mutable, state on refs[name].current
 *          2. an ActionHandler /w an update method 
 *             handler.fn()
 *        
 *         
 * 
 *    Dispatcher
 *      - may involve non-tri 
 *  
 * 
 */
export const toSpec = (defs0:{[ns:string]:DslDef<any>}, muReducers, state0?:any, update0?:CompositeReducerUpdate):HandlerSpec => {

  const defs = {[LANG]:AsyncUtil, ...defs0}; // <-- add langauge constructuers $.ps etc
  
  const nss = Object.keys(defs)             // -- namespaces {pm:Reducer(...), }
  
  var $$:any = {};
  var refs:{[ns:string]:Ref} = {};
  var byNs:{[ns:string]:{def:DslDef<any>, handlers:Handlers}} = {}
  
  
  nss.forEach(ns => {
    
    const def = defs[ns]  // <-- refs in which state will be persisted
    var ref:any = null
    const nsIsPulbic = publicNs(ns)
    
    if (nsIsPulbic) {
      // -- only Reducer supported for this, for the moment
      assert(def.$ === "Reducer", `${ns} cannot be public for ${def.$}. (Prefix /w '_')`)
      const s0 = (state0 && state0[ns]) || def.state 
      ref = refs[ns] =  {current:s0}  
    }

    var handlers:Handlers =  {}

    byNs[ns] =  {def, handlers}
    switch (def.$) {

      case 'Reducer':
        // -- for each reducer command
        /*
        -- for now, not going to bother adding reducer
        const muActions = muReducers && muReducers[ns];

        keys(muActions||{}, (name, muAct) => {
          console.log(' have a mu actions ' + name)
          const nsname =  toNs(ns, name) // `${ns}::${name}`
          // mu: {
          //   pm: { <-- the name space 
          //     MyLeafMuReducer : (args) =>  (mu, s) => 
          //  }
          // }}
          const cons = wrapCmd(nsname , muAct)   

        })
        */

        Object.keys(def.actions).forEach(name => {
          const nsname =  toNs(ns, name) // `${ns}::${name}`
          const cons = wrapCmd(nsname , def.actions[name]) // <-- constructor  
          const fn = def.reducers[name]
          
          handlers[name] = {fn, def ,  ref, name, ns, nsname, cons  }
        
        })




        Object.keys(def.selectors).forEach(name =>  {
          //cons = //$$[name] = {$:name}  // <-- extremely simple selector algebra.
          const nsname = toNs(ns, name)
          const cons = {$:nsname}

          handlers[name] = {fn:def.selectors[name], def, ref, isSelector:true, name, ns, nsname, cons  }
        })
        break

      case "Dispatch": 
        const {actions, selectors} = def;
        keys(actions, (name, action) => { 
          const nsname =  name // nsn(ns, name)
          const cons = wrapAction(nsname, action ) 
          handlers[name] = {fn:def.dispatch, def, ref, name, ns, nsname, cons}
        });
        selectors && keys(def.selectors!.selectors, name =>  {
          const nsname = name  // nsn(ns, name)
          const cons = {$:name} // <-- does not prevent collisions 
          handlers[name] = {fn:def.selectors!.selectors[name], def, ref, isSelector:true, name,ns, nsname, cons }
        })
        

        break
      case "Async":
        const {isGen} = def
        keys(def.actions, name => {
          const nsname = name 
          //$$[name] = (...args)  => ({$:PROMISE,  name, args})  // <-- $$.myFn is replaced /w a wrapper that preservers the args
          const cons =  (...args)  => ({$:PROMISE, ns, name, args})  
          const fn0 = def.actions[name]         
          const fn = ({args, name}) => {                       // <-- and here we call it
            //console.log(`    ${name}  => Promise ,  args= `, {args})
            const p = fn0.apply(null, args)
            if (isGen) {
              assertGen(p, name)  // make sure we have the generator we're expecing
            } else {
              assertPromise(p, name) // make sure we have the promise we're expecting
            }
            return p
          }
          handlers[name] = {fn, def, isGen, name, ns, ref, nsname, cons}   
        })
    
        break
        

      } 
  
    })
  
  
    // -- create the state, command and mu apis on $ and $$
    

    var handlers:any = {}
    var updating:{[ns:string]:boolean} = {}
    const ctors:{[ns:string]:{[cmdName:string]:Function}} = {}   // <-- collect constructors

    // -- 
    var state:any = {}

    keys(byNs, (ns, {def, handlers:hs}) => {

      const isReducer = def.$ === "Reducer"
      
      if (isReducer) {
        ctors[ns] = {}  //
        state[ns] = (state0 && state0[ns]) || def.state || {}
        if (ns.indexOf("_") !== 0) {  // <-- namespaces /w underscore prefix are private
          updating[ns] = true
        }
      }

      keys(hs, (name, handler:ActionHandler) => {
        const {nsname, cons} = handler
        if (def.$ === "Reducer") {
          ctors[ns][name] = cons   // ie. $$.pm.TermConstructor(...) 
          handlers[nsname] = handler // global handlers
        } else { 
          $$[name] = cons   // ie  $$.async = constructor for async cmd
          handlers[name] = handler  
        }
      })
      
    })

    // -- now iterator over reducer constructures/ commends
    const stateRef = {current:state}
    var muReducers0 = {}
    var muActions = {}
    keys(muReducers, (k:string,v) => {
      ((k.charAt(0) === k.charAt(0).toLowerCase()) ? muActions : muReducers0)[k] = v
    })


    $$.mu = $$.state = $$mu(refs, ctors, muReducers0, muActions, stateRef, o => {
     // console.log('dbg goes here')
    })

    keys(ctors, (ns, cs) => {
      
      $$[ns] = function (o) {  //  $$.pm(p) // <-- non chaining
        return batchMutate(ns, o, ns ) 
       }
      // -- TODO - add muActions here ie pm.LeafMuReducer = (arvgs) => (mu, s) => ... 
      keys(cs, (name, ctor) => {   // add all the algebraic cmds $$.pm.Cmd(args)
    
        if (ctor instanceof Function) {
          $$[ns][name] = toCons(ns, ctor) // might like to instrument /w a debug ...
        } else {
          $$[ns][name] = ctor   // ctor is a constant, so just return it
        }
  
      })
    })

    validate(handlers)  // <-- FIX_THIS - re-enable
    

    const update = createUpdateFn(updating, stateRef,refs, update0)

    return { 
    //pm,pmKeys,  <-- these might be nice to have to debug
      update,
      updating,
      stateRef,
      defs, 
      ctors,  
      refs,
      muActions,
      muReducers:muReducers0,
      handlers, byNs,  $$ }  //$$:wrap$$($$)}
   } // ::  HandlerSpec
   

   const createUpdateFn  = (updating:{[ns:string]:boolean}, stateRef, refs, update0?:CompositeReducerUpdate) => {

    const getUpdateState = () => {
      // TODO - in the absence of internal state, just send the full state
      var o = {}
      var s = stateRef.current
      Object.keys(updating).forEach(ns =>{

       (o[ns] = s[ns])
      } )
      return o
    }

    // -- composite reducer update
    return (visited) => { 
      
      var reqUpdate = false 

      Object.keys(visited).forEach(ns => {
      
        if (refs[ns] !== undefined) {
          refs[ns].current = visited[ns]
          reqUpdate = reqUpdate || updating[ns]
        } else {
          throw new Error(`unknown model ${ns}`)
        }
      }) 

      const v0 = stateRef.current
      stateRef.current = {...v0, ...visited}

      if (reqUpdate && update0) {
        var pm = getUpdateState()  // <-- copy subset of updating fns
        update0(pm)
      }




    }
   }


  
  const validate = (handlers:Handlers) => {
    for (var name in handlers) {
      assert(typeof handlers[name].fn == 'function', `invalid handler ${name}`, handlers[name]) 
    }
  }
  
  
  export const assert = (v, msg?, o?) => {
    if (!v) {
      console.log(`Assertion Fail: "${msg}"`, {o, msg})  
      throw new Error(msg || "") 
    }
  }


  const wrapAction = ($, fn) => (...args) => {
    const v = fn.apply(null, args)
    return {$,  v}
  }


  /**
   *  Wrapper for a leaf reducer command constructor 
   * 
   * Given a reducer action, ie  (...args)  =>  {$:"MyCmd", ...args}
   * wrap it in a fully namespaced {$:"ns:MyCmd", c:{$:"MyCmd", ...args }}
   * 
   *  - prevents conflicts when composing dsl algebras
   *  - gives a place to (optionally) apply type checking 
   * 
   */
  const wrapCmd = ($, fn0) =>  (...args) => {
    // TODO - inject type checkeing etc here 
    const v = fn0.apply(null, args)  

    return {$, v}
  }
    
   
   
const assertPromise = (p, name) => assert(p instanceof Promise, `${name} doesn't return a promise`)
const assertGen = (gen, name) => assert(gen && isGen(gen), `${name} must return a generator function`)


export const assertFn = fn => assert((typeof fn === "function"), 'require a function')

const isGen = fn =>  (typeof fn === 'function' && fn.constructor && fn.constructor.name === 'GeneratorFunction')

/**
*  js friendly version of the Either monad 
*  
*  data Result v = Ok .v ok:boolean | Err = err:{message:string} ok:boolean
* 
*  ie const {ok, result, err} = fnThatReturnsResults(...)
*     if (ok) ...  
*/
export type Result<a> = { ok:true, v:a}  | { ok:false, err:{message:string}}
export const Ok = <a>(v:a):Result<a> => ({ok:true, v})
export const Err = (err:{message:string}) => ({ok:false, err})



const toNs = (ns, name) => (`${ns}::${name}`)
 


 
const toCons = (ns, cons0) => (...args) => {
  var o = cons0.apply(null, args)
  return o 
  //return {$:BATCH, queue:[{ns, o, a }]}  
}