export type AnyonPSM = {
  loadPs: {
    running:boolean
    ref?:any
    unsubscribe?:() => void
  }
}


export const AnyonPSM0:AnyonPSM = ({
  
  loadPs: {
    running:false

  }

})