import { $isLineBreakNode, ElementNode, LexicalNode, SerializedElementNode } from "lexical";
import { Spread } from "../../libdefs/globals";
import { $isFieldNode, FieldNode } from "./FieldNode";



export type SerializedAntiformNode = Spread<
  {
    type: 'antiform';
  },
  SerializedElementNode
>;

// references https://lexical.dev/docs/concepts/nodes#extending-elementnode
export class AntiformNode extends ElementNode {

  static getType(): string {
    return 'antiform-node';
  }
  
  static clone():AntiformNode {
    return new AntiformNode()
  }
  

  createDOM(): HTMLElement {
    // Define the DOM element here
    const dom = document.createElement('div');
    dom.className = "antiform"

    return dom;
  }

  updateDOM(prevNode: AntiformNode, dom: HTMLElement): boolean {
    // Returning false tells Lexical that this node does not need its
    // DOM element replacing with a new copy from createDOM.
    return false;
  }

  toForm():{field:string,value:string} {
    const children = this.getChildren();
    const fieldNode:FieldNode = children.find(n => $isFieldNode(n))  as any
    const i = children.indexOf(fieldNode)
    if (i >= 0)  {
      const nl = children.find(n => $isLineBreakNode(n))
      const j = nl ? children.indexOf(nl) : children.length
      var value = ""
      for (var k = i + 1; k < j; k++) {
        value += children[k].getTextContent()
      } 
      return {field: fieldNode.getField(), value}


    } 
      return {field:"UNKNOWN", value:""}
  
  }

  exportJSON(): SerializedAntiformNode {
    return {
      ...super.exportJSON(),
      type: 'antiform',
      version: 1,
    };
  }


}

export const $isAntiformNode = (node:LexicalNode):boolean => {
  return node instanceof AntiformNode
}
export const $createAntiformNode = ():AntiformNode => {
  return new AntiformNode()
}