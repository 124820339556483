import { LexicalEditor } from "lexical"
import { usePm$ } from "../../anyonic/dsl/usePm"
import { Page } from "../../model/page/resource/PageState"
import { FBUser } from "../../model/page/user/FBUser"
import { Mu } from "../anyon/AnyonMu"
import { EditorEff } from "../bubble-base/ref/eff/EditorEff"
import { ChannelsMu } from "./ChannelsMu"
import { ChannelsPM } from "./ChannelsPM"
import { ChannelsEff } from "./eff/ChannelsEff"


export type $Channels = {
  mu:Mu<ChannelsPM, any, ChannelsMu<any>>
} & ChannelsEff

export type ChannelRefs = {
  editor:LexicalEditor
}

const ChannelRefs0 = {
  editor: null
}

export const useChannelsEditor = (page:Page, user:FBUser ):{$:$Channels, pm:ChannelsPM} => {

  const {$, pm} = usePm$(() => ({
    PM: {
      
    },
    refs:ChannelRefs0,
    mu:ChannelsMu,

    $:  $ => ({
      editor:EditorEff($),
      
      // -- all of this can be automated
      pm:$.mu.pm,

      refs: $.mu.refs,
      ref: {
        editor: editor => $.mu.refs({editor})
      }

    })

  })) as {$:$Channels, pm:ChannelsPM}

  return {$,pm}

}


