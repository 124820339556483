import { Pages, PgIds } from "../BubbleModel"
import { toPgIds } from "./ChapterTweak"

export const prevNote = (pages:Pages, did:string):string|null => {
    const {pgs} = toPgIds(pages)
    var found:[number, number, number|null]|null
    pgs.forEach( (pgIds, i) => {
      pgIds.forEach((id, j) => {
        if (id === did)  {
          found = [i, j, null]
        } else if (id instanceof Array) {
            const k = id.indexOf(did)
            if (k >= 0) {
              found = [i,j,k]
            }
          }
        })
      })
    
    if (found) {
      const [pg, i, j] = found
      if (j === null) {
        return prevOf(pgs, pg, i) 
      } else {
        var cur = pgs[pg][i]
        if (j > 0) {
          return cur[j-1]
        } else {
          return prevOf(pgs, pg, i)
        }
      }
    }

   
  }


  const prevOf = (pgs:PgIds[], pg, i):string|null => {
    if (i > 0) {
      const prev = pgs[pg][i-1]
      return lastOf(prev)
    } else {
      // -- go to prev page
      if (pg === 0) {
        return null
      } else {
        const prev = pgs[pg-1][pgs[pg-1].length-1]
        return lastOf(prev)
      }
    }


  }

  const lastOf  = (v:(string|string[])) => {
    if (v instanceof Array) {  
      return v[v.length -1]        
    } else {
      return v 
    }
  }
