import { fbPs } from "../../../firebase/fbPs"
import { $Anyon } from "../useAnyonPs"

export type AnyonFileEff = {
  load: () => void
  save: () => void
  stop: () => void

}



export const AnyonFileEff = ($:$Anyon):AnyonFileEff => ({
  load: (create?:boolean) => {



    // --- 
    const {loadPs} = $._ps()
   

    if (loadPs.running) {
      return
    }
    // 


    const { target } = $.pm()
    const { docId } = target
   
   

    const ref = fbPs().getAnyonDoc(docId)

    create = true //  <-- TODO make this explict

    const unsubscribe = ref.get().then(doc => {
      if (!doc.exists) {
        if (create) {
          $.mu.CreateDoc(docId)
          const {entity} = $.pm()
          ref.set(entity).then(_ => {
            console.log('created')
            $.setDoc(docId, entity)
          })
        } else {
          // TODO - handle case where creation isn't automatic
        }
      } else {
        const entity = doc.data()
        $.setDoc(docId, entity)
      }
      
      
    })


    // -- update 
    $._ps({loadPs:{
      running:true,
      ref,
      unsubscribe
    }})



  }, 
  save: () => {

  },

  stop: () => {
    const {loadPs} = $._ps()
    if (loadPs.running) {
      loadPs.unsubscribe()
      $._ps({loadPs:{running:false}})
    }
  }

})

