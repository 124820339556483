import { fbPs } from "../../../../../firebase/fbPs"
import { toSimpleEntity } from "../../../import/BubbleEntitty"
import { Tweaks } from "../../doc/tweak/BubbleTweak"

export const saveBubbleTweaks = async (readingId:string, tweaks:Tweaks) => {


  const ps = fbPs()
  const tweakRef = ps.bubbleNoteTweak(readingId)
  const tweakEntity = toSimpleEntity(tweaks)

  await tweakRef.set(tweakEntity)


}