import {matchPath, PathMatch} from 'react-router'
import { MediaEnv } from './MediaEnv'
import { LeafMedia, ResourceManifest } from './ResourceManifest'

/**
 * Representation of a page resource url, /w params parsed and assigned a  
 *    type, wrt. a resource map. ie "/video/:id" as a "Video" type, with id property parsed 
 *  
 */
 export type PageResource = {
    rurl:string  // <-- wholly determined by rurl ie 'project/user/pname'
    type:string   //  type and params are simply parsed for convenience
    params:any    
    manifest:ResourceManifest<LeafMedia<string>> // <-- core resources determined statically by url
    links:{[rel:string]:string}  // <-- hypermedia links that this page relies on   
  }
  

// data Pages = 
// Home | Login | Logout 
// | PVideo project. id.  

const unknownPage = (rurl:string):PageResource => ({type:"404", rurl, params:{}, links:{}, manifest:ResourceManifest.None })


/**
 * Resolve page resource, given routes, ie.
 * 
 *  {   
 * 
 *     Home:"/"
 *     Video:"/video/:project/:id"    
 *     
 *  }
 * 
 */

export const createResource = (env:MediaEnv<any>, rurl:string):PageResource => {


    for (var type of Object.keys(env.routes)) {
      //var match = matchPath(rurl, {path:env.routes[type], exact:true , strict:false}) // 
      var match:PathMatch|null = matchPath(env.routes[type], rurl)
      
      if (match) {
        const params = match.params
        const manifest:ResourceManifest<any> = env.toManifest(type, params)
        const links = ResourceManifest.toLinks(manifest);
        
        return {type, rurl, manifest, links, params}
      }
    }
    return unknownPage(rurl)
}
  







