import firebase from "../../firebase/firebase"

import { UserState } from "../../model/page/user/UserState"
import { FBUser } from "../../model/page/user/FBUser"
import { createStorePs$, SStore } from "../../anyonic/dsl/createStorePs$"
import { Mu } from "../../media/anyon/AnyonMu"
import { NavEff } from "../../model/ps/pagePs/NavEff"


type UserPs = {
  location:any
  navigate:Function
}

export type $User = {
  mu:Mu<UserState,UserPs, UserMu<any>>
  ps:any
  nav:NavEff  // <-- issue. No longer supported
  start: () => void
  setLocation: (location, navigate) => void
}



export const createUserPs$ = (store:SStore<any>):$User => createStorePs$(
  store, 
  {
    pm:userState_0,
    PM: {
      _ps: {
        navigate: () => {throw new Error("nagigation not injected")},
        location: {NOTE:"location not injected"}
      }
    },
    
    mu: UserMu,
    $: $ => ({
      nav: NavEff($),
      start:start($),
      // -- HACK. but we need to navigate
      setLocation: (location, navigate)  => {
        $.mu._ps({navigate, location})
      }
    })
  }) 


var _started = false

const start = ($:$User) => () => $.ps(_ => {
  if (_started) {
    return
  }
  _started = true
  firebase.auth.onAuthStateChanged(user => {
    if (user) {
      const usr:FBUser = toFBUser(user)
      //yield $$.SET_USER(usr)       // <-- possible that the navigation is causing the user to log out
      const _ps = $.mu._ps()
      $.mu.SetUser(usr)
      // FIX_THIS - this breaks the SPA
      if (_ps.location) {
        const {pathname, search} = _ps.location
        $.nav.to(pathname + (search && search.length > 0 ? `${search}` : ""))
      } else {
        $.nav.home()  // <-- navigate. Really would like this to be batched
      }
    } else {
      //console.log('-- no user --')
      $.nav.login() 
      //yield $$.CLEAR_USER()   
      $.mu.ClearUser()
    }
  })
  return () => {
    console.log('done')
    _started = false
  }
}) // note that this returns unsubscribe, to ensure process is stopped on unmount



// TODO - type as  firebase.User
export const toFBUser = (user):FBUser => 
    FBUser( user.uid,  user.displayName || emailToName(user.email), user.photoURL!)


const emailToName = (v:string) => {
    var i = v.indexOf("@") 
    var out = v.substr(0,i)
    return out
}


export const userState_0:UserState = {
    user: undefined,   
    isLoading: false  // 4 states actually: Not loading yet; loading; loaded; failed to load.
  };



type UserMu<r> = {
  SetUserLoading: () => r
  SetUser: (user:FBUser) => r
  ClearUser: () => r
}

const UserMu:UserMu<any> = {
  SetUserLoading: () => mu => {
    mu.pm({isLoading:true})
  },
  
  SetUser: (user:FBUser) => mu => {
    mu.pm({user, isLoading:false})
  },

  ClearUser: () => mu => {
    mu.SetUser(undefined)
  }
}