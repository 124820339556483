import { $createLineBreakNode, $createParagraphNode, $createTextNode, $getRoot, EditorState, LexicalEditor, RootNode } from "lexical"
import { $ProjectEditor } from "../../useProjectRefDocEditorPs"
import { DocPM } from "../DocPM"


/**
 * 
 * Manages the contents of an the editor.
 * 
 *  - basic lifecycle, start & change, gets the references to the editor and it's state
 *    from the lexical editor
 *  
 *  - exposes text
 * 
 *  - setDoc  x 
 *  
 */
export type  IEditorEff<doc> = {

  // -- sets the reference to the editor
  start: (editor:LexicalEditor) => void
  
  // -- register changes
  changed: (state:EditorState, editor:LexicalEditor) => void  
  
  // -- source of the raw text in the editor
  currentTxt: () => string


  // -- beginnings of the abstraction of text-entity mapping lifecyel
  //  type RefDocPM = {
  //    ref:RefDoc,       <-- this is document specific
  //    srcIds:{[ref:string]:string} 
  //    validated:boolean
  //    resolved:boolean 
  //    txt: string 
  //  }
  // 

  setDoc: (doc:DocPM<doc>) => string

}


/**
 * 
 * ref:
 *    editor:LexicalEditor
 *    state:EditorState
 *    txt:string
 * 
 * pm:
 *    unsaved:
 *    exits
 * create:() 
 */


export const EditorEff = <doc>($:{coDoc:IDocCoEff, xDoc:IXDocEff}):IEditorEff<doc> => ({

  start: (editor:LexicalEditor) => { 
    //$.mu._ps({editor})
    $.coDoc.set({editor})
  },

  changed: (state:EditorState, editor:LexicalEditor) => {
    
    //const x = parseDoc(state, editor)
    const {state:oldState} = $.coDoc.state1()
    $.coDoc.set({editor, state})


    
    const {exists} =  $.xDoc.pm()  //  $.mu.pm() 
    
    //$.mu._ps({ editor, gdfewxstate })

    if (exists === false) {

      //$.xDoc.create()
      throw new Error("exists concept now doesn't allow editing before creation ")
    } else if (oldState && state !== oldState) {
      $.xDoc.pm({ unsaved:true })
    }


  },


  currentTxt: ():string => {

    const {state, txt}:{state:EditorState, txt:string} = $.coDoc.state1() //$.mu._ps()
    
    const {unsaved} = $.xDoc.pm()

    if (!unsaved) {
      return txt
    }

    //const data = state.toJSON() 
    var newTxt
    state.read(() => { 
      const root:RootNode = $getRoot();
      newTxt = root.getTextContent()
    })
    
    // TODO - cache text ref 
    return newTxt
  },


  setDoc: (doc:DocPM<doc>) => {

    const {txt} = doc
    const {editor}:{editor:LexicalEditor} = $.coDoc.state1()   //$.mu._ps()
    
    // -- another option would
    var lines = txt.split("\n")

    if (lines.length === 0) {
      lines = ["# references  "]
    }

    if (!editor) {
      throw new Error(" TODO - make sure sure we have an editor reference")
    }
    
    editor.update(() => {
      const p = $createParagraphNode();
      for (var line of lines) {
        const text = $createTextNode(line);
        p.append(text);
        p.append($createLineBreakNode())
      }
      const root = $getRoot()
      const children = root.getChildren()
      for (var child of children) {
        child.remove()
      }
      root.append(p)
      
      root.selectEnd();
    })

    // -- TODO - parse various data
    //$.mu._ps({txt})
    $.coDoc.setText(txt)
    return txt
  },

})


export type IDocCoEff = {
  setText: (txt:string) => void
  state1: () => {editor:LexicalEditor, state:EditorState, txt:string}
  set: (vs:{editor?:LexicalEditor, state?:EditorState,txt?:string}) => void
}

export const CoDocEff = ($:any):IDocCoEff => ({
  setText: txt => $.mu._ps({txt}),
  
  state1: () => {
    const {editor,state, txt}:{editor:LexicalEditor, state:EditorState, txt:string} = $.mu._ps()
    return {editor,state, txt}
  },

  set: $.mu._ps
})

export type IXDocEff = {
  pm:any
  
  create:() => void 
  save: () => void
  load: () => void
  doesNotExist: (projectId:string) => void

}

export const XDoc = ($:$ProjectEditor):IXDocEff => ({
  pm:$.mu.pm,

  create: () => {
    const { exists} = $.pm()
    if (exists === false) {
      $.mu.Create()
      const {doc} = $.pm()
      $.editor.setDoc(doc)
    }
  },

  save: async () => {

    const { doc} = $.pm()
    const txt = $.getTxt()
    const doc1 = $.validate(txt, doc)
    if (!doc1) {
      console.log("ERROR - doc validates to null")
      console.log({doc, txt})
      const doc2 = $.validate(txt, doc) // for debut
    }
    if (!doc1.resolved) {
      const result = await $.resolve(doc1)
      if (result.ok) {
        $.server.save(result.v)
      }
    } else {
      $.server.save(doc1)
    }

    
  },
  
  load: () => {
    const {projectId} = $.pm()
    $.pm({loading:true, loaded:false})
    $.server.load(projectId)
  },

  doesNotExist: (projectId) => {
    $.pm({loading:false, exists:false})
  },  

})
