import { Cache, PageCache } from "./PageCache"
import { PageModel } from "./PageModel"
import { PageState, resolvePageState } from "./PageState"
import { ResourceManifest } from "./ResourceManifest"

const {None} = ResourceManifest


 
/**
 * Encapsulates the media environment of the site
 * 
 */
export type MediaEnv<media> = {
  routes: {[type:string]:string}
  toManifest:(type:string, params:any) => ResourceManifest<media>
  resolvePage:(rurl:string, cache:PageCache) => PageModel
  resolveLink:(page:PageState<any,any>, rurl:string, cache:PageCache) => PageState<any,any>|undefined
} 

export type MediaDef<media> = (params:any, env:MediaEnv<media>)=> ResourceManifest<media> 


export const mediaEnv = <media>(routes:{[type:string]:string}, types:{[type:string]:MediaDef<media>}):MediaEnv<media> => {

  var env:MediaEnv<media> = {
    routes,
    toManifest: ( type:string, params:any):ResourceManifest<media> => {
        const fn = types[type]
        return fn ? fn(params, env) : None
      },
    resolvePage: (rurl, cache:PageCache) => resolvePageState(env ,rurl, cache ),
    resolveLink 
  } 

  return env

}


const resolveLink = (page0:PageState<any,any>, link:string, cache:PageCache):PageState<any,any>|undefined => {
  var paths = link.split(".")
  var page = page0
  // -- iterate over paths
  for (var path of paths) {
    var rurl = page.resource.links[path]
    if (rurl) { 
      page = Cache.retrieve(rurl, cache)!
    }  
    if (!page || !rurl) {
      return undefined
    }
  }
  return page
}

