import { useMemo } from "react";
import { usePage } from "../../MediaContextProvider"

//import { useViewNav } from "../../model/ps/usePageLoader";


import { toFolders } from "./model/folder/Folder";
import { Slide } from "./view/typeography/Slide";
import { RefFolder } from "./model/view/type/RefLink";



/**
 * A completely pure renderer of page state ...
 *
 */
export const DProject = () => {

  const page = usePage()

  const { folders } = useMemo(() => {
    return toFolders(page.data!, page.resource.rurl)  // <-- page data always loaded before rendering
  }, [page.data, page.resource.rurl]);
 

  return <Slide supTitle="RESOURCES" title="Social Justice in Counselling: Annotated Readings">
    {folders.map((folder,i) => <RefFolder key={i} folder={folder}/>)}
  </Slide>




}
 
 
/*
old code
 return  (<div>
      <div className={"bubble-breadcrumbs-bar"}>
          <Link to="/">
            <HomeIcon  />
          </Link>
          <h1>Project: "{page.resource.params.pname}" </h1>
      </div>

        {render(folders, nav)}
        <Link to="/">
          <HomeIcon  />
        </Link> 
      </div>)
  


  
  return (
    <div>
      <div className={"bubble-breadcrumbs-bar"}>
      <Link to={nav("/")}>
        <HomeIcon  />
       </Link>

      <Link to={nav("/")}>projects</Link> {" > "} {page.resource.params.pname}
      </div>
      <h1>Project: "{page.resource.params.pname}" </h1>
      {render(folders, nav)}
    </div>
  );
};

const render = (folders: any[], nav) => {
  return (
    <div>
      {folders.map((p, i) => (
        <div key={i}>
          <Spacer />
          <ColoredLine color="#340410" />
          {p.path ? <h2>{p.path}</h2> : null}
          {p.items.map((item: any, i:any) => citation(item, nav, i))}
        </div>
      ))}
    </div>
  );
};


const hasRef = (ref:any) => (ref && ref.indexOf('_') < 0)

const citation = ({ ref, link, name, authors }: any, nav:any, i:any) => {
  return (
    <div key={i}>
      {"["}
      {hasRef(ref)  ? <Link to={nav(link)}>{ref}</Link> : <span>{ref}</span>}
      {']  "'}
s
      <span>{name}</span>
      {'"  '}
      {renderAuthors(authors)}
    </div>
  )
}

const renderAuthors = (authors: string[] = []) => {
  return (
    <span>
      {authors.map((v, i) => (
        <span key={i}>
          <div>{v}</div>
          {" ; "}
        </span>
      ))}
    </span>
  );
};

const ColoredLine = ({ color }: { color: any }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const Spacer = () => <div style={{ height: "30px" }}></div>;

// https://typespiration.com/design/love-story/



/*

const toViewNav = (page:PageState<any,any>) => {
  
  let {type, params:payload} = page.resource
  var base = {type:type.toUpperCase(), payload}
  
  return (view:string) => {
    return {...base, query:{view, showView:true}} // <-- a redux-first-router action
  }

}
*/ 