import { FormBase } from "../form/FormBase"
import { validEmail, validPassword } from "../form/validators"

// -- imported language --
import { login, logout } from './firebase-auth-ps'


import { change, validateMu } from '../form/form-ps'

import { usePm$ } from "../../anyonic/dsl/usePm"

export type LoginFormPM = FormBase<LoginValues> 

export const loginForm0:LoginFormPM = {
  
  formSchema: [
    { id: 'email', label: 'email', value: '' },
    { id: 'password', label: 'password', value: '', isPassword:true},
  ],

  values: {
    email:'',
    password:''
  },

  validators: {
    email: validEmail,
    password:validPassword
  }
}

export type LoginValues = {
  email: string
  password: string
}


export const useLoginPs = ():{pm:LoginFormPM, $:any} => usePm$(() => ({
    pm: loginForm0,
    $: {
      login,
      logout,
      submit,
      change,
    }, 
    
  })) 
  


/** 
 *  Submittions  
 *    - Form algebra (ie. validate)
 *    - Login algebra (ie. firebase implemention of login)
 *    - some addition process level state (ie Set({isSubmitting}))
 * 
 * 
 *  Note: the only way to reset (ie set isSubmitting back to false)  id 
 */

const submit =  $ => e => $.run(function*($$) { 
  e.preventDefault();
  
  const state = $.mu(mu => mu.pm(validateMu)) // TODO - $.mu.form(validate) pending mu-reducer api

  const {ok, validatedValues:v} = state.pm  // FIX THIS - validate isn't really an effect
  
  if (ok) {
    $.mu.pm({isSubmitting:true})
    
    const {ok:loginOk, err} = yield $$.await(() => $.login(v.email, v.password))
    
    if (!loginOk) {
      // yield $$.Set({err:err.message, isSubmitting: false}) // submit fails ..
      $.mu.pm({err:err.message, isSubmitting: false})
    } else {
      // higher level process will listen for login success ... nothihg
    }
    //yield $$.Set({isSubmitting:false})   // <-- not going to rest 
    
  }
})
