import { fbPs } from "../../../../../firebase/fbPs"
import { fromEntity } from "../../../import/BubbleEntitty"
import { BubbleReading } from "../../doc/BubbleModel"
import { Tweaks } from "../../doc/tweak/BubbleTweak"

export const getBubbleNotes = async (readingId:string):Promise<{reading:BubbleReading,tweaks:Tweaks}> => {

  const fb = fbPs()

  //const Ref = fb.bubbleNoteData(reading)
  const entities = await fb.bubbleNoteCollectionData(readingId)
    .get() 
    .then((querySnapshot) => {
        const out =  {}
        querySnapshot.forEach((doc) => {
          const k = doc.id
          const data = doc.data()
          if (k !== "data" && k !== "tweaks") {
            throw new Error(`unknownd db entry on reading ${k}` )
          }
          out[k] = data
        })
      return out
    })


    const reading:BubbleReading = fromEntity(entities.data)
    var tweaks = (entities.tweaks) 
      ? fromEntity(entities.tweaks) : {}

    return {reading, tweaks}



}