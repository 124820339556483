import { shallowEqual } from "react-redux"
import { Chapter, ChPages, Note, NoteNode, NotePage, PgTags, Taggings } from "../doc/BubblePM"
import { hasTag } from "../doc/noteTagging"


export type BubbleFilterPModel = {
  tag:string|null
  ch?:string  
  section?:boolean  // <-- section view ... only show sections
}




export const toTagFilter = (tagState:Taggings, fpm:BubbleFilterPModel):((n:Note<any>) => boolean) => {

  const {tag, section} = fpm
  if (!tag && !section) {
    return _ => true  // nothing to filter
  }
    
  return (note:Note<any>):boolean => {
    return  (!tag || hasTag(tagState, note.did, fpm.tag)) &&
            (!section || note.note.col === ".sec")
  }

}


const filterNodes = <p,g,n,s>(page:NotePage<p,g,n,s>, fn) => {


  const filtered = page.nodes.map( (note:NoteNode<g,n,s>) => {
    if (note.$$ === "Group") {
      const notes = note.notes.filter(fn)
      if (notes.length === 0)  {
        return null
      } else if (notes.length === 1) {
        return notes[0]  // <-- ungroup 
      } else if (notes.length === note.notes.length)  { 
        return note
      } else {
        return {...note, notes}
      }
    } else if (note.$$ === "Note")  {
      return fn(note) ? note : null
    } //else if (note.$$ === "Splat") {
      throw new Error("TODO - handle splat replacements")
    

  }).filter(notNull) as NoteNode<g,n,s>[]

  return shallowEqual(filtered, page.nodes) 
     ? page 
     : {...page, nodes:filtered}

}


const notNull = v => (v !== null)

export const filterChapters = <c extends ChPages, p extends PgTags,g,n,s>(
  chapters0:Chapter<c,p,g,n,s>[], 
  fpm:BubbleFilterPModel
):Chapter<c,p,g,n,s>[] => {

  const chapters = chapters0.map( ch => {
    const keep = (!fpm.ch || (fpm.ch === ch.pm.id))

    const pages = keep 
      ? (ch.pages.map(pg => {
          const filterFn = toTagFilter(pg.pm.taggings, fpm)
          return filterNodes(pg, filterFn)
        }).filter(p => p.nodes.length !== 0))
      :  []

    return shallowEqual(pages, ch.pages) ? ch : {...ch, pages}
 
  }).filter(ch => ch.pages.length !== 0)
 
  return shallowEqual(chapters, chapters0) ? chapters0 : chapters

}


