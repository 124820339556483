import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";
import { SrcRef, RefGroup, Comment } from "../../bubble-base/ref/model/RefDoc";

const SubSlideHeader = styled('div')(({theme}) => `
  justify-self: flex-end;
  color: ${theme.palette.slide.RED};
  font-size:35px;
`)

//  text-decoration: underline;
// text-decoration-color: ${theme.palette.slide.RED};

export const RefGroupView = ({group}:{group:RefGroup}) => {
    const {refs, name} = group
    const showName =  name.length > 0
    const showRefs =  refs.length > 0
    
    if (!(showName || showRefs)) {
      return null
    }
    return (<div>
        {showName &&
          (<SubSlideHeader>
          {name}
          </SubSlideHeader>)}
        {showRefs && 
          (<Box sx={{
            pt:1.5,
            pb:2
          }}>
          {refs.map((rf, i) => {
            if (rf.$$ === "Comment") {
              return (<CommentaryView key={i} rf={rf} />)
            } else {
              return <Reference key={i} rf={rf}/>
            }
          })}

          </Box>)}
    
      </div>)
      
}

export const CommentaryView = ({rf}:{rf:Comment}) => (
  <Box sx={{
      typography:"editorTxt", fontFamily:"Ubuntu", ml:0, flexGrow:1,
      color:"#eeeeee",
      fontSize:( rf.pm.indent ? 20 : 22),
      pl: (rf.pm.indent ? "20px" : 0),
      pt:"6px",
      pb:"6px"
      }}>
    {rf.comment}
  </Box>
)

export const Reference = ({rf}:{rf:SrcRef}) =>  (
  <Box sx={{display: 'inline-flex', pb:1}}>
    <RefLink refId={rf.ref} link={rf.pm.link} />
    {rf.name}
  </Box>
)




export const RefLink = ({refId, link}) => (
  <Box  sx={{  minWidth: (link ? 150 : 0)}}>
    {link
       ? (<Link to={link}>
             <RLink>
             {refId}
            </RLink>
          </Link>) 
    : (<RLink>
        {refId}
      </RLink>)}
  </Box> )


export const RLink = styled('span')(({theme}) => `
    justify-self: flex-end;
    color: ${theme.palette.slide.LINK};
    text-decoration: underline;
    text-decoration-color: ${theme.palette.slide.RED};
    padding-bottom:3px;
    border-bottom: 4px;
    border-color: blue;
    font-size:26px;
    &:hover {
      color: ${theme.palette.slide.RED};
      text-decoration-color: ${theme.palette.slide.LINK};
    }
`)

export const MLink = styled('span')(({theme}) => `
    justify-self: flex-end;
    color: ${theme.palette.slide.LINK};
    text-decoration-color: ${theme.palette.slide.RED};
    padding-bottom:3px;
    border-bottom: 4px;
    border-color: blue;
    font-size:20px;
    &:hover {
      color: ${theme.palette.slide.RED};
      text-decoration-color: ${theme.palette.slide.LINK};
    }
`)