import { $getRoot, $isElementNode, EditorState, ElementNode } from "lexical";
import { $isAntiformNode } from "./AntiformNode";





export const extractForm = (editorState: EditorState) => {
    const form = {
      fields:[],
      values:{}
    }
    
    editorState.read( () => {
    
      visitTree($getRoot(), (node, indent) => {
        //const type = node.getType();
        if ($isAntiformNode(node)) {
          const {field, value} = node.toForm();
          form.fields.push(field)
          form.values[field] = value
          return true
        }
  
      })
    })
  
  
    return form
    
  }



export const  visitTree  = (currentNode: ElementNode, visitor) => {
  const childNodes = currentNode.getChildren();

  childNodes.forEach((childNode, i) => {
    const stop = visitor( childNode );

    if (!stop && $isElementNode(childNode)) {
      visitTree( childNode,visitor );
    }
  });
}