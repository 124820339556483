import { Input } from "@mui/material"


import { RED } from "../../../ui/theme/theme-lea";
import { pgFnsNoop, toPagesPM } from "../model/doc/note-transforms";
import { Group, Leaf,  NoteNode, NotePage } from "../model/doc/BubblePM";
import { pretweak } from "../model/doc/tweak/BubbleTweak";
import { $Upload, BubbleNotesImportPM, useBubbleNotesImport } from "./useBubbleNotesImport";

const sel = {color:RED}
const notSel = {color:"white"}

export const DocXNotesImport = () => {
const {$, pm} = useBubbleNotesImport() 


if (pm.parseError) {
  return renderTxt(pm.parseError)
} else if (pm.saveErr) {
  return (<div>
    Save has failed: {pm.saveErr}
    {pm.couldForceSave && 
 
      <a href={`#force-save}`}  onClick={() => $.server.save(true)}>
        force save  
      </a>

    }
  </div>)
} else if (pm.saveOK) {
  return <div>Bubble notes are uploaded </div>
}

  return (<div>
    {!pm.ok &&  <Input  type="file"  name="previewImage"  onChange={$.setFile}/>}
    <div>
      {pm.ok && pm.viewStates.map(state => {
        const style = (pm.view === state) ? sel : notSel
        return (
          <>      
            <a href={`#${state}`} style={style} onClick={() => $.setView(state)}>{state}</a>
            {" "}
          </>
      )})}
      {pm.ok && renderView($, pm)}
      
    </div>



  </div>)
}


const renderView = ($:$Upload, pm:BubbleNotesImportPM) => {
  switch (pm.view) {
    case "json":
      return (<div> {renderTxt(pm.notesStr)} </div>)
    case "preview":
      return (<div>preview...</div>)
    case "object": 
      return ObjectView($,pm)
    case "pm": {
      const {pages, notes} = pm.reading!
      const bubbles:NotePage<null, null, null, null>[] = toPagesPM({notes, pages}, pgFnsNoop)

      return  PreviewBubble($,bubbles)
    }



  }
}
    

const PreviewBubble = ($:$Upload, bubbles:NotePage<null, null, null, null>[]) => {

  return (<div>
    <div>{" "}</div>
    <a href="#save" onClick={_ => $.server.save()}>
      save
    </a>
    <div>--Previewing page--</div>
    {bubbles.map(p => PreviewPage(p))}
  </div>)
}
 
const PreviewPage = (pg:NotePage<any,any,any,any>) => {
  return (<div>
    <div> -- pg --   </div>
    {pg.nodes.map((n:NoteNode<any,any,any>) => PreviewNode(n))}
  </div>)
}

const PreviewNode = (n:NoteNode<any,any,any>) => {
 
  if (n.$$ === "Group") {
    return PreviewGroup(n)
  }  else {
    return (<div>{PreviewLeaf(n)}</div>)
  }

}


const PreviewGroup = (g:Group<any, any,any>) => {
  const {notes} = g
  return notes.map(leaf => (<div>
    "Group: " {PreviewLeaf(leaf)}
  </div>))
}


const PreviewLeaf = (leaf:Leaf<any,any>) => {
  if (leaf.$$ === "Note") {
    const type = leaf.note.type || ""
    const col = leaf.note.col || ""
    if (col) {

    }
    return (
      <>[{type}:{col}:{leaf.note.pg}] : {pretweak(leaf.note.text)}</>
    )
  } else {
    return (<>...</>)
  }
}



const ObjectView = ($:$Upload, pm:BubbleNotesImportPM) => {


  return (<div>
            <div>
              <a  href="#prev" onClick={$.prev}>
                prev
              </a>
              {" "}
              <a href="#next" onClick={$.next}>
                next 
              </a>

              {` ${pm.i + 1}/${pm.ps.length}`}
            </div>
            <div>
              {pm.ps.map((_, n) => (
                <span  style={{color: pm.es[n] !== null ? RED : "white"}}  onClick={_ => $.pg(n)}>
                  {`[${n}]`}{' '}
                </span>)
              )}
              </div>
            <div>
              <h3> -- extracted -- </h3>
              <p>
                {pm.extracted}
              </p>
            </div>
            <div>
              <h3>-- raw --</h3>
              <p> {" "}</p>
                {renderTxt(pm.raw || "")}
             
            </div>
          </div>)

          }
        

     
  
  const renderTxt = txt => {
    const lines = txt.split("\n")
  
  
    return (<div>
      {lines.map((v,i) => <div key={i}>{`${i}: ${v}`}</div>)}
    </div>)
  }