import { fbPs } from "../../../../../firebase/fbPs"

export const getBubbleSrc = async (src:string) => {

  const ps = fbPs()
  try {
    const {doc} = await ps.bubbleSrcDocRef(src).get() 
    if (doc.exists) {
      const data = doc.data()
      return data
    } 
  } catch (e) {
    console.error(e)
  }

  return null


}