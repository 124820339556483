import { usePage } from "../../../../MediaContextProvider"
import { Page } from "../../../../model/page/resource/PageState"
import { DocXNotesImport } from "../../import/DocXNotesImport"
import { Slide } from "../../view/typeography/Slide"

export const BubbleEdit = () => {
  const page:Page = usePage()
  const {params} = page.resource

  return (<Slide title={page.rurl} supTitle="ADD NOTES">
    <div>project: {params.project} </div>
    <div>src: {params.src}</div>
    <DocXNotesImport/>
  </Slide>)
}

