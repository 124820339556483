import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";

const SubSlideHeader = styled('div')(({theme}) => `
  justify-self: flex-end;
  color: ${theme.palette.slide.RED};
  font-size:35px;
`)

//  text-decoration: underline;
// text-decoration-color: ${theme.palette.slide.RED};

export const RefFolder = ({folder}) => {
    const {items, path} = folder
    return (<div key={folder.path}>
     <SubSlideHeader>
     {path}
     </SubSlideHeader>
      <Box sx={{ pt:3,pb:2 }}>
      <Box sx={{display: 'inline-flex'}}>
        {items.map((item, i) => <Reference key={i} item={item}/>)}
      </Box>
    </Box>
   
  </div>)
      
}

export type RefPM = {
  ref:string
  link:string
  name:string
}

export const Reference = ({item}) =>  (
  <Box sx={{ pb:1, w:"100%", pl:3}}>
    <RefLink refId={item.ref} link={item.link} />
    <Box sx={{typography:"editorTxt", fontSize:21,fontFamily:"Ubuntu", ml:1 }}>
      {item.name} 
    </Box>
  </Box>
)




export const RefLink = ({refId, link}) => (
  <Box  sx={{  minWidth: (link ? 150 : 0)}}>
    {link
       ? (<Link to={link}>
             <RLink>
             {refId}
            </RLink>
          </Link>) 
    : (<RLink>
        {refId}
      </RLink>)}
  </Box> )


export const RLink = styled('span')(({theme}) => `
    justify-self: flex-end;
    color: ${theme.palette.slide.LINK};
    text-decoration: underline;
    text-decoration-color: ${theme.palette.slide.RED};
    padding-bottom:3px;
    border-bottom: 4px;
    border-color: blue;
    font-size:26px;
    &:hover {
      color: ${theme.palette.slide.RED};
      text-decoration-color: ${theme.palette.slide.LINK};
    }
`)