import { useEffect } from "react"
import { useSelector } from "react-redux"
import { createStorePs$, SStore } from "../../../anyonic/dsl/createStorePs$"
import { usePm$ } from "../../../anyonic/dsl/usePm"
import { MediaEnv } from "../../page/resource/MediaEnv"
import { Cache } from "../../page/resource/PageCache"
import { pageMu } from "./PageMu"

import { Page } from "../../page/resource/PageState"
import { PageEff } from "./PageEff"


export type $Page = {
  mu: any,
  ps: any
  
  run:any

  // -- private
 

} & PageEff


export type PagePM = {
  page:Page
}

export const usePagePs = (_env:MediaEnv<any>):{$:$Page, pm:PagePM} => {

  const location = useSelector((app:any) => app.app.location)

  const {$, pm} = usePm$(() => pagePsConfig(_env))   as any
  
  useEffect(() => {
    $.start(location)
  }, [location, $])


  return {$,pm}

}

export const createPagePs$ = (env:MediaEnv<any>, store:SStore<any>):$Page => {
  const cf = pagePsConfig(env)
  const $ = createStorePs$(store, cf)
  return $
}


// -- exported for testing
export const pagePsConfig = (env:MediaEnv<any>) => {

  
  const {page, cache} = env.resolvePage("/", Cache.EMPTY)

  return {
    
    PM: {
      page,
      _ps:{
        location:{},
        cache,
        navigate:  "NO VALUE" //_ => {throw new Error("No navigate supplied") }
      },
      _loading:{}
    },

    mu: pageMu,

    $: PageEff(env),


  }
}




    


